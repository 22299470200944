import * as THREE from 'three';
import {Product} from './dataTree/Product';
import {ProductNode} from './nodeTree/ProductNode';
import {stage3D} from './engine/stage3D';
import {engine} from './engine/engine';
import {Part} from './dataTree/Part';
import {Style} from './dataTree/Style';
import {PartNode} from './nodeTree/PartNode';
import {opts, partTypes} from './options';
import {info} from "./info";
import {loader} from "./loader/loader";
import {mapCollection} from "./maps/mapCollection";
import {PartGroup} from "./dataTree/PartGroup";
import {PartGroupNode} from "./nodeTree/PartGroupNode";
import {environmentMap} from "./engine/environmentMap";
import {Connector} from "./dataTree/Connector";
import {defaultPartStyles} from "./options";
import {datasheetdata} from "../../configuration.data";
import {Watermark} from "./dataTree/Watermark";
import {environment} from "../../../../../../environments/environment";

import {formatDateTime, b64toBlob} from './utils/utils';
import {saveAs} from 'file-saver';

class Configurator {
	private product: Product;
	private productNode: ProductNode;
	private connectors: Connector[];
  private selectedButton: HTMLElement;
  private _savedStyles: any = {};
  private selectedPartGroupNodesUid: string;
  private watermark: Watermark;
  private watermarkMap: THREE.Texture;
  private _armsVisibility: any[];

	constructor(options: any) {

		for (const key in options) {
			if (!options.hasOwnProperty(key)) continue;
			opts[key] = options[key];
		}
    window.addEventListener('meshselected', (event: CustomEvent) => {
      // this.updateSelectors(event.detail.uid);
      this.selectedPartGroupNodesUid = event.detail.uid;
    });

		this._savedStyles = defaultPartStyles;

		this.watermarkMap = new THREE.TextureLoader().load( '/assets/img/ui/hyve-photo.png' );
	}
  get partsList(): any[] {
    const parts: any = {};

    for(const partGroupNode of this.productNode.partGroupNodes) {
      for(const label of partGroupNode.labels) {
        if(!label.description) continue;
        const lineCode =  info.addSlatFinish(label.text, partGroupNode);
        if(!parts[label.text]) parts[label.text] = { LINECODE: lineCode, LINEDESCRIPTION: label.description, ORDERQTY: 1 };
        else parts[label.text].ORDERQTY++;
      }
    }
    return Object.values(parts);
  }

	get initialized(): boolean {
	  return this.productNode !== undefined;
  }

  get savedStyles(): any {
	  const styles = {...this._savedStyles};
	  for(const partType in defaultPartStyles) {
	    if(!defaultPartStyles.hasOwnProperty(partType)) continue;
      const partNodes = info.getPartNodesByPartType(partType);
      if(partNodes.length < 1) styles[partType] = '-';
    }
    return styles;
  }

  set savedStyles(value: any) {
    this._savedStyles = value;
  }

  get armsVisibility(): any {
    this.updateArmsVisibility();
    return this._armsVisibility;
  }

  set armsVisibility(value: any) {
    this._armsVisibility = value;
  }

  /* private setArmsVisibility(): void {
    if(!this._armsVisibility) return;
    const arms = info.getAllArms();

    for(let i=0; i<this._armsVisibility.length && i<arms.length; i++) {
      arms[i].visible = this._armsVisibility[i];
    }
  }*/

  public updateArmsVisibility(): void {
    const arms = info.getAllArms();
    const visibility = [];
    for(const arm of arms) {
      visibility.push(arm.visible);
    }
    this._armsVisibility = visibility;
  }

  public getProduct(url): Promise<any> {
		this.product = new Product();

		return this.product.load(url).then(() => {
			return this.product;
		});
	}

	public addProduct(product: Product): Promise<ProductNode> {
		engine.start();
		engine.render();

		this.productNode = new ProductNode(product);

		return environmentMap.loadMap().then(() => {
			return this.productNode;
		});
	}

	public async init() {
		const product = await this.getProduct(environment.configuratorUrl);
		await this.addProduct(product);
	}

	public addPartGroupNode(partGroup: PartGroup): PartGroupNode {
		if(!this.product || !this.productNode) return;

		return this.productNode.addPartGroupNode(partGroup);
	}

	public removePartGroupNode(id: string): void {
		const partGroupNodeInfo = info.getPartGroupNodeInfo(id);
		if(partGroupNodeInfo && partGroupNodeInfo.partGroupNode) partGroupNodeInfo.partGroupNode.destruct();

		engine.update();
	}

	public removePartGroupNodes(): void {
		for(let i=this.productNode.partGroupNodes.length-1; i>=0; i--) {
			this.productNode.partGroupNodes[i].destruct();
		}
	}

	public removePartNode(uid: string): void {
		const partNodeInfo = info.getPartNodeInfo(uid);
		if(partNodeInfo && partNodeInfo.partNode) partNodeInfo.partNode.destruct();

		engine.update();
	}

	public addPart(partGroupId: string, part: Part, style?: Style): Promise<PartNode> {
		const partGroup = info.getPartGroupInfo(partGroupId).partGroup;
		partGroup.parts.push(part);

		return this.addPartNode(partGroupId, part, style);
	}

	public addPartNode(partGroupId: string, part: Part, style?: Style): Promise<PartNode> {
		if(!this.product || !this.productNode) return;

		if(style) part.styles.push(style);

		const partGroupNode = info.getPartGroupNodeInfo(partGroupId).partGroupNode;
		const partNode = new PartNode(part);
		partGroupNode.partNodes.push(partNode);

		return loader.load().then(() => {
			partNode.addToScene(partGroupNode.groupNode);
			engine.update();
			return partNode;
		});
	}

	/* public setStyle(partNode: PartNode, style?: Style): Promise<any> {
		let styleName: string;
		if(style) {
			styleName = style.name;
			partNode.setStyle(style);
			mapCollection.addMapUrls(styleName, style.materialSet.imageUrls);
		}
		return loader.load().then(() => {
			partNode.updateMaterial(styleName);
		});
	}*/

	public selectStyle(styleName: string, partType: string): void {
		const partNodes = info.getPartNodesByPartType(partType);
		for(const partNode of partNodes) {
			partNode.updateMaterial(styleName);
		}
	}

	private selectButton(el: HTMLElement): void {
    if (this.selectedButton) {
      this.selectedButton.classList.remove('selected-style');
    }
    el.classList.add('selected-style');
    this.selectedButton = el;
  }

	public async takePhoto() {
    this.createWatermark();
		stage3D.shoot();
    this.removeWatermark();
	}

  public takeImage() {
    return stage3D.takeImage();
  }

  public takeTopImage() {
    for(const partGroupNode of this.productNode.partGroupNodes) {
      partGroupNode.createLabels();
      partGroupNode.showLabels();
    }
    const img = stage3D.takeTopImage();
    datasheetdata.data.partsList = this.partsList;

    for(const partGroupNode of this.productNode.partGroupNodes) {
      partGroupNode.hideLabels();
      partGroupNode.removeLabels();
    }

const img2 = img.replace(/^data:image\/(png|jpg);base64,/, '');
engine.renderer.setPixelRatio(engine.renderer.getPixelRatio());
const blob = new Blob([b64toBlob(img2,'image/png')], {type: 'image/png'});
const now = new Date();
saveAs(blob, `Datasheet ${formatDateTime(now)}.png`);

    return img;
  }

  private createWatermark(): void {
    this.watermark = new Watermark(this.watermarkMap);
  }

  private removeWatermark(): void {
    this.watermark.destruct();
	}

  public async setSprites(sprites) {
		initLoadingEvent();
		const partGroupNodes = [];
		for(const sprite of sprites) {
			// console.log(sprite);
      // console.log(info.getPartGroupInfo(sprite._id));
			const partGroupNode = this.addPartGroupNode(info.getPartGroupInfo(sprite._id).partGroup);
			partGroupNodes.push(partGroupNode);
		}

		await loader.load();

		for(let i=0; i<sprites.length; i++) {
			const partGroupNode = partGroupNodes[i];
			partGroupNode.addToScene();
			const sprite = sprites[i];
			partGroupNode.translate(new THREE.Vector3(sprite.x, sprite.z, sprite.y));
// console.log(sprite);
      if(sprite._id === '6114c5a939822c25a67679fd') partGroupNode.scale(new THREE.Vector3(sprite.width, 1, sprite.height));
			partGroupNode.rotate(-sprite.rotation);

      partGroupNode.setArmsVisibility(sprite.armsVisibility);
		}
		stage3D.setViewport(new THREE.Vector3(0, 3, 7));
    this.initAllConnectors();
		const connectors = info.getConnectorsWithUniquePositions();
		this.connectors = connectors;
		// console.log(connectors);
		for(const connector of connectors) {
			connector.removeExcessPartNodes();
		}
		this.setSavedStyles();
	}

  private initAllConnectors(): void {
    const connectors = info.getAllConnectors();
    for(const connector of connectors) {
      connector.initIntersections();
    }
  }

  private setSavedStyles(): void {
    for(const partType in this._savedStyles) {
      if(!this._savedStyles.hasOwnProperty(partType)) continue;
      if(this._savedStyles[partType]) this.selectStyle(this._savedStyles[partType], partType);
    }
  }

/*  public hideAllArms(): void {
    const arms = info.getAllArms();
    for(const arm of arms) {
      arm.visible = false;
    }
//    this.updateArmsVisibility();
  }*/

	public visibleArms(value: boolean): void {
		const arms = info.getArmsOfPiece(this.selectedPartGroupNodesUid);
		for(const arm of arms) {
			arm.visible = value;
		}
//    this.updateArmsVisibility();
	}

  public showEndArms(): void {
    this.visibleArms(false);
    const arms = info.getArmsOfPiece(this.selectedPartGroupNodesUid);
    arms[0].visible = true;
    arms[1].visible = true;
    // this.updateArmsVisibility();
	}

  public showCenterArm(): void {
    this.visibleArms(false);
    const arms = info.getArmsOfPiece(this.selectedPartGroupNodesUid);
    if(arms.length < 3) return;
    arms[2].visible = true;
    // this.updateArmsVisibility();
  }

  public showLeftArm(): void {
    this.visibleArms(false);
    const arms = info.getArmsOfPiece(this.selectedPartGroupNodesUid);
    arms[0].visible = true;
    // this.updateArmsVisibility();
  }

  public showRightArm(): void {
    this.visibleArms(false);
    const arms = info.getArmsOfPiece(this.selectedPartGroupNodesUid);
    arms[1].visible = true;
    // this.updateArmsVisibility();
  }

  public updateSelectors(partName: string): void {
    const partType = info.getPartType(partName);
    if(partType === 'ignorable') return;
		const selectorInfo = info.getSelectorInfo(partName);
    const btnContainer = document.getElementById('selectors');
		btnContainer.innerHTML = '';

    // Banner is added dynamically due to btnContainer being cleared each time page is loaded
    const category = document.createElement('div');
    category.classList.add('category');
    category.classList.add('selector-title');
    category.innerText = 'Choose a colour';
    btnContainer.appendChild(category);
		const that = this;
		for(const styleName in selectorInfo) {
			if(!selectorInfo.hasOwnProperty(styleName)) continue;
      const container = document.createElement('div');
      container.className = 'color-container';

      const name = document.createElement('p');
      name.innerText = styleName;
      name.className = 'color-text';

			const el = document.createElement('div');
			if(this._savedStyles[partType] === undefined) {
        el.className = 'color-btn selected-style';
        this.selectedButton = el;
        this._savedStyles[partType] = styleName;
      } else {
        if (this._savedStyles[partType] === styleName) {
          el.className = 'color-btn selected-style';
          this.selectedButton = el;
        } else {
          el.className = 'color-btn';
        }
      }
			el.setAttribute('title', styleName);
			el.addEventListener('click', function() {
				const selectedMesh = engine.highlighter.selectedMesh;
				if(selectedMesh) {
					const partNode = info.getPartNodeInfoByMeshUID(selectedMesh.uid).partNode;
					partNode.updateMaterial(styleName);
					return;
				}
				that.selectStyle(styleName, partType);
				that.selectButton(el);
				that._savedStyles[partType] = styleName;
			}, false);
      container.appendChild(el);
      container.appendChild(name);
			btnContainer.appendChild(container);
			if(selectorInfo[styleName].imageColor) el.style.backgroundColor = selectorInfo[styleName].imageColor;
			if(selectorInfo[styleName].imageUrl) el.style.backgroundImage = 'url('+selectorInfo[styleName].imageUrl+')';
		}
	}
}

function initLoadingEvent() {
	window.addEventListener('rcload', (event: CustomEvent) => {
		loadingProgress(event.detail.totalProgress*100);
 // console.log(event.detail.totalProgress*100);
	});
}

function loadingProgress(percent) {
	const progressbar = document.getElementById('progressbar');
	if(percent >= 100) progressbar.style.display = 'none';
	else progressbar.style.display = 'block';
	progressbar.style.background = 'linear-gradient(to right, #fdcd03 ' + percent + '%, #fff ' + percent + '%)';
}

export const configurator3d = new Configurator({
	containerId: 'container',
	maps: 'https://api.inhaabit.com/viewer/maps/maps-grill/',
});
