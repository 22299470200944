import * as THREE from 'three';
import {Object3d} from './Object3d';
import {Material} from '../materials/Material';
import {shadowMaterialParametrs} from "../../options";
import {MaterialSet} from "../../dataTree/MaterialSet";

export class Shadow extends Object3d {

	constructor(geometry: THREE.BufferGeometry, material: Material) {

		super(geometry, material);
	}

	public updateMaterial(set: MaterialSet): void {

		set.items = {...set.items, ...shadowMaterialParametrs};
		this.material.update(set);
	}
}