import * as THREE from 'three';
import {Mesh} from './Mesh';
import {Material} from '../materials/Material';
import {engine} from '../../engine/engine';

export class Object3d {

	private _mesh: Mesh;

	constructor(geometry: THREE.BufferGeometry, protected material: Material) {

		this._mesh = new Mesh(geometry, material);
		this._mesh.rotateX(-Math.PI / 2);
		// this._mesh.geometry.center();
		// this._mesh.position.set(0, 0.2, 0);
	}

	get mesh(): Mesh {
		return this._mesh;
	}

	public addToScene(groupNode: THREE.Group): void {
		groupNode.add(this._mesh);
		// const box = new THREE.BoxHelper( this._mesh, 0xff0000 );
		// engine.scene.add( box );
	}

	public rotateX(rad: number): void {
		this._mesh.rotateX(rad);
	}

	public mirror() {
		//this._mesh.rotateX(Math.PI);
	}

	/*set position(value): void {
		this._mesh.position.copy(value);
	}*/

	public destruct(): void {
		this._mesh.parent.remove(this._mesh);
		this._mesh = null;

		if(this.material) this.material.dispose();
		this.material = null;
	}
}
