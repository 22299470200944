import { Component, ViewChild, ViewChildren, QueryList, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { gsap } from 'gsap';
import { configurator3d } from './c3d/src/configurator';
import { configurator2d } from '../c2d/configurator';
import { LayoutComponent } from '../../../layout/layout.component';
import { DatasheetComponent } from '../../../datasheet/datasheet.component';
import { EmailComponent } from './email/email.component';
import { QuoteComponent } from './quote/quote.component';
import { AlertComponent } from '../../../alert/alert.component';
import { ConfiguratorSliderComponent } from './configurator-slider/configurator-slider.component';
import { configuration } from './configuration.data';
import { HttpService } from '../../../http.service';
// import { isIE } from './configurator3d/js/utils';
import cssVars from 'css-vars-ponyfill';
import { datasheetdata } from './configuration.data';
import { BulletTutorialComponent } from '../../bullet-tutorial/bullet-tutorial.component';
import { TutorialComponent } from '../../tutorial/tutorial.component';

enum Mode {
  Layout,
  Quote
}
enum Step {
  Init,
  Take
}

@Component({
  selector: 'app-view3d',
  template: `
    <article class="page" #page>
      <div class="flash" #flash></div>
      <div id="container">
        <div id="progressbar"></div>
      </div>
      <a>
        <div class="logo"></div>
      </a>
      <span class="name"></span>
      <div class="arrow" (touchend)=hide() (click)=hide() *ngIf="!forServer&&!take">
        &#xe907;
        <span>Back to Editor</span>
      </div>
      <!-- <nav class="footer">
        <ul>
          <li class="btn" title="Take a photo" (touchend)=captureScene() (click)=captureScene()>&#xe902;</li>
          <li *ngIf="!forServer&&!take" class="btn" title="Customise arms & legs" (touchend)=chooseComponents($event) (click)=chooseComponents($event)>
            &#xe901;</li>
          <li *ngIf="!forServer&&!take" class="btn" title="Contact" (touchend)=showContact3d() (click)=showContact3d()>&#xe900;</li>
        </ul>
      </nav> -->

      <div class="button-container">
        <div #contactButton class="button ask" (click)="runBulletTutorial()">Tutorial</div>
        <div #customButtons class="custom-container">
          <div #seatColourButtons class="seat-colour-buttons">
            <div *ngIf="!forServer&&!take" class="button custom over" (click)="openConfigSlider('Leg')">
              Primary Colour
              <span class="tooltip-text">Change primary colour</span>
            </div>
            <div *ngIf="!forServer&&!take" class="button custom over" (click)="openConfigSlider('Bench Panel')">
              Secondary Colour
              <span class="tooltip-text">Change secondary colour</span>
            </div>
            <div *ngIf="!forServer&&!take" class="button custom over" (click)="openConfigSlider('Bench Slats 1000')">
              Slat Colour
              <span class="tooltip-text">Change slat colour</span>
            </div>
          </div>
          <div #seatColourBtn [ngClass]="{'button custom': true, 'over': !disabledBtn, 'disabled-btn': disabledBtn, 'selected-btn': selectedSeatBtn}" *ngIf="!forServer&&!take" (click)="showSeatColourButtons()">
            Furniture Colour
            <span class="tooltip-text">Change seat colour</span>
          </div>
          <div #armrestBtn [ngClass]="{'button custom': true, 'over': !disabledBtn, 'disabled-btn': disabledBtn}" *ngIf="!forServer&&!take" (click)="openArmTutorial()">
            Armrests
            <span class="tooltip-text">Change arms</span>
          </div>
          <div #bbqColourBtn [ngClass]="{'button custom': true, 'over': !disabledBtn, 'disabled-btn': disabledBtn}" *ngIf="!forServer&&!take" (click)="openConfigSlider('Edge Single BBQ Cabinet')">
            BBQ Colour
            <span class="tooltip-text">Change BBQ colour</span>
          </div>
          <div #greencellColourBtn [ngClass]="{'button custom': true, 'over': !disabledBtn, 'disabled-btn': disabledBtn, 'selected-btn': selectedGreencellBtn}" *ngIf="!forServer&&!take" (click)="showGreencellColourButtons()">
            Greencell Colour
            <span class="tooltip-text">Change greencell colour</span>
          </div>
          <div #greencellColourButtons class="greencell-colour-buttons">
            <div *ngIf="!forServer&&!take" class="button custom over" (click)="openConfigSlider('Greencell 1000x500 Body')">
              Primary Colour
              <span class="tooltip-text">Change primary colour</span>
            </div>
            <div *ngIf="!forServer&&!take" class="button custom over" (click)="openConfigSlider('Greencell 1000x500 Trim')">
              Secondary Colour
              <span class="tooltip-text">Change secondary colour</span>
            </div>
          </div>
        </div>

        <!-- <div> *ngIf="!forServer&&!take" class="button custom" (click)="openConfigSlider('arms')">Customise Arms</div> -->
        <div class="function-container">
          <div *ngIf="!forServer&&!take" class="button layout" (click)="showLayout()">
            <span class="tooltip-text">Save layout</span>
          </div>
          <div #photoButton class="button photo" (click)="captureScene()">
            <span class="tooltip-text">Take a photo</span>
          </div>
        </div>

<!--        <div class="dimension">{{dimension}}</div>-->
      </div>

      <div #quoteButton class="generate-quote" (click)="showDatasheet()">
        <span>Request a Quote</span>
      </div>

    <!-- <div class="spot" #balloon (click)=showContact()>&#xe904;</div> -->
    </article>

    <!-- This slides in the menu for style changes -->
    <app-configurator-slider></app-configurator-slider>

    <app-tutorial></app-tutorial>

    <app-alert (actionType)="toEmailMode()"></app-alert>
    <app-alert></app-alert>
    <app-alert (actionType)="toDatasheetMode()"></app-alert>
    <app-alert (actionType)="saveScene()"></app-alert>
    <app-alert (actionType)="openDocument()"></app-alert>
    <app-alert (actionType)="takePhoto()"></app-alert>
    <app-alert (actionType)="toLayoutMode()"></app-alert>
    <app-layout (actionType)="layoutAlert()"></app-layout>
    <app-alert (actionType)="replacePart()"></app-alert>
    <app-view3d-email (request)="request($event)"></app-view3d-email>
    <app-datasheet (generate)="generate()"></app-datasheet>
    <app-bullet-tutorial></app-bullet-tutorial>
  `,
  styles: [`
    .seat-colour-buttons {
      left: -50%;
      transform: translateX(166px);
      top: -100%;
      display: flex;
      position: absolute;
      visibility: hidden;
      z-index: 10;
    }
    
    .greencell-colour-buttons {
      left: 50%;
      transform: translateX(83px);
      top: -100%;
      display: flex;
      position: absolute;
      visibility: hidden;
      z-index: 10;
    }

    .tooltip-text {
      visibility: hidden;
      width: 115px;
      bottom: 150%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #6f6f6f;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      font-size: 9px;
      /* Position the tooltip text - see examples below! */
      position: absolute;
      z-index: 1;
      line-height: 12px;
      padding: 5px;
    }

    .tooltip-text:after {
      content: '';
      position: absolute;
      top: 90%;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      border-top: solid 10px #6f6f6f;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
    }

    .button:hover .tooltip-text {
      visibility: visible;
      opacity: 1;
    }

    .aside-contact {
      visibility: hidden;
    }

    .generate-quote {
      position: absolute;
      right: 20px;
      top: 40px;
      width: 87px;
      height: 90px;
      font-size: 12px;
      font-weight: 300;
      cursor: pointer;
      z-index: 15;
      border-radius: 50%;
      background: no-repeat url("/assets/img/ui/icon-pdf.png") #5E5E5D;
      background-size: 35%;
      background-position: 50% 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }

    .generate-quote:hover {
      transform: scale(1.05);
    }

    .generate-quote span {
      align-self: flex-end;
      margin-bottom: 16px;
      font-size: 9px;
      width: 50%;
      line-height: 10px;
    }

    .page {
      left: 100%;
      background-color: #fff;
      z-index: 10;
    }

    .flash {
      position: absolute;
      display: none;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0;
      z-index: 20;
    }

    .arrow {
      position: absolute;
      width: 50px;
      height: 50px;
      color: var(--light-grey-hover);
      line-height: 50px;
      font-family: "Icons";
      font-size: 17px;
      left: 11px;
      top: 4px;
      border-radius: 4px;
      cursor: pointer;
      z-index: 50;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
      display: flex;
      align-items: center;
    }

    .arrow span {
      font-size: 13px;
      white-space: nowrap;
      margin-left: 14px;
      font-family: "Poppins";
      visibility: visible;
    }

    .arrow:hover {
      color: var(--dark-charcoal);
      font-size: 23px;
      transform: scale(1.05);
    }

    .btn {
      margin: 0 5px;
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 30px;
      border-radius: 100%;
      font-family: "Icons";
      color: var(--light-grey-hover);
      cursor: pointer;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }

    .btn:hover {
      background-color: var(--light-grey);
      color: #000;
      transform: scale(1.05);
    }

    .footer {
      position: absolute;
      left: 50%;
      bottom: 40px;
      transform: translateX(-50%);
    }

    .footer ul {
      list-style: none;
      overflow: hidden;
    }

    .footer li {
      cursor: pointer;
      outline: none;
      float: left;
    }

    .spot {
      position: absolute;
      width: 50px;
      height: 50px;
      left: 40px;
      bottom: 40px;
      color: #fff;
      background-color: var(--light-grey);
      font-family: "Icons";
      font-size: 25px;
      text-align: center;
      line-height: 55px;
      border-radius: 100%;
      cursor: pointer;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }

    .spot:hover {
      background-color: var(--light-grey-hover);
      transform: scale(1.05);
    }

    .button-container {
      position: absolute;
      bottom: 35px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .button {
      width: 146px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      border-radius: 30px;
      font-weight: 500;
      line-height: 35px;
      box-sizing: border-box;
      text-align: center;
      margin-top: 15px;
      cursor: pointer;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }

    .disabled-btn {
      pointer-events: none;
      background-color: var(--light-grey-hover) !important;
    }

    .selected-btn {
      pointer-events: none;
      background-color: #333 !important;
    }

    .ask {
      background-color: #fdcd03;
      margin-left: 30px;
      color: black;
      /* position: absolute;
      left:30px; */
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }

    .ask:hover {
      background-color: #febb07;
      transform: scale(1.05);
    }

    .dimension {
      color: var(--dark-charcoal);
      background-color: #F3F3F3;
      height: 25px;
      line-height: 25px;
      padding: 0px 15px;
      background-size: cover;
      right: 30px;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
      position: absolute;
      font-size: 12px;
      border-radius: 15px;
      font-weight: 300;
      text-align: center;
      user-select: none;
      cursor: pointer;
    }

    /* .dimension:hover { background-color: var(--light-grey-hover); } */

    .custom {
      background-color: #5E5E5D;
      color: white;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
      margin-right: 20px;
      position: relative;
    }

    .over:hover {
      background-color: var(--light-grey-hover);
      transform: scale(1.05);
    }

    .custom-container {
      display: flex;
      position: relative;
    }

    .function-container {
      display: flex;
      margin-right: 30px;
    }

    .photo {
      color: white;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
      background-image: url('/assets/img/ui/icon-photo.png');
      background-position: center;
      background-size: 36px;
      width: 50px;
      height: 50px;
    }

    .photo:hover {
      transform: scale(1.05);
    }

    .layout {
      color: white;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
      background-image: url('/assets/img/ui/icon-save.png');
      background-position: center;
      background-size: 36px;
      width: 50px;
      height: 50px;
      margin-right: 8px;
    }

    .layout:hover {
      transform: scale(1.05);
    }

    .logo {
      width: 100%;
      height: 160px;
      background: url("/assets/img/ui/logo.png") 50% 60% no-repeat;
      background-size: 200px;
      position: absolute;
      z-index: 12;
    }
  `],
  providers: [HttpService]
})
export class View3dComponent implements OnInit {
  @ViewChild('page') page: ElementRef;
  @ViewChild('img') img: ElementRef;
  @ViewChild('quoteButton') quoteButton: ElementRef;
  @ViewChild('contactButton') contactButton: ElementRef;
  @ViewChild('photoButton') photoButton: ElementRef;
  @ViewChild('seatColourButtons') seatColourButtons: ElementRef;
  @ViewChild('greencellColourButtons') greencellColourButtons: ElementRef;
  @ViewChild('seatColourBtn') seatColourBtn: ElementRef;
  @ViewChild('greencellColourBtn') greencellColourBtn: ElementRef;
  @ViewChildren(AlertComponent) alerts: QueryList<AlertComponent>;
  @ViewChild(TutorialComponent) tutorial;
  @ViewChild(BulletTutorialComponent) bulletTutorial;
  @ViewChild(EmailComponent) email;
  @ViewChild(QuoteComponent) quote;
  @ViewChild(LayoutComponent) layout;
  @ViewChild(DatasheetComponent) datasheet;
  @ViewChild(ConfiguratorSliderComponent) configSlider;
  @ViewChild('flash') flash;
  @Output() showConnector = new EventEmitter<void>();

  sequence: any;
  forServer = false;
  mode: Mode;
  step: Step = Step.Init;
  take = false;
  private disabledBtn = false;
  private selectedSeatBtn = false;
  private selectedGreencellBtn = false;

  pdf: any;
  ie = false;

  id: string;
  dimension: string;

  private objectSelected: boolean = false;

  constructor(private httpService: HttpService) {
    cssVars();
    window.addEventListener('meshselected', (event: CustomEvent) => {
      this.configSlider.isSeatWithBackrest = /Backrest|600mm/ig.test(event.detail.name);
      this.openConfigSlider('arms', event.detail.uid );
      this.objectSelected = true;
    });
    // this.ie = isIE();
  }

  ngOnInit() {

    this.page.nativeElement.parentElement.addEventListener('pointerdown', (event) => {
      if(!this.objectSelected && !this.parentElementIsFound(<HTMLElement>event.target, 'aside')) this.hideConfigSlider();
      this.objectSelected = false;
    });
    /*this.promiseCfg3d = new Cfg3d(this.page.nativeElement, {data: ''});
    this.promiseCfg3d.then((that) => { this.cfg3d = that; this.cfg3d.onReplace(() => {
      const alert = this.alerts.toArray()[6];
      alert.message.title = 'please note';
      alert.message.text = 'Your current arm settings will be replaced.';
      alert.message.button = 'continue';
      alert.show();

    }); });*/
  }

  private parentElementIsFound(element: HTMLElement, id: string): boolean {
    do {
      if(id === element.id) return true;
      element = element.parentElement;
    } while(element);

    return false;
  }

  public async show(): Promise<void> {

    /* TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0, onComplete: () => {
      this.tutorial.run(1);
    }}); */
    const that = this;
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: 0,
      duration: 0.5,
      onComplete() { that.tutorial.run(1); }
    });
    if(!configurator3d.initialized) await configurator3d.init();
    configurator3d.removePartGroupNodes();
    // console.log(this.sequence);
    const sprites = JSON.parse(this.sequence);
    await configurator3d.setSprites(sprites);
  }

  public chooseComponents(event): void {

    event.stopPropagation();

    // this.promiseCfg3d.then((that) => { that.showChooserPanel(); });
  }

  public captureScene(): void {

    if (this.forServer) {

      datasheetdata.image0 = configurator3d.takeTopImage();
      configuration.image0 = configurator3d.takeImage();
      this.shine();
      datasheetdata.image1 = configurator3d.takeImage(); // this.cfg3d.captureScene();
      // console.log(datasheetdata);
      switch (this.mode) {

        case Mode.Layout:
          setTimeout(() => { this.saveLayout(); }, 400);
          break;

        case Mode.Quote:
          setTimeout(() => { this.sendData(); }, 400);
          break;

      }
      this.forServer = false;
      this.unslidePhoto();
    } else {

      switch (this.step) {

        case Step.Init:
          const alert = this.alerts.toArray()[5];
          // console.log(this.alerts);
          alert.message.title = 'Take a photo';
          alert.message.text = 'Rotate your configuration to the ideal perspective and use the mouse wheel to zoom.';
          alert.message.button = 'Continue';
          alert.show(false);
          break;

        case Step.Take:

          this.shine();

          setTimeout(() => {

              const alert = this.alerts.toArray()[3];
              alert.message.title = 'Take a Photo';
              alert.message.text = 'Your photo will now be saved to your device.';
              alert.message.button = 'Continue';
              alert.show(false);
              this.unslidePhoto();
            }, 400);
          break;
      }
    }

    // this.cfg3d.startEdit();
  }

  public takePhoto() {
    this.slidePhoto();
  }

  public slidePhoto() {
    this.take = true;
    /* TweenLite.to(this.quoteButton.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: '0',
      onComplete: () => {
        this.quoteButton.nativeElement.style.visibility = 'hidden';
      }
    });
    TweenLite.to(this.contactButton.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: '0',
      onComplete: () => {
        this.contactButton.nativeElement.style.visibility = 'hidden';
      }
    });*/
    const that = this;
    gsap.to(this.quoteButton.nativeElement, {
      ease: 'power3.out',
      opacity: 0,
      duration: 0.5,
      onComplete() { that.quoteButton.nativeElement.style.visibility = 'hidden'; }
    });
    gsap.to(this.contactButton.nativeElement, {
      ease: 'power3.out',
      opacity: 0,
      duration: 0.5,
      onComplete() { that.contactButton.nativeElement.style.visibility = 'hidden'; }
    });
    this.photoButton.nativeElement.style.marginTop = '0px';
    // TweenLite.to(this.photoButton.nativeElement, 1, { ease: Power3.easeOut, position: 'absolute', right: '50%', top: '20%'});
    gsap.to(this.photoButton.nativeElement, {
      ease: 'power3.out',
      position: 'absolute',
      right: '50%',
      top: '20%',
      duration: 1
    });
    this.step = Step.Take;
  }

  public unslidePhoto() {
    this.photoButton.nativeElement.style.marginTop = '15px';
    this.quoteButton.nativeElement.style.visibility = 'visible';
    this.contactButton.nativeElement.style.visibility = 'visible';

    /* TweenLite.to(this.photoButton.nativeElement, 1,
      {
        ease: Power3.easeOut,
        position: 'absolute',
        right: '0%',
        onComplete: () => {
          this.photoButton.nativeElement.style.removeProperty('top');
          this.photoButton.nativeElement.style.removeProperty('right');
          this.photoButton.nativeElement.style.removeProperty('position');
          TweenLite.to(this.quoteButton.nativeElement, 0.5, {
            ease: Power3.easeOut,
            opacity: '1'
          });
          TweenLite.to(this.contactButton.nativeElement, 0.5, {
            ease: Power3.easeOut,
            opacity: '1'
          });
          this.take = false;
          this.step = Step.Init;
        }
      });*/
    const that = this;
    gsap.to(this.photoButton.nativeElement, {
      ease: 'power3.out',
      position: 'absolute',
      right: 0,
      duration: 1,
      onComplete() {
        that.photoButton.nativeElement.style.removeProperty('top');
        that.photoButton.nativeElement.style.removeProperty('right');
        that.photoButton.nativeElement.style.removeProperty('position');
        /*TweenLite.to(this.quoteButton.nativeElement, 0.5, {
          ease: Power3.easeOut,
          opacity: '1'
        });
        TweenLite.to(this.contactButton.nativeElement, 0.5, {
          ease: Power3.easeOut,
          opacity: '1'
        });*/
        gsap.to(that.quoteButton.nativeElement, {
          ease: 'power3.out',
          opacity: 1,
          duration: 0.5
        });
        gsap.to(that.contactButton.nativeElement, {
          ease: 'power3.out',
          opacity: 1,
          duration: 0.5
        });
        that.take = false;
        that.step = Step.Init;
      }

    });
  }

  public saveScene() {
    configurator3d.takePhoto();
  }

  public contactUs(event: boolean): void {
    if (event) {
      const alert = this.alerts.toArray()[1];
      alert.message.title = 'Contact Us';
      alert.message.text = 'Thank you for your enquiry. We will be in touch shortly.';
      alert.message.button = 'Continue';
      alert.show();
    } else {
      const alert = this.alerts.toArray()[1];
      alert.message.title = 'Sorry';
      alert.message.text = 'We encountered an issue.';
      alert.message.button = 'Continue';
      alert.show();
    }
  }

  public saveLayout(): void {
    const alertWait = this.alerts.toArray()[1];
    alertWait.message.title = 'Generating Configuration';
    alertWait.message.text = 'Please wait as we generate your configuration.';
    alertWait.message.button = '';
    alertWait.show(false);

    const config = this.getLayoutConfig();
    // configuration.savedStyles = JSON.stringify(configurator3d.savedStyles);
    configuration.configuration = JSON.stringify(config);

    // console.log(configuration);

    const formData = new FormData();
    Object.entries(configuration).forEach(
      ([key, value]: any[]) => {
        formData.append(key, value);
      });

    // console.log(configuration);
    const alert = this.alerts.toArray()[1];
    const that = this;
    this.httpService.post('save-configuration', formData)
      .subscribe( (response: any) => {
        // console.log(response);
        that.id = response.id;
        alert.message.title = 'Save Layout';
        alert.message.text = "We've saved your configuration and sent a link to your email address.";
        alert.message.button = 'Continue';
        alert.show(false);
      }, error => {
        alert.message.title = 'Sorry';
        alert.message.text = 'We encountered an issue.';
        alert.message.button = 'Continue';
        alert.show(false);
        console.error(error);
      });
  }

  private getLayoutConfig() {
    return {
      sprites: JSON.parse(this.sequence),
      styles: configurator3d.savedStyles,
      arms: configurator3d.armsVisibility
    }
  }

  public sendData(): void {

    const alert = this.alerts.toArray()[1];
    alert.message.title = 'Request a Quote';
    alert.message.text = 'Please wait while we submit your request and layout details.';
    alert.message.button = '';
    alert.show(false);

    const config = this.getLayoutConfig();
    datasheetdata.data.configuration = JSON.stringify(config);
    datasheetdata.data.primaryColor = configurator3d.savedStyles.legs;
    datasheetdata.data.secondaryColor = configurator3d.savedStyles.panels;
    datasheetdata.data.slatsColor = configurator3d.savedStyles.slats;
    datasheetdata.data.bbqColor = configurator3d.savedStyles.bbqs;

    const formData = new FormData();
    Object.entries(datasheetdata).forEach(
      ([key, value]: any[]) => {
        if(key === "data") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      });

    this.httpService.post('save-quote', formData)
      .subscribe( (pdf: any) => {
        this.pdf = pdf;
        alert.hide();
        const downloadAlert = this.alerts.toArray()[4];
        downloadAlert.message.title = 'Request a Quote';
        downloadAlert.message.text = "We've received your request. Our team will contact you soon.";
        downloadAlert.message.button = 'Continue';
        downloadAlert.show(false);
      }, error => {
        alert.hide();
        alert.message.title = 'sorry';
        alert.message.text = 'We encountered an issue.';
        alert.message.button = 'continue';
        alert.show(false);
        console.error(error);
      });
  }

  public showContact() {

    // this.contact.codes = this.cfg3d.getSequence();

    // this.cfg3d.hidePanels();

    // this.contact.message = 'Price: $' + this.cfg3d.getPrice() + ' (without wifi)\n\nDetails:\n' +  this.cfg3d.getDetails();
  }

  public showQuote() {

    // const plan = new Plan(document, this.cfg2d, parts);
    // plan.getPromise().then((image) => {
    //   configuration.image0 = image;
    // });

    // configuration.dimensions = this.cfg2d.getRealSize().text;
    // configuration.powerMePositions = this.cfg2d.getPMPoses();

    this.datasheet.show();
  }

  public showLayout() {
    this.layout.show();
  }

  public showDatasheet() {
    this.datasheet.show();
  }

  hide(): void {
    configurator3d.updateArmsVisibility();
    configurator2d.armsVisibility = configurator3d.armsVisibility;

    if (this.take === true) {
      this.unslidePhoto();
    }
    this.showConnector.emit();
    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '100%' });
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: '100%',
      duration: 0.5
    });
  }

  public action(event) {

    // const styles = this.cfg3d.getStyles();
    // configuration.arms = this.cfg3d.getAmountOfArms();
    // configuration.partsCode = this.cfg3d.getSequence();
    // configuration.battensFinish = styles.battensFinish;
    // configuration.frameFinish = styles.frameFinish;
    // configuration.powerMeFinish = styles.powerMeFinish;
    // configuration.price = this.cfg3d.getPrice();

    switch (event) {

      case 'emailConfig':
        const alert = this.alerts.toArray()[0];
        alert.message.title = 'email your configuration';
        alert.message.text = `Email a photo of your configuration and parts list. Get started by taking a photo.`;
        alert.message.button = 'take photo';
        alert.show(false);
        break;

      case 'getInTouch':
        this.showContact();
        break;

      case 'createQuote':
        /*if (this.ie) {
          this.detectedIE();
        } else {*/
          this.showQuote();
        // }
        break;
    }
  }

  public request(event) {

    const alert = this.alerts.toArray()[1];

    switch (event) {

      case 'success':
        alert.message.title = 'configuration saved';
        alert.message.text = 'Your configuration has been emailed to you.';
        alert.message.button = 'continue';
        alert.show();
        break;

      case 'error':
        alert.message.title = 'Error';
        alert.message.text = 'Something went wrong.';
        alert.message.button = 'continue';
        alert.show();
        break;
    }
  }

  public openDocument() {

    // window.open(this.pdf.url,'_blank');
    // wnd.location = pdf.url;
  }

  public toEmailMode() {

    // this.cfg3d.stopEdit();

    // this.forServer = true;
    // this.mode = Mode.Email;
  }

  public toDatasheetMode() {
    this.forServer = true;
    this.mode = Mode.Quote;
    this.slidePhoto();
  }

  public toLayoutMode() {
    this.forServer = true;
    this.mode = Mode.Layout;
    this.slidePhoto();
  }

  public replacePart() {

    // this.cfg3d.replacePart();
  }

  public generate() {
    const alert = this.alerts.toArray()[2];
    alert.message.title = 'Finish Your Quote';
    alert.message.text = 'Take a photo of the layout and we will send this along to our team to complete your quote.<br/><br/>Use the mouse wheel to zoom.';
    alert.message.button = 'Take Photo';
    alert.show(false);
  }

  public layoutAlert() {
    const alert = this.alerts.toArray()[6];
    alert.message.title = 'Save Layout';
    alert.message.text = 'Take a photo to finish saving your layout.<br/>Use the mouse wheel to zoom.';
    alert.message.button = 'Take Photo';
    alert.show(false);
  }

  public detectedIE() {

    const alert = this.alerts.toArray()[1];
    alert.message.title = 'outdated browser';
    alert.message.text = 'Please use a <a href="https://www.google.com/chrome/">modern browser</a> to generate a quote.</a>';
    alert.message.button = 'close';
    alert.show();
  }

  private shine(): void {

    this.flash.nativeElement.style.display = 'block';

    /* TweenLite.to(this.flash.nativeElement, 0.2, {
      ease: Expo.easeInOut,
      opacity: 0.7,
      onComplete: () => {
        this.flash.nativeElement.style.display = 'none';
      }
    });*/
    const that = this;
    gsap.to(this.flash.nativeElement, {
      ease: 'expo.inout',
      opacity: 0.7,
      duration: 0.2,
      onComplete() { that.flash.nativeElement.style.display = 'none'; }
    });
  }

  public close() {

    // this.cfg3d.startEdit();
  }

  public hideConfigSlider(): void {
    this.hideSeatColourButtons();
    this.hideGreencellColourButtons();
    this.configSlider.hide();
  }
  public openConfigSlider(type: string, uid: string): void {
    this.hideSeatColourButtons();
    this.hideGreencellColourButtons();
    if(type !== 'arms') configurator3d.updateSelectors(type);
    this.configSlider.run(type, uid);
  }

  public runBulletTutorial(): void {
    this.bulletTutorial.run(1);
  }

  public openArmTutorial(): void {
    this.bulletTutorial.run(2);
  }

  private showSeatColourButtons(): void {
    this.seatColourButtons.nativeElement.style.visibility = 'visible';
    this.selectedSeatBtn = true;
    this.disableButtons();
  }
  private hideSeatColourButtons(): void {
    this.seatColourButtons.nativeElement.style.visibility = 'hidden';
    this.selectedSeatBtn = false;
    this.enableButtons();
  }
  private showGreencellColourButtons(): void {
    this.greencellColourButtons.nativeElement.style.visibility = 'visible';
    this.selectedGreencellBtn = true;
    this.disableButtons();
  }
  private hideGreencellColourButtons(): void {
    this.greencellColourButtons.nativeElement.style.visibility = 'hidden';
    this.selectedGreencellBtn = false;
    this.enableButtons();
  }
  private disableButtons(): void {
    this.disabledBtn = true;
  }
  private enableButtons(): void {
    this.disabledBtn = false;
  }
}
