import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
// import { TweenLite, Power1, Power2, Power3 } from 'gsap/all';
import { gsap } from 'gsap';
import cssVars from 'css-vars-ponyfill';

@Component({
  selector: 'app-home-greeting',
  template: `
    <!--article class="page" #page-->
      <section class="box" #box>
        <p class="hero-text">Welcome to the Grillex<br>
          configurator for HYVE. Start <br>
          designing your better <br>
          shared space.
        </p>
        <nav class="footer">
          <ul>
            <li class="button build" (click)=build()>Let's Go</li>
            <!--li class="button" (click)=about()>about aria</li-->
          </ul>
        </nav>
      </section>
    <!--/article-->
  `,
  styles: [`
    .hero-text {
      color: white;
      text-align:center;
      margin-top:0px;
      margin-bottom:5px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
    }
    .page {
      background-color: transparent;
      top: 0;
    }
    .box {
      position: absolute;
      width: 630px;
      height: 390px;
      top: 57%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* padding: 60px 50px; */
      color: var(--dark-charcoal);
      border-radius: 8px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-content: center;
      z-index: 10;
    }
    h1 {
      text-align: center;
      font-size: 62px;
      letter-spacing:-1px;
      font-weight: bold;
    }
    .button {
      width: 180px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      border-radius: 30px;
      font-weight: 500;
      box-sizing: border-box;
      text-align: center;
      background: none;
      color: #fcce0d;
    }
    .build {
      /*margin-right: 40px;*/
      /* background-color: var(--logo-red); */
      border: 5px solid white;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }
    .build:hover { background-color: #fdcd03; color: black; }
    nav {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 200px;
      line-height: 0;
      text-align: center;
    }
    ul { list-style: none; display: inline-block; transition: all .2s cubic-bezier(0, 1.04, 0.58, 1); box-sizing: border-box;}
    ul:hover {transform: scale(1.05);}
    li {
      cursor: pointer;
      outline: none;
    }
    li:hover {transform: scale(1.05);}
    .footer li {
      float: left;
    }
  `]
})

export class GreetingComponent {

  @ViewChild('box') box: ElementRef;
  @Output() startBuilding = new EventEmitter<void>();
  @Output() showAbout = new EventEmitter<void>();

  constructor() { cssVars(); }

  public show(): void {

    // TweenLite.from(this.box.nativeElement, 1.5, { ease: Power2.easeOut, opacity: 0 }).delay(1);
    gsap.from(this.box.nativeElement, {
      ease: 'power2.out',
      opacity: 0,
      duration: 1.5,
      delay: 1
    });

  }

  public build(): void {

    this.startBuilding.emit();
  }

  public about(): void {

    this.showAbout.emit();
  }
}
