import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {opts} from '../options';
import {stage3D} from './stage3D';
import {engine} from './engine';
// import {gsap} from 'gsap';
import {SphericalToCartesian, CartesianToSpherical, anglesIsEqual} from '../utils/math';

interface SceneDimensions {
	height: number,
	width: number,
	depth: number,
	center: THREE.Vector3
}

export class CameraControls extends OrbitControls {

	constructor(private scene: THREE.Scene, private camera: THREE.PerspectiveCamera, domElement: HTMLElement) {

		super(camera, domElement);
		this.init();
	}

	private init(): void {

		this.enableDamping = true;
		this.dampingFactor = 0.05;
		this.rotateSpeed = 1.0;
		this.enablePan = false;
		this.autoRotate = opts.rotate;
		this.autoRotateSpeed = 1;
		this.camera.fov = 3.2;
    this.maxPolarAngle = Math.PI / 2;
  }

	get cameraFov(): number {
	  return this.camera.fov;
  }
  set cameraFov(fov: number) {
    this.camera.fov = fov;
  }
  get cameraZoom(): number {
    return this.camera.zoom;
  }
  set cameraZoom(zoom: number) {
    this.camera.zoom = zoom;
  }
	get cameraPosition(): THREE.Vector3 {
	  return this.camera.position.clone();
  }
  set cameraPosition(position: THREE.Vector3) {
    this.camera.position.copy(position);
  }

  public fitScene(): void {
		const sceneClone = this.scene.clone();

    this.cleanTree(sceneClone);
		// console.log(sceneClone);

		const box = new THREE.BoxHelper( sceneClone, 0x000000 );
    box.geometry.computeBoundingBox();

		const boundingBox = box.geometry.boundingBox;

		const sceneCenter = boundingBox.getCenter(new THREE.Vector3());

		this.target.copy(sceneCenter);

		const sceneSize = boundingBox.clone().max.sub(boundingBox.min);

    const m = Math.max(sceneSize.y, sceneSize.z);
    const sceneWidth = sceneSize.x;
		const sceneHeight = m; // sceneSize.y; //*10; //  * 5;
    const sceneDepth = m; // sceneSize.z;

		if(sceneWidth * sceneHeight * sceneDepth === 0) return;
		const sceneDimensions: SceneDimensions = {
			height: sceneHeight,
			width: sceneWidth,
			depth: sceneDepth,
			center: sceneCenter
		};
		this.adjustCameraPosition(sceneDimensions);

		const initDistance = this.camera.position.distanceTo(sceneCenter);

		this.minDistance = initDistance * opts.minDistance;
		this.maxDistance = initDistance * opts.maxDistance;
	}

	private cleanTree(group) {
    for(let i = group.children.length - 1; i >= 0 ; i--) {
      const obj = group.children[i];
      if (obj.type === 'Group') this.cleanTree(obj);
      else if (obj.name === 'shadow' || obj.type !== 'Mesh') {
        group.remove(obj);
      }
    }
  }

	private adjustCameraPosition(sceneDimensions: SceneDimensions): void {
		const heightRatio = stage3D.height / sceneDimensions.height;
		const widthRatio = stage3D.width / sceneDimensions.width;
		const estimatedWidth = sceneDimensions.width * heightRatio;

		let widthFactor = estimatedWidth / stage3D.width;
		if(heightRatio < widthRatio) widthFactor = 1;

		const fov = this.camera.fov * (Math.PI / 180);
		const dist = sceneDimensions.height / 2 / Math.tan(fov / 2) * widthFactor;

		let cameraDist = dist + 1 / dist;
		cameraDist /= opts.zoom;

		this.camera.position.copy(sceneDimensions.center.clone().add( engine.camera.position.clone().sub(sceneDimensions.center).normalize().multiplyScalar(cameraDist)));
	}

	public lookFrom(directVector: THREE.Vector3): void {

		this.camera.position.copy(this.calcCameraPosition(directVector));
	}

	public lookFromWithAnimation(directVector: THREE.Vector3): void {
/*
		const sphc = CartesianToSpherical(this.camera.position);
		const nextCameraPos = this.calcCameraPosition(directVector);
		const destSphc = CartesianToSpherical(nextCameraPos);
		if(destSphc.teta === 0 || destSphc.teta >= 3.14) destSphc.fi = Math.PI * 0.5;
		destSphc.radius = sphc.radius;

		if(anglesIsEqual(sphc.teta, destSphc.teta) && anglesIsEqual(sphc.fi, destSphc.fi)) return;

		const that = this;
		gsap.to(sphc, {
			teta: destSphc.teta,
			fi: destSphc.fi,
			onUpdate: () => {
				if(sphc.teta === 0) sphc.teta = 0.001;
				if(sphc.teta >= 3.14) sphc.teta = 3.14;
				that.camera.position.copy(SphericalToCartesian(sphc));
			},
			ease: 'power2.inOut',
			duration: 0.5});*/
	}

	private calcCameraPosition(directVector: THREE.Vector3): THREE.Vector3 {

		const vectorLength = this.camera.position.clone().sub(this.target).length();
		const normal = directVector.normalize();
		return this.target.clone().add(normal.multiplyScalar(vectorLength));
	}
}
