import {getAngle, getScale} from './utils/dom';
import {IVector, calcVectorLength, normalizeVector, sumVectors, calcUnitVector} from './utils/math';
import {Unit}  from './Unit';
import {options} from "./options";

export class Connector {

	private original: IVector; // изначальный вектор направления
	private _direction: IVector; // вектор направления
	private _position: IVector;
	private _locked: boolean = false;
	private _linked: Connector;
	private _html: HTMLElement;
	private _id: string;
	private threshold: number;

	constructor(private _parent: Unit, connectorData: any) {

		this._id = connectorData._id;
		this.original = calcUnitVector(connectorData.rotation);
		this._direction = calcUnitVector(connectorData.rotation);
		this.threshold = options.magneticThreshold;

		this.create(connectorData);
		this.connect = this.connect.bind(this);
// console.log(connectorData);
// console.log(this._parent.name);
	}

	private create(connectorData: any): void {
		this._html = document.createElement('div');
		this.setCss2();
		// this._html.className = 'connector';
		this._parent.html.appendChild(this._html);
		this._position = {
			x: connectorData.x * options.globalZoomFactor + this._parent.center.x - this.getCenter().x,
			y: connectorData.y * options.globalZoomFactor + this._parent.center.y - this.getCenter().y
		};
		this._html.style.left = this._position.x + 'px';
		this._html.style.top = this._position.y + 'px';
/*
		const arrow = document.createElement('div');
		// arrow.className = 'arrow';
		arrow.style.position = 'absolute';
		arrow.style.width = '2px';
		arrow.style.background = 'linear-gradient(0deg, #f00 50%, #00f 50%)';

		this._html.appendChild(arrow);
		arrow.style.left = '50%';
		arrow.style.height = '100%';
		arrow.style.top = '0px';

		const angle = -Math.atan2(this.original.x, this.original.y) * 180 / Math.PI;
		arrow.style.transform = `rotate(${angle}deg)`;*/
	}

	private setCss(): void {
		this._html.style.position = 'absolute';
		this._html.style.width = '30px';
		this._html.style.height = '30px';
		this._html.style.borderRadius = '50%';
		this._html.style.border = 'solid 2px #000291';
		this._html.style.boxSizing = 'border-box';
		this._html.style.pointerEvents = 'none';
		this._html.style.visibility = 'hidden';
	}

	private setCss2(): void {
		this._html.style.position = 'absolute';
		this._html.style.width = '40px';
		this._html.style.height = '40px';
		this._html.style.borderRadius = '50%';
		this._html.style.backgroundColor = 'rgba(170, 170, 170, 0.7)';
		this._html.style.boxSizing = 'border-box';
		this._html.style.pointerEvents = 'none';
		this._html.style.visibility = 'hidden';
	}
	get linked(): Connector {
		return this._linked;
	}
	set linked(value: Connector) {
		this._linked = value;
	}
	get locked(): boolean {
		return this._locked;
	}
	set locked(value: boolean) {
		this._locked = value;
	}
	get id(): string {
		return this._id;
	}
	get position(): IVector {
		return this._position;
	}
	set position(value: IVector) {
		this._position = value;
	}
	get direction(): IVector {
		return this._direction;
	}
	get html(): HTMLElement {
		return this._html;
	}
	get parentID(): string {
		return this._parent.id;
	}
	get parent(): Unit {
		return this._parent;
	}
	get globalCoordinates(): IVector {
		const global = this._html.getBoundingClientRect();
		const scale = getScale(this._parent.container);
		return { x: (global.left + 0.5 * global.width) / scale, y: (global.top + 0.5 * global.height) / scale };
	}
	public show(unitName: string): void {
		if(unitName === 'coffee-table') {
			if(this._id === '6089001236f086029ef6da9d') this._html.style.visibility = 'visible';
		} else if(this._id !== '6089001236f086029ef6da9d') this._html.style.visibility = 'visible';
	}
	public hide(unitName: string): void {
		if(unitName === 'coffee-table') {
			if(this._id === '6089001236f086029ef6da9d') this._html.style.visibility = 'hidden';
		} else if(this._id !== '6089001236f086029ef6da9d') this._html.style.visibility = 'hidden';
	}

	public tryToConnect(connector: Connector): boolean {
		const deg = getAngle(this._parent.html);
		this.rotate(deg);
    if(connector.parentID === this._parent.id) return false;
		const connectable = this.isConnectable(connector);
		if(connectable) return true; // this.connect(connector);
		return false;
	}

  public outOfArea(a: any): boolean {
    const p = this._html.getBoundingClientRect();
    return (a.left > p.right || a.right < p.left || a.top > p.bottom || a.bottom < p.top);
  }

  private getCenter(): IVector {
		const connectorRect = this._html.getBoundingClientRect();
		const scale = getScale(this._parent.container);
		return { x: connectorRect.width * 0.5 / scale, y: connectorRect.height * 0.5 / scale };
	}

	private rotate(deg: number): void {
		const v = this.original;
		const rad = Math.PI * deg / 180;
		this._direction = { x: Math.cos(rad) * v.x - Math.sin(rad) * v.y, y:  Math.sin(rad) * v.x + Math.cos(rad) * v.y };
	}

	private isInverted(direction: IVector): boolean {
		const nA = normalizeVector(direction);
		const nB = normalizeVector(this._direction);
		const vSum = sumVectors(nA, nB);
		return calcVectorLength(vSum) === 0;
	}

	private isConnectable(connector: Connector): boolean {
		const dist = this.calcGlobalDistace(connector);

    if(this._parent.isTable) return this.isInverted(connector.direction) && dist < this.threshold &&
      connector.id === this._id && connector.parentID !== this._parent.id && this.typesMatch(connector);

		return this.isInverted(connector.direction) && dist < this.threshold && !connector.locked &&
			connector.id === this._id && connector.parentID !== this._parent.id && this.typesMatch(connector);
	}

  public probablyConnected(connector: Connector): boolean {
    const dist = this.calcGlobalDistace(connector);

    return this.isInverted(connector.direction) && dist < 1 && !connector.locked  && !this.locked &&
      connector.id === this._id && connector.parentID !== this._parent.id && this.typesMatch(connector);
  }

  private typesMatch(connector: Connector): boolean {
		return this.id !== '6089001236f086029ef6da9d' || (this.id === '6089001236f086029ef6da9d' &&
			this._parent.name === 'coffee-table' && connector.parent.name !== 'coffee-table' );
	}

	private calcGlobalDistace(connector: Connector): number {
		const globalSelf = this.globalCoordinates;
		const globalNext = connector.globalCoordinates;

		const vector = { x: globalSelf.x - globalNext.x, y: globalSelf.y - globalNext.y };
		return calcVectorLength(vector);
	}

	public connect(connector: Connector): void {
		const globalSelf = this.globalCoordinates;
		const globalNext = connector.globalCoordinates;

		this._parent.html.style.left = this._parent.html.offsetLeft - Math.round(globalSelf.x - globalNext.x) + 'px';
		this._parent.html.style.top = this._parent.html.offsetTop - Math.round(globalSelf.y - globalNext.y) + 'px';

    if(this._parent.isTable || connector.parent.isTable) return;

		this._locked = true;
		connector.locked = true;
		this._linked = connector;
		connector.linked = this;
	}

  public disconnect(): void {
    if(this._parent.isTable) return;

    this.locked = false;
    if(this.linked) {
      this.linked.locked = false;
      this.linked.linked = null;
      this.linked = null;
    }
  }
}
