import * as THREE from 'three';
import {CPromise} from '../utils/CPromise';

interface IMap {
	map?: THREE.Texture,
	promise?: Promise<IMap>
}

interface IMapStore {
	[idx: string]: IMap; // we use urls as indices
}

class MapCache {

	private mapStore: IMapStore = {};

	public loadMap(url: string): Promise<IMap> {

		const promise = new CPromise();

		const cachedMap = this.getMap(url);
		if(cachedMap) {
			if(cachedMap.map) {
				promise.result.resolve(cachedMap.map);
				return promise.handle;
			} else return cachedMap.promise;
		}

		const that = this;
		if( cachedMap === undefined) {
			const textureLoader = new THREE.TextureLoader();
			textureLoader.load(url, (map: THREE.Texture): void => {
				// console.log(url);
				that.saveMap(url, { map: map });
				map.wrapS = THREE.RepeatWrapping;
				map.wrapT = THREE.RepeatWrapping;
				map.anisotropy = 16;
				map.minFilter = THREE.NearestMipMapNearestFilter;
				promise.result.resolve(map);
			}, undefined, () => {
				promise.result.reject();
				console.warn(`Loading error. Check url "${url}"`);
			});
		}
		that.saveMap(url, {promise: promise.handle});
		return promise.handle;
	}

	private saveMap(idx: string, map: IMap): void {

		this.mapStore[idx] = map;
	}

	public getMap(idx: string): IMap {
		// if(!this.mapStore[idx]) console.log(this.mapStore);
		return this.mapStore[idx];
	}

	/* public clean(): void {
		for(const key in this.mapStore) {
			if(!this.mapStore.hasOwnProperty(key)) continue;
			if(this.mapStore[key]) this.removeMap(key);
		}
	} */

	public removeMap(idx): void {
// console.log(idx);
		if(this.mapStore[idx] && this.mapStore[idx].map) {
			this.mapStore[idx].map.dispose();
			delete this.mapStore[idx];
		}
	}
}

export const mapCache = new MapCache();