import { Component, ViewChild, ViewChildren, ElementRef, QueryList, AfterViewInit, ApplicationRef } from '@angular/core';
// import { TweenLite, Power1, Power3 } from 'gsap/all';
import { gsap } from 'gsap';
import * as Swiper from '../../../../node_modules/swiper/dist/js/swiper.min.js'

@Component({
  selector: 'app-tutorial',
  template: `
    <div class="shadow" #shadow></div>
    <article class="page container" #container>
      <div class="swiper-container" #swiperContainer>
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of tips; index as i">
            <section class="box" #box (click)=next($event)>
              <h1 #title>{{item.title}}</h1>
              <p #text>{{item.text}}<br>
                  <img #image src="{{item.img}}">
              </p>
              <div class="close" (click)=last($event)>&#x2715;</div>
            </section>
          </div>
        </div>
        <div class="swiper-pagination" #panel></div>
      </div>
    </article>
  `,
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements AfterViewInit {

  @ViewChildren('box') boxes: QueryList<ElementRef>;
  @ViewChild('container') container: ElementRef;
  @ViewChild('panel') panel: ElementRef;
  @ViewChild('shadow') shadow: ElementRef;
  @ViewChild('swiperContainer') swiperContainer: ElementRef;

  swiper: any;

  firstTime = true;

  tutorials: any[] = [
    [
      {title: "Let's Get Started", text: 'Drag and drop pieces to create your configuration.', img: 'assets/img/tutorial/tutorial-drag.png'},
      {title: "Let's Get Started", text: 'Tap the arrow keys on the keyboard to rotate parts.', img: 'assets/img/tutorial/tutorial-2.jpeg'},
      {title: "Let's Get Started", text: 'Press 3D View to see your configuration in 3D.', img: 'assets/img/tutorial/tutorial-3d.png'},
    ],
    [
      {title: '3D View', text: 'Hold the left mouse button to rotate your layout', img: 'assets/img/tutorial/3d-tutorial-1.jpg'},
      {title: '3D View', text: 'Use the mouse scroll wheel to zoom in and out', img: 'assets/img/tutorial/3d-tutorial-2.jpg'},
      {title: 'Customise Arms', text: 'Select a bench to customise arm options', img: 'assets/img/tutorial/3d-tutorial-3.jpg'},
      {title: 'Save Layout', text: 'Save your layout and access it again via a link', img: 'assets/img/tutorial/3d-tutorial-4.jpg'},
      {title: 'Take a Photo', text: 'Download a photo of your current layout in 3D', img: 'assets/img/tutorial/3d-tutorial-5.jpg'},
    ]
  ];

  tips: any[];

  prevSlideNum = 0;

  ngAfterViewInit() {

    window.addEventListener('resize', () => { this.positionPanel(); });

    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      pagination: { el: '.swiper-pagination', clickable: true },
      mousewheel: true,
      direction: 'horizontal',
      observer: true,
      observeParents: true,
    });

    this.swiper.on('transitionEnd', () => {
      if (this.swiper.isEnd && this.prevSlideNum === this.tips.length - 1) { this.last(); }
      this.prevSlideNum = this.swiper.activeIndex;
    });
    this.container.nativeElement.style.display = 'none';

  }

  constructor(private appRef: ApplicationRef) {/* tick */ }

  public positionPanel() {
    const boxes = this.boxes.toArray();

    const offset = 0;

    const containerHalfHeight = this.container.nativeElement.offsetHeight / 2;
    let boxHalfHeight = 0;
    if (boxes[0]) {
      boxHalfHeight = boxes[0].nativeElement.offsetHeight / 2;
    }

    this.panel.nativeElement.style.top = containerHalfHeight + boxHalfHeight + offset + 'px';
  }

  public next(event): void {

    if (this.swiper.isEnd) {
      this.last(event);
      return;
    }
    this.swiper.slideNext();
  }

  public last(event?) {

    if (event !== undefined) {
        event.stopPropagation();
      }

    this.firstTime = false;

    const that = this;
    gsap.to(that.shadow.nativeElement, {
      ease: 'power1.out',
      opacity: 0,
      duration: 0.5,
      onComplete() {
        that.shadow.nativeElement.style.display = 'none';
      }
    });

    gsap.to(that.container.nativeElement, {
      ease: 'power1.out',
      opacity: 0,
      scale: 0,
      duration: 0.5,
      onComplete() {
        that.container.nativeElement.style.display = 'none';
      }
    });

    /*TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: 0,onComplete() {
      that.shadow.nativeElement.style.display = 'none';
    }});

    TweenLite.to(that.container.nativeElement, 0.5, {ease: Power1.easeOut, opacity: 0, scale: 0, onComplete(){
      that.container.nativeElement.style.display = 'none';
    }});*/
  }

  public run(num): void {

    if (!this.firstTime) { return; }

    this.tips = this.tutorials[num];

    this.appRef.tick();

    this.swiper.update();

    const that = this;

    that.shadow.nativeElement.style.display = 'block';
    this.container.nativeElement.style.display = 'block';
/*
    TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: .2});

    TweenLite.to(this.container.nativeElement, 0.5, {ease: Power1.easeOut, top:0, scale: 1, opacity: 1});
*/
    gsap.to(this.shadow.nativeElement, {
      ease: 'power1.out',
      opacity: .2,
      duration: 0.5
    });

    gsap.to(this.container.nativeElement, {
      ease: 'power1.out',
      top: 0,
      scale: 1,
      opacity: 1,
      duration: 0.5
    });

    this.positionPanel();
  }
}

