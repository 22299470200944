export const configuration: any = {
  image0: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  company: '',
  configuration: null
};
export const datasheetdata: any = {
  image0: null,
  image1: null,
  data: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    configuration: null
  }
};
