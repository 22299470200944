import * as THREE from 'three';
import * as JSZip from '../../lib/jszip';
// import fragmentShader from '../../glsl/fragment.glsl';
// import vertexShader from '../../glsl/vertex.glsl';

export function loadJSON(url: string): Promise<JSON> {

    return fetch(url, {
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
        credentials: 'same-origin'
        })
		.then( response => response.json())
		.catch( () => { throw errMsg('loadJSON', `JSON problem - ${url}`); } );
}

export function loadZip(url: string): Promise<Blob[]> {

	return fetch(url, {
		headers: {'Content-Type': 'application/zip', 'Accept': 'application/zip'},
		credentials: 'same-origin'
		})
		.then( response => {
			if(response.status === 200 || response.status === 0) {
				return Promise.resolve(response.blob());
			} else {
				return Promise.reject(new Error(response.statusText));
			}
		})
		.then(JSZip.loadAsync)
		.then((value: any) => {
			const promises = [];
			for(const fName of Object.keys(value.files)) {
				const result = value.file(fName).async("string").then((file) => {
					const obj = {};
					obj[fName] = file;
					return obj;
				});
				promises.push(result);
			}
			return Promise.all(promises);
		});
}

export function errMsg(name: string, massage: string): Error {

	const error = new Error(massage);
	error.name = name;
	return error;
}

export function isIE(): boolean{

	const ua: string = navigator.userAgent;
	const M: string[] = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

	return /trident/i.test(M[1]);
}

export function generateID(length: number = 24): string {

	let id: string = '';
	for(let i=0; i<10; i++) {
		id += Math.random().toString(16).slice(2);
	}
	return id.slice(0, length);
}
/*
export function blendMaps(mapA: THREE.Texture, mapB: THREE.Texture): THREE.Texture {

	const uniforms = {
		color: { type: "c", value: new THREE.Color( 0x0000ff ) },
		texture: { type: "t", value: mapA },
		texture2: { type: "t", value: mapB }
	};

	const shaderMaterial = new THREE.ShaderMaterial({
		uniforms: uniforms,
		vertexShader: vertexShader,
		fragmentShader: fragmentShader
	});

	const camera = new THREE.OrthographicCamera( -1, 1, 1, -1, 0, 1 );
	const scene = new THREE.Scene();

	const geometry = new THREE.PlaneBufferGeometry( 2, 2 );
	const mesh = new THREE.Mesh( geometry, shaderMaterial );
	const light = new THREE.HemisphereLight(0x404040, 0xFFFFFF, 0.5);

	scene.add( light );
	scene.add( mesh );

	const renderer = new THREE.WebGLRenderer({ preserveDrawingBuffer: true });
	renderer.setPixelRatio(1);
	renderer.setSize(mapA.image.width, mapA.image.height);
	renderer.render(scene, camera);

	return new THREE.Texture(renderer.domElement);
}
*/
export function b64toBlob(b64Data, contentType='', sliceSize=512): Blob {

	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, {type: contentType});
}

export function formatDateTime(date: Date): string {

	const [dt, tm] = date.toISOString().split('T');
	const [y, m, d] = dt.split('-');
	const t = tm.split('.')[0];

	return d + '-' + m + '-' + y + ' ' + t;
}

export function hexToInt(hex: string): number {

	return parseInt(hex.replace('#','0x'), 16);
}
