import 'whatwg-fetch'

export function loadJSON(url: string): Promise<JSON> {
    return fetch(url, {
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
        credentials: 'same-origin'
        })
		.then( response => response.json())
		.catch( () => { throw errMsg('loadJSON', `JSON problem - ${url}`); } );
}

export function errMsg(name: string, massage: string): Error {
	const error = new Error(massage);
	error.name = name;
	return error;
}

export function generateID(length: number = 24): string {
	let id: string = '';
	for(let i=0; i<10; i++) {
		id += Math.random().toString(16).slice(2);
	}
	return id.slice(0, length);
}

export function formatDateTime(date: Date): string {
	const [dt, tm] = date.toISOString().split('T');
	const [y, m, d] = dt.split('-');
	const t = tm.split('.')[0];

	return d + '-' + m + '-' + y + ' ' + t;
}


