import {allConnectors} from './allConnectors';
import {getAngle, getScale} from './utils/dom';
import {layersControl} from "./layersControl";
import {options} from "./options";
import {Connector} from "./Connector";
import {calcVectorLength, IVector, subVectors} from "./utils/math";

interface IBoundingClientRect {
	top: number, left: number, bottom: number, right: number, width?: number, height?: number
}

export class Unit {
	private image: string;
	private _spriteID: string;
	private _name: string;
  private _category: string;
	private _width: number;
	private _height: number;
	private _html: HTMLElement;
	private _block: boolean;
	private _connectors: Connector[]  = [];
	private _visible: boolean = false;
	private _moving: boolean = false;
	private _alwaysOnTop: boolean;
  private _alwaysBack: boolean;
  private delta: any;
  private _scaleFactor: number = 1;
  private _armsVisibility: boolean[] = []; // for 3d configurator

	constructor(private _id: string, private _container: HTMLElement, sprite: any) {
		this.image = sprite.image;
		this._name = sprite.slug;
    this._category = sprite.category;
		this._alwaysOnTop = sprite.alwaysOnTop;
    this._alwaysBack = sprite.alwaysBack;
		this._spriteID = sprite._id;
		this._width = sprite.width * options.globalZoomFactor;
		this._height = sprite.height * options.globalZoomFactor;
		if(this._name !== 'footprint') this._height += options.verticalShift;
    this.initArmsVisibility(sprite.arModels);
		this._html = null;

		this.onMove = this.onMove.bind(this);
		this.onStart = this.onStart.bind(this);
		this.onEnd = this.onEnd.bind(this);
		this.onRotate = this.onRotate.bind(this);
		this.scaleAndCenter = this.scaleAndCenter.bind(this);
		this.create();
		layersControl.addLayer(this, this._id);
	}

	private create(): void {
		this._html = document.createElement('div');
		this.setCss();
		// this._html.className = 'unit';

		this._html.style.background = `url(${this.image}) center/cover no-repeat`;
		this._html.style.width = this._width + 'px';
		this._html.style.height = this._height + 'px';

		this._container.appendChild(this._html);

		this._html.addEventListener('touchstart', this.onStart, false);
		this._html.addEventListener('mousedown', this.onStart, false);

    document.addEventListener('keydown', this.onRotate, false);
		document.addEventListener('wheel', this.onRotate, false);
		document.addEventListener('touchmove', this.onMove, false);
		document.addEventListener('mousemove', this.onMove, false);
		document.addEventListener('touchend', this.onEnd, false);
		document.addEventListener('mouseup', this.onEnd, false);

		window.addEventListener('resize', this.scaleAndCenter, false);
	}

  public setSize(width: number, height: number) {
    this._width = width * options.globalZoomFactor;
    this._height = height * options.globalZoomFactor;
    const elementsRect = this.getBoundingClientRect();
    this._html.style.top = this._html.offsetTop - (this._height - this._html.offsetHeight) + 'px';
    if(this._name !== 'footprint') this._height += 40;
    this._html.style.width = this._width + 'px';
    this._html.style.height = this._height + 'px';
    this.scaleAndCenter();
  }

  private initArmsVisibility(arModels: any): void {
    const arms = arModels.filter((arModel) => arModel._id === '60ade663aa30c61f21ce44a3');
    for(const arm of arms) {
      this._armsVisibility.push(false);
    }
  }

  public setArmsVisibility(armsVisibility: boolean[]): void {
    for(let i=0; i<this._armsVisibility.length; i++) {
      this._armsVisibility[i] = armsVisibility[0];
      armsVisibility.splice(0,1);
    }
  }

	private setCss(): void {
		this._html.style.position = 'absolute';
		this._html.style.boxSizing = 'border-box';
		this._html.style.cursor = 'pointer';
	}

	get id(): string {
		return this._id;
	}
	get spriteID(): string {
		return this._spriteID;
	}
	get name(): string {
		return this._name;
	}
  get category(): string {
    return this._category;
  }
	get connectors(): Connector[] {
		return this._connectors;
	}
	get container(): HTMLElement {
		return this._container;
	}
	set block(value: boolean) {
		this._block = value;
		this._html.style.cursor = value ? 'auto' : 'pointer';
	}
	get html(): HTMLElement {
		return this._html;
	}
	get alwaysOnTop(): boolean {
		return this._alwaysOnTop;
	}
  get alwaysBack(): boolean {
    return this._alwaysBack;
  }
	set visible(value: boolean) {
		this._visible = value;
		this._html.style.visibility = value ? 'visible' : 'hidden';
	}
	get visible(): boolean {
		return this._visible;
	}
	set order(zIndex: string) {
		this._html.style.zIndex = zIndex;
	}
	get width(): number {
		return this._width;
	}
	get height(): number {
		return this._height;
	}
	get center(): IVector {
		return { x: this._width * 0.5, y: this._height * 0.5 };
	}
	get position(): IVector {
		const containerRect = this._container.getBoundingClientRect();
		const unitRect = this._html.getBoundingClientRect();
		// console.log(this._name);
		// console.log(containerRect);
		// console.log(unitRect);
		const scale = getScale(this._container);

		const x = (unitRect.left - containerRect.left + unitRect.width * 0.5 - containerRect.width * 0.5) / scale; // containerRect.width * 0.5 - unitRect.width * 0.5;
		const y = (unitRect.top - containerRect.top + unitRect.height * 0.5 - containerRect.height * 0.5) / scale; // containerRect.height * 0.5 - unitRect.height * 0.5;

		return {x, y};
	}
  get isTable(): boolean {
    return /^(?!coffee).*table/ig.test(this._name);
  }
  get armsVisibility(): boolean[] {
    return this._armsVisibility;
  }

  public addConnectors(...connector): void {
		this._connectors.push(...connector);
	}

	private removeConnectors(): void {
		for(const connector of this._connectors) {
			const idx = allConnectors.indexOf(connector);
			allConnectors.splice(idx,1);
		}
	}

	public moveFrom(event: MouseEvent | TouchEvent): void {
		const p = this.getMousePose(event);
		const containerRect = this._container.getBoundingClientRect();
		const unitRect = this._html.getBoundingClientRect();
		const scale = getScale(this._container);

		this._html.style.left = p.x - (containerRect.left + unitRect.width*0.5) / scale + 'px';
		this._html.style.top = p.y - (containerRect.top + unitRect.height*0.5) / scale + 'px';

		this.onStart(event);
		this.onMove(event);
	}

  public moveToCenter(): void {
    const containerRect = this._container.getBoundingClientRect();
    const unitRect = this._html.getBoundingClientRect();
    const scale = getScale(this._container);

    this._html.style.left = (containerRect.width*0.5 - unitRect.width*0.5) / scale + 'px';
    this._html.style.top = (containerRect.height*0.5 - unitRect.height*0.5) / scale + 'px';

    layersControl.toFront(this._id);
  }

  public translate(x: number, y: number): void {
		this._html.style.left = Math.round(x * options.globalZoomFactor - this._width * 0.5) + 'px';
		this._html.style.top = Math.round(y * options.globalZoomFactor - this._height * 0.5) + 'px';
		this.tryToConnect();
	}

	public rotate(rad: number): void {
		this.setAngle(rad * 180 / Math.PI);
		this.tryToConnect();
	}

  public scale(width: number, height: number): void {
    this._html.style.width = Math.round(width * options.globalZoomFactor) + 'px';
    this._html.style.height = Math.round(height * options.globalZoomFactor) + 'px';
  }

  private pose(x: number, y: number): void { // позиционирование объекта (x, y - центр объекта)
		this._html.style.left = Math.round(x - this.delta.x) + 'px';
		this._html.style.top = Math.round(y - this.delta.y) + 'px';
		this.tryToConnect();
	}

  private tryToConnect(): void {
    let c1;
    let c2;
    let distance = Infinity;
    const area = this.getArea();
    for(const connector of allConnectors) {
      if(connector.outOfArea(area)) continue;
      for(const connectorSelf of this._connectors) {
        if(connector === connectorSelf) continue;
        if(connectorSelf.tryToConnect(connector)) {
          const v = subVectors(connectorSelf.position, connector.position);
          const length = calcVectorLength(v);
          if(length < distance) {
            distance = length;
            c1 = connectorSelf;
            c2 = connector;
          }
        }
      }
    }
    if(c1 && c2) {
      c1.connect(c2);
      this.checkOtherConnectors();
    }
  }

  private checkOtherConnectors(): void {
    for(const connectorSelf of this._connectors) {
      for(const connector of allConnectors) {
        if(connector === connectorSelf) continue;
        if(connectorSelf.probablyConnected(connector)) {
          connectorSelf.connect(connector);
        }
      }
    }
  }

  private getArea(): any {
    const b = this._html.getBoundingClientRect();
    const m = options.magneticThreshold;
    return { left: b.left-m, right: b.right+m, top: b.top-m, bottom: b.bottom+m };
  }

	private connectorVisibility(value: boolean): void {
		for(const connector of allConnectors) {
			if(value) connector.show(this._name);
			else connector.hide(this._name);
		}
	}

	private setAngle(deg: number): void {
		let angle = Math.round(deg / options.incrementAngle) * options.incrementAngle;
		angle = (angle < 0) ? angle + 360 : angle;
		angle = angle > 360 ? angle - 360 : angle;

		this._html.style.transform = `rotate(${angle}deg)`;
	}

	public removeUnit(): void {
		if(!this._html || !this._html.parentNode) return;

		this.disconnect();
		this.removeConnectors();

		this._container.removeChild(this._html);
		layersControl.removeLayer(this._id);
		this._html = null;
    this.scaleAndCenter();
	}

  private disconnect(): void {
    for(const connector of this._connectors) {
      connector.disconnect();
    }
  }

	private emitStartDrag(): void {
		const event = new CustomEvent('StartDrag');
		this._container.dispatchEvent(event);
	}

	private emitEndDrag(): void {
		const event = new CustomEvent('EndDrag', {
      detail: { scaleFactor: this._scaleFactor }
    });
    this._container.dispatchEvent(event);
  }

	private onStart(event: MouseEvent | TouchEvent): void {
		if(this._block || this._moving) return;

		this.disconnect();
		this._moving = true;

		layersControl.toFront(this._id);

		const p = this.getMousePose(event);
		this.delta = {x: p.x - this._html.offsetLeft, y: p.y - this._html.offsetTop };

    if(this._name !== 'footprint') {
      this.emitStartDrag();
      this.connectorVisibility(true);
    }
		document.body.style.cursor = 'pointer';
	}

  private onEnd(event): void {
    if(!this._moving || (event.touches && event.touches.length > 0)) return;

    const element = event.changedTouches ? document.elementFromPoint(event.changedTouches[0].pageX, event.changedTouches[0].pageY) : event.target;
    const point = this.getPoint(event);
    if((point.x > document.body.offsetWidth || point.x < 500 ||
      point.y > document.body.offsetHeight || point.y < 0) && this._name !== 'footprint') this.removeUnit();

    let connected = false;
    for(const connector of this._connectors) {
      if(connector.linked && connector.locked) { connector.linked.locked = true;  connected = true; }
    }
    if(!connected && this._name === 'coffee-table') this.removeUnit();

    this.scaleAndCenter();
    this._moving = false;
    // console.log(allConnectors);
    this.emitEndDrag();
    this.connectorVisibility(false);

    document.body.style.cursor = 'auto';
  }

  private getPoint(event): IVector {
    let x: number;
    let y: number;
    if(event.touches) {
      if(event.touches.length > 0) {
        x = event.touches[0].pageX;
        y = event.touches[0].pageY;
      }
    } else {
      event.preventDefault();
      x = event.clientX;
      y = event.clientY;
    }
    return { x, y };
  }

	private onMove(event): void {
		if(this._block || !this._moving) return;
		if(event.changedTouches && event.touches.length > 1) {
			const angle = Math.atan2(event.touches[0].pageY - event.touches[1].pageY, event.touches[0].pageX - event.touches[1].pageX) * 180 / Math.PI;
			this.setAngle(angle);
			this.tryToConnect();
			return;
		}
		this.disconnect();
		this.moveToPointer(event);
	}

  private onRotate(event: KeyboardEvent | WheelEvent): void {
    if(!this._moving) return;
    if(event instanceof KeyboardEvent && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') return;

    const clockwise = event instanceof WheelEvent ? event.deltaY > 0 : event.key === 'ArrowLeft';
    const delta = clockwise ? -options.incrementAngle : options.incrementAngle;
    this.setAngle(getAngle(this._html) + delta);
    this.tryToConnect();
  }


  private moveToPointer(event: MouseEvent | TouchEvent): void { // перемещение объекта вслед за указателем мыши
		const p = this.getMousePose(event);
		this.pose(p.x, p.y);
	}

  private getMousePose(event): IVector {
    const point = this.getPoint(event);
    const scale = getScale(this._container);

    return {x: point.x / scale, y: point.y / scale};
  }

  private getBoundingClientRect(): IBoundingClientRect {
    const boundaries: IBoundingClientRect = { top: Infinity, left: Infinity, bottom: -Infinity, right: -Infinity };
    const containerRect = this._container.getBoundingClientRect();

    for(const child of this._container.children) {
      const unitRect = child.getBoundingClientRect();
      if(boundaries.top > unitRect.top) boundaries.top = unitRect.top;
      if(boundaries.left > unitRect.left) boundaries.left = unitRect.left;
      if(boundaries.bottom < unitRect.bottom) boundaries.bottom = unitRect.bottom;
      if(boundaries.right < unitRect.right) boundaries.right = unitRect.right;
    }
    boundaries.left -= containerRect.left;
    boundaries.right -= containerRect.left;
    boundaries.top -= containerRect.top;
    boundaries.bottom -= containerRect.top;

    boundaries.width = boundaries.right - boundaries.left;
    boundaries.height = boundaries.bottom - boundaries.top;

    return boundaries;
  }

	public scaleAndCenter(): void {
		this.resetContainerStyle();

		const sceneRect = this._container.parentElement.getBoundingClientRect();
		const elementsRect = this.getBoundingClientRect();

		this.moveUnitGroupToUpLeftCorner(elementsRect);
		this.setContainerSize(elementsRect);
		this.scaleContainer(sceneRect, elementsRect);

		const containerRect = this._container.getBoundingClientRect();
		this.alignContainer(sceneRect, containerRect);
	}

	private resetContainerStyle(): void {
		this._container.style.transform = `scale(1)`;
		this._container.style.left = '0';
		this._container.style.top = '0';
		this._container.style.width = '100%'; // т.к. getBoundingClientRect() расчитывает относительно body
		this._container.style.height = '100%';
	}

	private moveUnitGroupToUpLeftCorner(elementsRect): void {
		for(const child of this._container.children) {
			const htmlChild: HTMLElement = <HTMLElement>child;
			htmlChild.style.left = htmlChild.offsetLeft - elementsRect.left + 'px';
			htmlChild.style.top = htmlChild.offsetTop - elementsRect.top + 'px';
    }
	}

	private setContainerSize(elementsRect) {
		this._container.style.height = elementsRect.height + 'px';
		this._container.style.width = elementsRect.width + 'px';
	}

	private scaleContainer(sceneRect, elementsRect) {
		const xFactor = sceneRect.width < elementsRect.width ? sceneRect.width / elementsRect.width : 1;
		const yFactor = sceneRect.height < elementsRect.height ? sceneRect.height / elementsRect.height : 1;
		const factor = xFactor < yFactor ? xFactor : yFactor;
    this._scaleFactor = factor;
    options.variableScaleFactor = factor;
		this._container.style.transformOrigin = 'top left';
		const scale = factor * options.extraScaleFactor;
		this._container.style.transform = `scale(${scale})`;

		const scene2d = document.getElementById("scene2d");
		scene2d.style.backgroundSize = options.backgroundSize/options.extraScaleFactor * scale + "px";
	}

  private alignContainer(sceneRect, containerRect) {
	  const left = Math.round(0.5 * (sceneRect.width - containerRect.width));
	  const top = Math.round(0.5 * (sceneRect.height - containerRect.height));
    this._container.style.left = left + 'px';
    this._container.style.top = top + 'px';

    const asideBg = document.getElementById("asideBg");
    const scene2d = document.getElementById("scene2d");
    scene2d.style.backgroundPosition = (left+asideBg.offsetWidth) + "px " + top + "px";
  }
}
