import {MaterialSet} from './MaterialSet';
import * as THREE from 'three';
import {Style} from './Style';
import {errMsg} from '../utils/utils';
import {info} from "../info";

export class Part {

	private _id: string;
	private _angle: string;
	private _isMirrored: boolean;
	private _position: any;

	private _name: string;
	private _slug: string;
	private _bundleUrl: string;
	private _shadowImage: string;
	private _transparent: boolean;
	private _materialSet: MaterialSet;

	private _styles: Style[] = [];

	constructor(data: any, arModelData: any) {

		this._id = arModelData['_id'];
		this._angle = arModelData['rotation'];
		this._isMirrored = arModelData['isMirrored'];
		this._position = new THREE.Vector3( arModelData['x'], arModelData['z'], arModelData['y'] );
		const arModel = data.arModels.find((currArModel) => { return currArModel._id === arModelData._id; });
		this._name = arModel['name'];
		this._slug = arModel['slug'];
// console.log(this._name);
		if(this._id === undefined || this._name === undefined) throw errMsg('Part ID/Name', '_id and name values are mandatory!');
		// info.partExists(this._id);
		this._bundleUrl = arModel['webBundle'];
		this._shadowImage = arModel['shadow'];
		this._transparent = arModel['isTransparent'];

		this._materialSet = new MaterialSet(arModel);

		const styleCollection = data.styleCollections.find((styleCo) => {
			const styleId = (arModel.inheritStyles !== undefined && arModel.inheritStyles !== null) ? arModel.inheritStyles : arModel._id;
			return styleCo._id === styleId;
		} );

		if(styleCollection && styleCollection.styles) this.parseStyles(styleCollection.styles);

	}

	get id(): string {
		return this._id;
	}

	get name(): string {
		return this._name;
	}

  get slug(): string {
    return this._slug;
  }

  get position(): THREE.Vector3 {
		return this._position;
	}

	get angle(): number {
		return parseFloat(this._angle);
	}

	get isMirrored(): boolean {
		return this._isMirrored;
	}

	get bundleUrl(): string {
		return this._bundleUrl;
	}

	get shadowImage(): string {
		return this._shadowImage;
	}

	get transparent(): boolean {
		return this._transparent;
	}

	get styles(): Style[] {
		return this._styles;
	}

	get materialSet(): MaterialSet {
		return this._materialSet;
	}

	public addStyle(style: Style): void {
		this.styles.push(style);
	}

	public getStyleByName(name: string): Style {
		for(const style of this._styles) {
			if(style.name === name) return style;
		}
		throw errMsg('style', `Can't find a style with the name - ${name}`);
	}

	private parseStyles(stylesData: any): void {
		for(const styleData of stylesData) {
			const style = new Style(styleData);
			this._styles.push(style);
		}
	}

	public removeAllStyles(): void {

		for(let i=this._styles.length-1; i>=0; i--) {
			this._styles[i].destruct();
			this._styles.splice(i, 1);
		}
	}

	public destruct(): void {

		this.removeAllStyles();

		if(this._materialSet) this._materialSet.destruct();
		this._materialSet = null;

		const partInfo = info.getPartInfo(this._id);
		partInfo.parent.parts.splice(partInfo.parent.parts.indexOf(partInfo.part), 1);
	}
}
