import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
// import { TweenLite, Power1, Power3 } from 'gsap/all';
import { gsap } from 'gsap';
import { configurator3d } from '../c3d/src/configurator';

@Component({
  selector: 'app-configurator-slider',
  template: `

    <section id="aside" #aside>
        <div #wrapper class="wrapper">
          <div id="selectors">
          </div>
          <div id="arms">
            <div class="category selector-title">Choose arm type</div>
            <div class="arm" id="no-arm" (click)=noArms()></div>
            <div class="arm" id="left-arm" (click)=leftArm()></div>
            <div class="arm" id="right-arm" (click)=rightArm()></div>
            <div *ngIf="!isSeatWithBackrest" class="arm" id="center-arm" (click)=centerArm()></div>
            <div class="arm" id="ends-arm" (click)=endArms()></div>
            <div *ngIf="!isSeatWithBackrest && !max2Arms" class="arm" id="all-arm" (click)=allArms()></div>
          </div>
          <div id="close-container" (click)="hide()">
            <div id="close-text">&#x2715;</div>
          </div>
        </div>
      </section>
  `,
  styles: [`
    .arm {
      color: #c8c8c8;
    }

    #no-arm, #all-arm, #ends-arm, #center-arm, #left-arm, #right-arm {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    #no-arm {
      background-image: url('./assets/img/tutorial/arms-none.png');
    }

    #all-arm {
      background-image: url('./assets/img/tutorial/arms-all.png');
    }

    #ends-arm {
      background-image: url('./assets/img/tutorial/arms-both.png');
    }

    #center-arm {
      background-image: url('./assets/img/tutorial/arms-center.png');
    }

    #left-arm {
      background-image: url('./assets/img/tutorial/arms-right.png');
    }

    #right-arm {
      background-image: url('./assets/img/tutorial/arms-left.png');
    }

    #aside {
      position: absolute;
      display: flex;
      height: 100%;
      width: 400px;
      left: 0;
      box-sizing: border-box;
      overflow: hidden;
      z-index: 20;
      left: -100%;
    }

    .wrapper {
      width: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      background: white;
      position: relative;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .wrapper::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .wrapper {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    #close-container {
      position: absolute;
      top: 2%;
      right: 2%;
      width: 30px;
      height: 30px;
      color: var(--dark-charcoal);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }

    #close-text {
      cursor: pointer;
      font-size: 10px;
    }

    #close-container:hover {
      background-color: #febb07;
      color: #fff;
      transform: scale(1.05);
    }

    @media (max-width: 1000px) {
      /* .wrapper {
		display: none;
	  } */
    }
  `],
})
export class ConfiguratorSliderComponent implements OnInit {

  @ViewChild('wrapper') wrapper: ElementRef;
  @Output() actionType = new EventEmitter<string>();
  public isSeatWithBackrest: boolean;
  public max2Arms: boolean;

  constructor() { }

  ngOnInit() {
  }

  public run(type: string): void {
    if(type === 'arms') {
      document.getElementById('selectors').style.display = 'none';
      document.getElementById('arms').style.display = 'flex';
    } else {
      document.getElementById('selectors').style.display = 'flex';
      document.getElementById('arms').style.display = 'none';
    }

    const container = document.getElementById("aside");
    this.wrapper.nativeElement.scrollTo({top: 0, behavior: 'smooth'});
    // TweenLite.to(container, 0.5, { ease: Power3.easeOut, left: 0 });
    gsap.to(container, {
      ease: 'power3.out',
      left: 0,
      duration: 0.5
    });
    // TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: 0, onComplete: () => {
    //   this.shadow.nativeElement.style.display = 'none';
    // }});
  }

  public hide() {
    const container = document.getElementById("aside");
    // TweenLite.to(container, 0.5, { ease: Power3.easeIn, left: "-100%" });
    gsap.to(container, {
      ease: 'power3.out',
      left: '-100%',
      duration: 0.5
    });
  }

  public noArms(): void {
    configurator3d.visibleArms(false);
  }

  public allArms(): void {
    configurator3d.visibleArms(true);
  }

  public endArms(): void {
    configurator3d.showEndArms();
  }

  public centerArm(): void {
    configurator3d.showCenterArm();
  }
  public leftArm(): void {
    configurator3d.showLeftArm();
  }
  public rightArm(): void {
    configurator3d.showRightArm();
  }

  // contactUs(): void {
  //   this.actionType.emit();
  // }

}
