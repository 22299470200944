export class CPromise {

	private _result: any;
	private _handle: any;

	constructor() {
		this._handle = new Promise((resolve, reject) => {
			this._result = {resolve, reject};
		});
	}

	get result(): any {
		return this._result;
	}

	get handle(): any {
		return this._handle;
	}
}
