import * as THREE from 'three';
import {connectorRadius} from "../options";
import {engine} from "../engine/engine";
import {Mesh} from "../nodeTree/objects/Mesh";
import {info} from "../info";
import {generateID} from "../utils/utils";
import {PartGroupNode} from "../nodeTree/PartGroupNode";

export class Connector {
	private originalPosition: THREE.Vector3;
	private _rotation: number;
	private _id: string;
	private _uid: string;
	private area: THREE.Sphere;
	private boundingBox: THREE.Box3;
	// private box3Helper: THREE.Box3Helper; // for testing
	private _intersectedPieces: any[];

	constructor(data: any) {
		this.originalPosition = new THREE.Vector3(data.x, data.z, data.y);
		this._rotation =  data.rotation;
		this._id = data._id;
		this._uid = generateID();
		this.area = new THREE.Sphere(this.originalPosition.clone(), connectorRadius);
		this.boundingBox = this.area.getBoundingBox(new THREE.Box3());
		// this.box3Helper = new THREE.Box3Helper( this.boundingBox, new THREE.Color( 0xff0000 ) );
		// engine.scene.add( this.box3Helper );
	}

	get uid(): string {
		return this._uid;
	}
	get id(): string {
		return this._id;
	}
	get rotation(): number {
		return this._rotation;
	}
	get position(): THREE.Vector3 {
		return this.area.center;
	}
	get intersectedPieces(): any[] {
		return this._intersectedPieces;
	}

	public update(parent: PartGroupNode): void {
		parent.groupNode.updateMatrix();
		this.resetTransformations();
		this.area.applyMatrix4(parent.groupNode.matrix);

		this.updateBoundingBox();
	}

	private updateBoundingBox(): void {
		this.boundingBox = this.area.getBoundingBox(this.boundingBox);
		this.boundingBox.max.y = 1;
		// this.box3Helper.box.copy(this.boundingBox); // for testing
	}

	private resetTransformations(): void {
		this.area.center.copy(this.originalPosition);
	}

  public initIntersections(): void {
    this._intersectedPieces = this.getIntersectedPieces();
  }

  public removeExcessPartNodes(): void {
    const intersectedPieces = this._intersectedPieces;

		const indices = checkCon(intersectedPieces);
		if(indices.length > 0) {
			for(let i=0; i<intersectedPieces.length; i++) {
				if(indices.indexOf(i)!==-1) continue;
				removePieces(intersectedPieces[i]);
			}
		} else {
			for(let i=1; i<intersectedPieces.length; i++) {
				removePieces(intersectedPieces[i]);
			}
		}
	}

	private getIntersectedPieces(): any[] {
		const meshes = this.getIntersectedMeshes();
		const intersectedPieces = {};
		for(const mesh of meshes) {
			const partNodeInfo = info.getPartNodeInfoByMeshUID(mesh.uid);
			if(!intersectedPieces[partNodeInfo.parent.uid]) intersectedPieces[partNodeInfo.parent.uid] = {};
			intersectedPieces[partNodeInfo.parent.uid][mesh.name] = partNodeInfo;
		}
 // console.log(intersectedPieces);
		return objectToArray(intersectedPieces);
	}

	public getIntersectedMeshes(): Mesh[] {
		engine.scene.updateWorldMatrix(false, true);
		const meshes: Mesh[] = [];
		for(const object of engine.scene.children) {
			if(object instanceof THREE.Object3D) {
				for(const mesh of object.children) {
					if(mesh instanceof Mesh && mesh.name !== 'shadow' && mesh.name !== '' && mesh.name !== 'Footprint') {
						this.updateBoundingBox();
						if(this.boundingBox.intersectsBox(mesh.boundingBox)) {
							meshes.push(mesh);
						}
					}
				}
			}
		}
		return meshes;
	}
}

function objectToArray(object: any): any[] {
	const array = [];
	for(const key in object) {
		if(!object.hasOwnProperty(key)) continue;
		array.push(object[key]);
	}
	return array;
}

function checkCon(intersectedPieces): number[] {
	const indices = [];
	for(let i=0; i<intersectedPieces.length; i++) {
		if(intersectedPieces[i]['Con 60 Legs'] || intersectedPieces[i]['Con 90 Legs']) indices.push(i);
	}
	return indices;
}

function removePieces(intersectedPieces: any[]): void {
	if(intersectedPieces['Leg']) intersectedPieces['Leg'].partNode.destruct();
	if(intersectedPieces['Leg Screws']) intersectedPieces['Leg Screws'].partNode.destruct();
}
