import * as THREE from 'three';
import {errMsg} from './utils/utils';
import {ProductNode} from "./nodeTree/ProductNode";
import {Product} from "./dataTree/Product";
import {ArrayOfUniqueItems} from "./utils/ArrayOfUniqueItems";
import {Style} from "./dataTree/Style";
import {mapCollection} from "./maps/mapCollection";
import {PartNode} from "./nodeTree/PartNode";
import {Connector} from "./dataTree/Connector";
import {connectorRadius, partTypes, defaultPartStyles} from "./options";
import {engine} from "./engine/engine";
import {hasOwnProperty} from "tslint/lib/utils";
import {PartGroupNode} from "./nodeTree/PartGroupNode";

class Info {

	private productNode: ProductNode;
	private product: Product;

	public setProduct(product: Product) {
		this.product = product;
	}

	public setProductNode(productNode: ProductNode) {
		this.productNode = productNode;
	}

	public reset(): void {
		this.productNode = null;
		this.product = null;
	}

	public getPartGroupInfo(id: string): any {

		for(const partGroup of this.product.partGroups) {
			if(partGroup.id === id) return { partGroup, parent: this.product };
		}
	}

	public getPartInfo(id: string): any {

		for(const partGroup of this.product.partGroups) {
			for(const part of partGroup.parts) {
				if(part.id === id) return { part, parent: partGroup };
			}
		}
	}

	public getPartGroupNodeInfo(uid: string): any {

		for(const partGroupNode of this.productNode.partGroupNodes) {
			if(partGroupNode.uid === uid) return { partGroupNode, parent: this.productNode };
		}
	}

	public getPartGroupNodeInfo2(id: string): any {

		for(const partGroupNode of this.productNode.partGroupNodes) {
			if(partGroupNode.id === id) return { partGroupNode, parent: this.productNode };
		}
	}

	public getPartNodeInfo(uid: string): any {

		for(const partGroupNode of this.productNode.partGroupNodes) {
			for(const partNode of partGroupNode.partNodes) {
				if(partNode.uid === uid) return { partNode, parent: partGroupNode };
			}
		}
    // console.log(uid + ' RETURNED UNDEFINED');
	}

	public getPartNodeInfos(id: string): any {

		const infos = [];
		for(const partGroupNode of this.productNode.partGroupNodes) {
			for(const partNode of partGroupNode.partNodes) {
				if(partNode.id === id)  infos.push( { partNode, parent: partGroupNode });
			}
		}
		return infos;
	}

	public getPartNodeInfoByMeshUID(uid: string): any {

		for(const partGroupNode of this.productNode.partGroupNodes) {
			for(const partNode of partGroupNode.partNodes) {
				if(partNode.meshExists(uid)) return { partNode, parent: partGroupNode };
			}
		}
	}

	public getStyleInfo(id: string): any {

		for(const partGroup of this.product.partGroups) {
			for(const part of partGroup.parts) {
				for(const style of part.styles) {
					if (style.id === id) return {style, parent: part};
				}
			}
		}
	}

	public getStyleInfos(id: string): any[] {

		const infos = [];
		for(const partGroup of this.product.partGroups) {
			for(const part of partGroup.parts) {
				for(const style of part.styles) {
					if (style.id === id) infos.push({style, parent: part});
				}
			}
		}
		return infos;
	}

	public getCurrentMapUrlList(): ArrayOfUniqueItems<string> {

		const currentMapUrlList: ArrayOfUniqueItems<string> = new ArrayOfUniqueItems();

		for(const partGroupNode of this.productNode.partGroupNodes) {

			if(!partGroupNode.partNodes[0]) continue;
			const part = partGroupNode.partNodes[0].part;
			if(part.materialSet) currentMapUrlList.addItems(part.materialSet.imageUrls);
			if(part.shadowImage) currentMapUrlList.addItem(part.shadowImage);

			const style = part.styles[0];
			if(style && style.materialSet) currentMapUrlList.addItems(style.materialSet.imageUrls);
		}
		return currentMapUrlList;
	}

	public getStyleNameList(): ArrayOfUniqueItems<string> {

		const styleNameList: ArrayOfUniqueItems<string> = new ArrayOfUniqueItems();
		const styles = this.getAllStyleList();
		for(const style of styles) styleNameList.addItem(style.name);

		return styleNameList;
	}

	public getStyleIdList(): ArrayOfUniqueItems<string> {

		const styleNameList: ArrayOfUniqueItems<string> = new ArrayOfUniqueItems();
		const styles = this.getAllStyleList();
		for(const style of styles) styleNameList.addItem(style.id);

		return styleNameList;
	}

	public getAllStyleList(): Style[] {

		const allStyles = [];
		for(const partGroup of this.product.partGroups) {
			for(const part of partGroup.parts) {
				allStyles.push(...part.styles);
			}
		}
		return allStyles;
	}

	public getLoadingList(): string[] {
		const loadingList = mapCollection.getCategoryList();
		loadingList.addItem('geometry');
		return loadingList;
	}

	public partGroupExists(id: string): void {
		if(this.getPartGroupInfo(id)) throw errMsg('Not unique ID', `PartGroup with the id:${id} already exists`);
	}

	public partExists(id: string): void {
		if(this.getPartInfo(id)) throw errMsg('Not unique ID', `Part with the id:${id} already exists`);
	}

	public styleExists(id: string): void {
		if(this.getStyleInfo(id)) throw errMsg('Not unique ID', `Style with the id:${id} already exists`);
	}

	public getSelectorInfo(partName: string): any {

		const selectorInfo = {};

		for(const partGroupNode of this.productNode.partGroupNodes) {
			for(const partNode of partGroupNode.partNodes) {
			  if(!this.comparePartTypes(partName, partNode.part.name)) continue;
				for(const style of partNode.styles) {
					if(!selectorInfo[style.name]) selectorInfo[style.name] = {};
					if(style.imageColor) selectorInfo[style.name]['imageColor'] = style.imageColor;
					if(style.imageUrl) selectorInfo[style.name]['imageUrl'] = style.imageUrl;
				}
			}
		}
		return selectorInfo;
	}

	public getPartNodesByPartType(partType: string): PartNode[] {

    const partNodes: PartNode[] = [];

    for(const partGroupNode of this.productNode.partGroupNodes) {
      for(const partNode of partGroupNode.partNodes) {
        if(info.getPartType(partNode.part.name) === partType) partNodes.push(partNode);
      }
    }
    return partNodes;
  }

	public getPartNodesByStyleName(styleName: string): PartNode[] {

		const partNodes: PartNode[] = [];

		for(const partGroupNode of this.productNode.partGroupNodes) {
			for(const partNode of partGroupNode.partNodes) {
				for(const style of partNode.styles) {
					if(style.name === styleName) partNodes.push(partNode);
				}
			}
		}
		return partNodes;
	}

	public getAllConnectors(): Connector[] {
		const connectors = [];
		for(const partGroupNode of this.productNode.partGroupNodes) {
			for(const connector of partGroupNode.connectors) {
				connectors.push(connector);
			}
		}
		return connectors;
	}

	public getConnectorsWithUniquePositions(): Connector[] {
		const connectors = this.getAllConnectors();
		for(let i=0; i<connectors.length; i++) {
			for(let j=connectors.length-1; j>i; j--) {
				if(comparePositions(connectors[i].position, connectors[j].position)) connectors.splice(j,1);
			}
		}
		return connectors;
	}

	public getAllArms(): PartNode[] {
		const arms: PartNode[] = [];

		for(const partGroupNode of this.productNode.partGroupNodes) {
			for(const partNode of partGroupNode.partNodes) {
				if(partNode.model.mesh.name === 'Arm') arms.push(partNode);
			}
		}
		return arms;
	}

  public getArmsOfPiece(partGroupNodeUid: string): PartNode[] {
    const arms: PartNode[] = [];
    const partGroupNodeInfo = this.getPartGroupNodeInfo(partGroupNodeUid);

    for(const partNode of partGroupNodeInfo.partGroupNode.partNodes) {
      if(partNode.model.mesh.name === 'Arm') arms.push(partNode);
    }
    return arms;
  }

  public getPartType(partName: string): string {
	  for(const type in partTypes) {
	    if(!partTypes.hasOwnProperty(type)) continue;
	    if(partTypes[type].indexOf(partName) !== -1) return type;
    }
    return null;
  }

  public getDefaultStyleName(partType: string): string {
    if(partType && defaultPartStyles[partType]) {
      return defaultPartStyles[partType];
    }

    return null;
  }

  public comparePartTypes(partNameA: string, partNameB: string): boolean {
    for(const type in partTypes) {
      if(!partTypes.hasOwnProperty(type)) continue;
      if(partTypes[type].indexOf(partNameA) !== -1 && partTypes[type].indexOf(partNameB) !== -1) return true;
    }
    return false;
  }

  public getAmountOfConnectedSeats(conUid: string): number {
    const partGroupNodeInfo = this.getPartGroupNodeInfo(conUid);
    if(!partGroupNodeInfo) return null;
    const partGroupNode = partGroupNodeInfo.partGroupNode;
    if(!/60-connector|90-connector/.test(partGroupNode.partGroup.slug)) return null;
    let amount = 0;
    for(const connector of partGroupNode.connectors) {
      if(connector.intersectedPieces.length < 2) continue;
      connector.intersectedPieces.forEach((intersectedPiece) => {
        if(intersectedPiece['Leg']) amount++;
      });
    }
    return amount;
  }

  public generatePartGroupNodeCodes(partGroupNode: PartGroupNode): any {
    const slug = partGroupNode.partGroup.slug;
    let codeA: string;
    let codeB: string;
    let connections: string;
    let amount = this.getAmountOfConnectedSeats(partGroupNode.uid);
    if(amount !== null) amount++;
    const armsCode = partGroupNode.armsCode;
    const intAC = parseInt(armsCode, 10);
    const cabinetStyle = this.bbqCabinetHasStainlessSteelStyle(partGroupNode) ? 'ST' : 'TD';

    switch(slug) {
      case '600mm-seat': codeA = 'HYV-SEAT-001-600'; break;
      case '1000mm-seat': codeA = 'HYV-SEAT-001-1000'; break;
      case '1500mm-seat': codeA = 'HYV-SEAT-001-1500'; break;
      case '2000mm-seat': codeA = 'HYV-SEAT-001-2000'; break;

      case '600mm-seat-with-backrest': codeB = `HYV-BKRT-${armsCode}-520`; codeA = 'HYV-SEAT-001-600'; connections = `Connection C${intAC*2-1}`; break;
      case '1000mm-seat-with-backrest': codeB = `HYV-BKRT-${armsCode}-920`; codeA = 'HYV-SEAT-001-1000'; connections = `Connection C${intAC*2-1}`; break;
      case '1500mm-seat-with-backrest': codeB = `HYV-BKRT-${armsCode}-1420`; codeA = 'HYV-SEAT-001-1500'; connections = `Connection C${intAC*2}`; break;
      case '2000mm-seat-with-backrest': codeB = `HYV-BKRT-${armsCode}-1920`; codeA = 'HYV-SEAT-001-2000'; connections = `Connection C${intAC*2}`; break;

      case 'single-table-1000mm': codeA = 'HYV-STAB-001-1000'; connections = 'Connections J1'; break;
      case 'single-table-1500mm': codeA = 'HYV-STAB-001-1500'; connections = 'Connections J1'; break;
      case 'single-table-2000mm': codeA = 'HYV-STAB-001-2000'; connections = 'Connections J1'; break;
      case 'single-table-3000mm': codeA = 'HYV-STAB-002-3000'; connections = 'Connections J2'; break;
      case 'single-table-4000mm': codeA = 'HYV-STAB-002-4000'; connections = 'Connections J2'; break;

      case 'double-table-1000mm': codeA = 'HYV-DTAB-001-1000'; connections = 'Connections J3'; break;
      case 'double-table-1500mm': codeA = 'HYV-DTAB-001-1500'; connections = 'Connections J3'; break;
      case 'double-table-2000mm': codeA = 'HYV-DTAB-001-2000'; connections = 'Connections J3'; break;
      case 'double-table-3000mm': codeA = 'HYV-DTAB-002-3000'; connections = 'Connections J4'; break;
      case 'double-table-4000mm': codeA = 'HYV-DTAB-002-4000'; connections = 'Connections J4'; break;

      case 'greencell-1000x500mm': codeA = 'HYV-GC-SM-001-1000-DEL'; connections = 'See Greencell install'; break;
      case 'greencell-2000x500mm': codeA = 'HYV-GC-SM-001-2000-DEL'; connections = 'See Greencell install'; break;
      case 'greencell-3000x500mm': codeA = 'HYV-GC-SM-001-3000-DEL'; connections = 'See Greencell install'; break;
      case 'greencell-4000x500mm': codeA = 'HYV-GC-SM-001-4000-DEL'; connections = 'See Greencell install'; break;
      case 'greencell-1000x1000mm': codeA = 'HYV-GC-SM-002-DEL'; connections = 'See Greencell install'; break;

      case 'bar-table-1500mm': codeA = 'HYV-STAB-003-1500'; connections = 'Connection J5'; break;
      case 'bar-table-2000mm': codeA = 'HYV-STAB-003-2000'; connections = 'Connection J5'; break;
      case 'bar-table-3000mm': codeA = 'HYV-STAB-003-3000'; connections = 'Connection J6'; break;
      case 'bar-table-4000mm': codeA = 'HYV-STAB-003-4000'; connections = 'Connection J6'; break;

      case 'bar-stool': codeA = 'HYV-STL-001'; connections = 'Connection K1'; break;
      case 'bar-stool-with-backrest': codeA = 'HYV-STL-002'; connections = 'Connection K2'; break;

      case '60-connector': codeA = `HYV-CON60-00${amount}`; connections = `Connections F${amount} & H2`; break;
      case '90-connector': codeA = `HYV-CON90-00${amount}`; connections = `Connections E${amount} & H1`; break;

      case 'coffee-table': codeA = 'HYV-CTAB-001'; connections = 'Connection A'; break;

      case 'frontier-single-bbq': codeA = `FSB-SC-S${cabinetStyle}-001`; break;
      case 'edge-single-bbq': codeA = `ESB-SC-S${cabinetStyle}-001`; break;
      case 'frontier-double-bbq': codeA = `FDB-DC-S${cabinetStyle}-001`; break;
      case 'edge-double-bbq': codeA = `EDB-DC-S${cabinetStyle}-001`; break;
    }

    return {codeA, codeB, connections};
  }

  private bbqCabinetHasStainlessSteelStyle(partGroupNode: PartGroupNode): boolean {
    if(!/BBQ/ig.test(partGroupNode.partGroup.name)) return null;
    for(const partNode of partGroupNode.partNodes) {
      // if(/Cabinet/ig.test(partNode.part.name)) console.log(partNode.style.name);
      if(/Cabinet/ig.test(partNode.part.name) && partNode.style && /Stainless Steel/ig.test(partNode.style.name)) return true;
    }
    return false;
  }

  public addSlatFinish(code: string, partGroupNode: PartGroupNode): string {
    const slatFinish = this.getSlatFinish(partGroupNode.partNodes);
    if(slatFinish && /HYV-SEAT|HYV-BKRT|HYV-STAB|HYV-DTAB|HYV-STL/ig.test(code)) code += '-' + slatFinish;
    return code;
  }

  private getSlatFinish(partNodes: PartNode[]): string {
	  let styleSlug;
    for(const partNode of partNodes) {
      const partType = this.getPartType(partNode.part.name);
      if(partType === 'slats') {
        if(partNode.style) {
          styleSlug = partNode.style.slug;
          break;
        }
      }
    }
    if(!styleSlug) return;

    let slatFinish;
	  switch(styleSlug) {
      case 'anodized': slatFinish = 'DEL';
        break;
      case 'western-red-cedar': slatFinish = 'WRC';
        break;
      case 'blackbutt': slatFinish = 'BB';
        break;
	  default: slatFinish = 'DSG';
    }
    return slatFinish;
  }
}

export const info = new Info();

function comparePositions(p1: THREE.Vector3, p2: THREE.Vector3): boolean {
	const distance = p1.clone().sub(p2).length();
	return distance < connectorRadius;
}
