interface IOptions {
  variableScaleFactor: number,
	extraScaleFactor: number,
	globalZoomFactor: number,
  verticalShift: number,
	incrementAngle: number,
	magneticThreshold: number,
  backgroundSize: number
}
export const options: IOptions = {
  variableScaleFactor: 1,
	extraScaleFactor: 0.745,
	globalZoomFactor: 300,
  verticalShift: 40,
	incrementAngle: 30,
	magneticThreshold: 140,
  backgroundSize: 224
};
