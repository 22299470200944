import * as THREE from 'three';
import {Part} from '../dataTree/Part';
import {Style} from '../dataTree/Style';
import {MaterialSet} from '../dataTree/MaterialSet';
import {Material} from './materials/Material';
import {Model} from './objects/Model';
import {Shadow} from './objects/Shadow';
import {geometryCollection} from '../geometries/geometryCollection';
import {info} from "../info";
import {mapCollection} from "../maps/mapCollection";
import {Object3d} from "./objects/Object3d";
import {generateID} from "../utils/utils";

export class PartNode {
	private _model: Model;
	private shadow: Shadow;
	private _style: Style;
	private _uid: string;

	constructor(private _part: Part) {
		geometryCollection.addBundleUrl(_part.bundleUrl);

		mapCollection.addMapUrls('base', _part.materialSet.imageUrls);
		if(_part.shadowImage) mapCollection.addMapUrls('shadow', [_part.shadowImage]);

		for(const style of _part.styles) {
			if(style) mapCollection.addMapUrls(style.name, style.materialSet.imageUrls);
		}

		this._uid = generateID();
	}

	get uid(): string {
		return this._uid;
	}
	get part(): Part {
		return this._part;
	}
	get id(): string {
		return this._part.id;
	}
	get styles(): Style[] {
		return this._part.styles;
	}
	get style(): Style {
		return this._style;
	}
	get model(): Model {
		return this._model;
	}
	set visible(value: boolean) {
		if(this._model) this._model.mesh.visible = value;
		if(this.shadow) this.shadow.mesh.visible = value;
	}
  get visible(): boolean {
    return this._model.mesh.visible;
  }
	public meshExists(id: string): boolean {
		return this._model && this._model.mesh.uid === id;
	}
	setStyle(stl) {
		this._style = stl;
	}

	public addToScene(groupNode: THREE.Group): void {
		const geometries = geometryCollection.getGeometries(this._part.bundleUrl);
		this._model = new Model(geometries.modelGeometry, new Material());
		this._model.addToScene(groupNode);
		const mirrorFactor = this._part.isMirrored ? -1 : 1;
		this.setupObject(this._model, this._part.name, mirrorFactor);

		if(geometries.shadowGeometry) {
			this.shadow = new Shadow(geometries.shadowGeometry, new Material());
			this.shadow.addToScene(groupNode);
			this.setupObject(this.shadow, 'shadow', mirrorFactor);
		}
    const partType = info.getPartType(this.part.name);
		const defaultStyleName = info.getDefaultStyleName(partType);
		const firstStyle = this.part.styles[0]? this.part.styles[0].name : undefined;
    const styleName = defaultStyleName ? defaultStyleName : firstStyle;
// console.log(styleName);
    this.updateMaterial(styleName);
	}

	private setupObject(object: Object3d, name: string, mirrorFactor: number): void {
		object.mesh.name = name;
		object.mesh.rotation.z += this._part.angle;
		object.mesh.position.add(this._part.position);
		object.mesh.scale.x *= mirrorFactor;
	}

	public updateMaterial(styleName: string): void {
		this._style = this.getStyleByName(styleName);

		if(this._model) {
			const materialSet = this._style ? this._part.materialSet.merge(this._style.materialSet) : this._part.materialSet;
			materialSet.items.transparent = <boolean>!!this._part.transparent;
			this._model.updateMaterial(materialSet);
		}
		if(this.shadow) {
			const materialSet = new MaterialSet();
			materialSet.items.map = this._part.shadowImage;
			this.shadow.updateMaterial(materialSet);
		}
	}

	public translate(deltaVector: THREE.Vector3): void {
		this._model.mesh.position.add(deltaVector);
		if(this.shadow) this.shadow.mesh.position.add(deltaVector);
	}

	public rotate(deltaAngle: number): void {
		this._model.mesh.rotation.z += deltaAngle;
		if(this.shadow) this.shadow.mesh.rotation.z += deltaAngle;
	}

	public getStyleByName(name: string): Style {
		return this._part.styles.find((style: Style): boolean => style.name === name);
	}

	public destruct(): void {
		if(this._model) {
			this._model.destruct();
			// geometryCollection.removeBundle(this._part.bundleUrl);
		}
		this._model = null;

		if(this.shadow) this.shadow.destruct();
		this.shadow = null;

		const partNodeInfo = info.getPartNodeInfo(this.uid);
		if(!partNodeInfo) return;
		partNodeInfo.parent.partNodes.splice(partNodeInfo.parent.partNodes.indexOf(partNodeInfo.partNode), 1);
	}
}
