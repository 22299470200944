import * as THREE from 'three';
import {loadZip} from '../utils/utils';
import Collada from '../../lib/colladaX';
import {colladaCode} from '../../lib/colladaWorker';
import {CPromise} from '../utils/CPromise';

interface IDAEs {
	modelDae?: any,
	shadowDae?: any
}

export interface IGeometries {
	modelGeometry?: THREE.BufferGeometry,
	shadowGeometry?: THREE.BufferGeometry,
	promise?: Promise<IGeometries>
}

interface IGeometryStore {
	[idx: string]: IGeometries; // we use urls of bundles as indices
}

class GeometryCache {

	private geometryStore: IGeometryStore = {};

	public load(url: string): Promise<IGeometries> {

		const cacheGeometries = this.getGeometries(url);

		if(cacheGeometries) {
			if(cacheGeometries.modelGeometry) {
				return new Promise((resolve) => { resolve(cacheGeometries); });
			} else return cacheGeometries.promise;
		}

		const that = this;
		const promise = this.loadFile(url)
			.then(value => {
				const geometries = this.getGeometries(url);
				geometries.modelGeometry = colladaToGeometry(value.modelDae);

				if(value.shadowDae !== undefined) {
					geometries.shadowGeometry = colladaToGeometry(value.shadowDae);
				}
				that.saveGeometries(url, geometries);

				return geometries;
			});
		this.saveGeometries(url, {promise: promise});

		return promise;
	}

	private loadFile(url: string): Promise<IDAEs> {

		const modelPromise = new CPromise();
		const shadowPromise = new CPromise();

		const daes: IDAEs = {};

		loadZip(url)
			.then( sources => {
				let modelBlob;
				let shadowBlob;

				for(const source of sources) {
					if(source['modelSource.dae'] !== undefined) modelBlob = source['modelSource.dae'];
					if(source['shadowModelSource.dae'] !== undefined) shadowBlob = source['shadowModelSource.dae'];
				}

				const colladaBlob = new Blob([colladaCode], {type: 'application/javascript'});
				const colladaPath = URL.createObjectURL(colladaBlob);

				Collada.init({ forceParser: false, dataPath: '', colladaPath: colladaPath });

				Collada.parseInWorker(modelBlob, (collada) => {
					URL.revokeObjectURL(colladaPath);
					URL.revokeObjectURL(modelBlob);
					daes.modelDae = collada;
					modelPromise.result.resolve();
				});

				if(modelBlob && shadowBlob) {
					Collada.parseInWorker(shadowBlob, (collada) => {
						URL.revokeObjectURL(shadowBlob);
						daes.shadowDae = collada;
						shadowPromise.result.resolve();
					});
				} else shadowPromise.result.resolve();

			}).catch((err) => {
				console.warn('Zip problem');
				if(err) console.log(err.stack);
			});

		return Promise.all([modelPromise.handle, shadowPromise.handle]).then(() => daes);
	}

	private saveGeometries(idx: string, geometries: IGeometries): void {

		this.geometryStore[idx] = geometries;
	}

	public getGeometries(idx: string): IGeometries {
		return this.geometryStore[idx];
	}

	public removeGeometries(idx: string): void {

		if(this.geometryStore[idx]) delete this.geometryStore[idx];
	}

	public clean(): void {

		for(const key in this.geometryStore) {
			if(!this.geometryStore.hasOwnProperty(key)) continue;
			this.removeGeometries(key);
		}
	}
}

function colladaToGeometry(collada: any): THREE.BufferGeometry {
	const geometry = new THREE.BufferGeometry();

	for(const key in collada.meshes) {

		if(!collada.meshes.hasOwnProperty(key)) continue;

		geometry.setAttribute('position', new THREE.BufferAttribute(collada.meshes[key].vertices, 3));
		geometry.setAttribute('normal', new THREE.BufferAttribute(collada.meshes[key].normals, 3));
		geometry.setAttribute('uv', new THREE.BufferAttribute(collada.meshes[key].coords, 2));
		break;
	}
	geometry.applyMatrix4(new THREE.Matrix4().fromArray(collada.root.children[0].model));
	(<any>geometry.attributes).uv2 = (<any>geometry.attributes).uv;
	// geometry.applyMatrix4( new THREE.Matrix4().makeTranslation( 0, 0, 0 ) );
	// console.log('GEOMETRY');

	return geometry;
}

export const geometryCache = new GeometryCache();