import {Unit} from './Unit';
import {Connector} from './Connector';
import {allConnectors} from './allConnectors';
import {generateID, loadJSON} from './utils/utils';
import {layersControl} from './layersControl';
import {getAngle} from './utils/dom';
import {options} from './options';

class Configurator {
	private data: any;
	private container: HTMLElement;
  private currentCategory: string;
  private pointerDown: boolean = false;
  private timeout: number;
  private fullWidth = ['Double Table 2000mm', 'Double Table 3000mm', 'Double Table 4000mm', 'Single Table 2000mm', 'Single Table 3000mm', 'Single Table 4000mm']
  private _footprint: Unit;

	constructor() {}

	public async run(container: HTMLElement, url: string): Promise<void> {
		this.container = container;
		await this.loadData(url);
    createBubble('Drag me');
		// this.showConfiguration(this.data.configurations[17]);
	}

	public getCurrentConfiguration(): any {
		const sprites = [];
		// console.log(layersControl.units);
		for(const id in layersControl.units) {
			if(!layersControl.units.hasOwnProperty(id)) continue;
			const unit = layersControl.units[id];
			const sprite = {
				_id: unit.spriteID,
				rotation: getAngle(unit.html) * Math.PI / 180,
				x: unit.position.x / options.globalZoomFactor,
				y: unit.position.y / options.globalZoomFactor,
				z: 0,
        width: unit.width / options.globalZoomFactor,
        height: unit.height / options.globalZoomFactor,
				connectors: [],
        armsVisibility: unit.armsVisibility
			};
			for(const connector of unit.connectors) {
				if(connector.linked && connector.locked) sprite.connectors.push({ _id: connector.linked.parentID });
			}
			sprites.push(sprite);
		}
		// console.log(sprites);
		// console.log(JSON.stringify(sprites));
		return JSON.stringify(sprites);
	}

  get footprint(): Unit {
    return this._footprint;
  }

  set footprint(value: Unit) {
    this._footprint = value;
  }

  set armsVisibility(armsVisibility: boolean[]) {
    for(const id in layersControl.units) {
      if(!layersControl.units.hasOwnProperty(id)) continue;
      const unit = layersControl.units[id];
      unit.setArmsVisibility(armsVisibility);
    }
  }

	public getSizeString(): string {
    // console.log(options.variableScaleFactor);
    // console.log(this.container.getBoundingClientRect());
    const size = this.container.getBoundingClientRect();
    const w = size.width / options.variableScaleFactor / options.globalZoomFactor / options.extraScaleFactor;
    const h = (size.height / options.variableScaleFactor / options.extraScaleFactor - options.verticalShift) / options.globalZoomFactor;
    return Math.round(w * 1000) + 'mm x ' + Math.round(h * 1000) + 'mm';
  }

	public setConfigurationIcons(configurationIconContainer: HTMLElement, callback): void {
		for(const configuration of this.data.configurations) {
			// console.log(sprite.slug + ' : ' + sprite.icon);
			const configurationIcon = document.createElement('img');
			configurationIcon.className = 'icon';
			configurationIcon.src = configuration.image;
      configurationIcon.style.cursor = 'pointer';
			// configurationIcon.setAttribute('data-id', configuration._id);
			configurationIconContainer.appendChild(configurationIcon);
      configurationIcon.addEventListener('pointerdown', async () => { await callback(); this.showConfiguration(configuration); });
		}
	}

	public setFurnitureIcons(furnitureIconContainer: HTMLElement): void {
		for(const sprite of this.data.sprites) {
      if(!sprite.icon) continue;
			// console.log(sprite.slug + ' : ' + sprite.icon);
      if(this.currentCategory !== sprite.category) {
        this.currentCategory = sprite.category;
        const title = document.createElement('div');
        title.className = 'category';
        title.textContent = this.currentCategory;
        furnitureIconContainer.appendChild(title);
      }

      const container = document.createElement('div');
      container.className = 'info-container';

			const furnitureIcon = document.createElement('img');
			furnitureIcon.className = 'icon';
			furnitureIcon.src = sprite.icon;
			furnitureIcon.style.cursor = 'pointer';

      furnitureIcon.ondragstart = () => false;
			container.appendChild(furnitureIcon);

      const width = document.createElement('p');
      width.className = 'size';
      width.innerText = sprite.name;
      container.appendChild(width);
      if (this.fullWidth.includes(sprite.name)) {
        container.style.width = '76%';
      }
      furnitureIconContainer.appendChild(container);
      furnitureIcon.addEventListener('pointerdown', (event: MouseEvent|TouchEvent) => {
        this.pointerDown = true;
        this.timeout = window.setTimeout(() => {
          this.showBubble({
            x: (<TouchEvent>event).touches ? (<TouchEvent>event).touches[0].pageX : (<MouseEvent>event).clientX,
            y: (<TouchEvent>event).touches ? (<TouchEvent>event).touches[0].pageY : (<MouseEvent>event).clientY
          });
        }, 100);
      });
      furnitureIcon.addEventListener('pointermove', (event: MouseEvent|TouchEvent) => {
        document.getElementById('bubble').style.display = 'none';
        window.clearTimeout(this.timeout);
        this.createUnit(event, sprite);
      });
      furnitureIcon.addEventListener('pointerup', () => {
        this.pointerDown = false;
        // document.getElementById('bubble').style.display = 'none';
        // window.clearTimeout(this.timeout);
      });
    }
	}

	private showBubble(point: any): void {
	  const bubble = document.getElementById('bubble');
	  const dY = 30;
    bubble.style.display = 'block';
    bubble.style.left = point.x + 'px';
    bubble.style.top = point.y - dY + 'px';
  }

	public cleanScene(): void {
		for(const id in layersControl.units) {
			if(!layersControl.units.hasOwnProperty(id)) continue;
			const unit = layersControl.units[id];
			unit.removeUnit();
		}
	}

	private createUnit(event: MouseEvent|TouchEvent, sprite: any): void {
    if(!this.pointerDown) return;
    this.pointerDown = false;
		// console.log((<HTMLElement>event.target).getAttribute('data-id'));
		const unit = this.newUnit(sprite);
		unit.moveFrom(event);
	}

	private newUnit(sprite: any): Unit {
		const unit = new Unit(generateID(), this.container, sprite);
		for(const connectorData of sprite.connectors) {
			const connector = new Connector(unit, connectorData);
			allConnectors.push(connector);
			unit.addConnectors(connector);
		}
		return unit;
	}

  private setFootprint(width: number, height: number): void {
    const footprintSprite = this.data.sprites.find(sprite => sprite.slug === 'footprint');
    footprintSprite.width = width;
    footprintSprite.height = height;
    this._footprint = new Unit(generateID(), this.container, footprintSprite);
    this._footprint.block = true;
    this._footprint.moveToCenter();
    this._footprint.scaleAndCenter();
  }

  public setFootprintSize(width: number, height: number): void {
	  if(this._footprint) {
      if(width === 0 || height === 0) {
          this._footprint.removeUnit();
          this._footprint = null;
        } else this._footprint.setSize(width, height);
        return;
	  }
	  if(width > 0 && height > 0) this.setFootprint(width, height);
  }

  public showConfiguration(configuration: any): void {
	  // console.log(configuration);
		if(!configuration) return;
		const sprites = this.data.sprites;
		let unit;
		this.cleanScene();
		for(const sprite of configuration.sprites) {
		  const spt = sprites.find(sp => sp._id === sprite._id);
      if(sprite._id === '6114c5a939822c25a67679fd') { spt.width = sprite.width; spt.height = sprite.height; }
			unit = this.newUnit(spt);
      if(sprite._id === '6114c5a939822c25a67679fd') {
        unit.block = true;
        unit.order = -1;
        this._footprint = unit;
      }
      unit.rotate(sprite.rotation);
      unit.translate(sprite.x, sprite.y);
		}
		if(unit) unit.scaleAndCenter();
	}

  private loadData(url: string): Promise<void> {
    return loadJSON(url).then((data: JSON) => {
      this.data = data;
      this.emitJsonLoaded();
    });
  }

  private emitJsonLoaded(): void {
    const event = new CustomEvent('JsonLoaded');
    window.dispatchEvent(event);
  }
}

export const configurator2d = new Configurator();

function createBubble(text: string): void {
  const bubble = document.createElement('div');
  bubble.id = 'bubble';
  bubble.textContent = text;
  document.body.appendChild(bubble);
}
