export class ArrayOfUniqueItems<T> extends Array<T> {

	constructor(...items) {
		super(...items);
		Object.setPrototypeOf(this, ArrayOfUniqueItems.prototype);
	}

	public addItem(item: T): void {

		if(this.indexOf(item) === -1) this.push(item);
	}

	public addItems(items: T[]): void {

		for(const item of items) {
			this.addItem(item);
		}
	}

	public removeItem(item: T): void {

		const idx = this.indexOf(item);
		if(idx !== -1) this.splice(idx, 1);
	}

	public exists(item: T): boolean {

		return this.indexOf(item) !== -1;
	}
}
