import * as THREE from 'three';
import {opts} from '../options';
import {Composer} from './Composer';
import {CameraControls} from './CameraControls';
import {stage3D} from './stage3D';
import {Highlighter} from './Highlighter';

class Engine {

	private _renderer: THREE.WebGLRenderer;
	private _scene: THREE.Scene;
	private _camera: THREE.PerspectiveCamera;
	private _cameraControls: CameraControls;
	private _composer: Composer;
	private _highlighter: Highlighter;
	private stopRender: boolean = false;

	public start(): void {
		stage3D.init();

		this.initRenderer();
		this.initScene();
		this.initCamera();
		this.initComposer();
		this.initCameraControls();

		this._highlighter = new Highlighter();

		this.onResize = this.onResize.bind(this);
		stage3D.addResizeListener(this.onResize);
	}

	get renderer(): THREE.WebGLRenderer {
		return this._renderer;
	}

	get scene(): THREE.Scene {
		return this._scene;
	}

	get camera(): THREE.PerspectiveCamera {
		return this._camera;
	}

	get cameraControls(): CameraControls {
		return this._cameraControls;
	}

	get composer(): Composer {
		return this._composer;
	}

	get highlighter(): Highlighter {
		return this._highlighter;
	}

	private initRenderer(): void {
		this._renderer = new THREE.WebGLRenderer({
			antialias: false,
			alpha: true,
			powerPreference: 'high-performance'
		});

		opts.pixelRatio = opts.pixelRatio > window.devicePixelRatio? opts.pixelRatio : window.devicePixelRatio;
		this._renderer.setPixelRatio(opts.pixelRatio);

		this._renderer.setSize(stage3D.width, stage3D.height);
		this._renderer.setClearColor(0x000000, 0);

    this._renderer.outputEncoding = THREE.sRGBEncoding;
    this._renderer.toneMapping = THREE.LinearToneMapping;
    this._renderer.gammaFactor = 1.5;
    this._renderer.toneMappingExposure = 1.0;

		const domElement = this._renderer.domElement;
		stage3D.container.appendChild(domElement);
	}

	private initScene(): void {
		this._scene = new THREE.Scene();
	}

	private initCamera(): void {
		const ratio = stage3D.width / stage3D.height;
		this._camera = new THREE.PerspectiveCamera(45, ratio, opts.zNear, opts.zFar);
		this._camera.position.set(0, 2.0, 5.0);
		this._scene.add(this._camera);
	}

	private initComposer(): void {
		this._composer = new Composer(this._renderer, this._scene, this._camera);
	}

	private initCameraControls(): void {
		this._cameraControls = new CameraControls(this._scene, this._camera, stage3D.container);
	}

	private onResize(): void {
		this.update();
	}

	public update(): void {
		this._camera.aspect = stage3D.width / stage3D.height;
		this._camera.updateProjectionMatrix();

		this._renderer.setSize(stage3D.width, stage3D.height);
		this._cameraControls.fitScene();

		this._composer.update();
	}

	public setRender(value: boolean): void {
		this.stopRender = !value;
		this.render();
	}

	public destruct(): void {
		this._scene = null;
		this._camera = null;
		this._cameraControls.dispose();
		this._renderer.dispose();
		stage3D.removeResizeListener();
	}

	public render(): void {
		if(this.stopRender) return;

		this._cameraControls.update();

		if (opts.postprocessing) this._composer.render();
		else this._renderer.render(this._scene, this._camera);

		requestAnimationFrame(() => {
			this.render();
		});
	}
}

export const engine = new Engine();
