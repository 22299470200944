import { Component, ViewChild, ElementRef, Output, EventEmitter, Renderer2, OnInit, ViewChildren, QueryList } from '@angular/core';
// import { TweenLite, Power3 } from 'gsap/all';
import { gsap } from 'gsap';

import { ConnectorComponent } from './connector/connector.component';
import { configurator2d } from './connector/c2d/configurator';
import { touchController } from './touchController';
import cssVars from 'css-vars-ponyfill';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-chooser',
  template: `
    <article class="page" #page>
      <section class="header">
        <div class="arrow" (click)="hide()">&#xe907;</div>
        <a>
          <div class="logo"></div>
        </a>
        <span class="title">Get started by choosing your base configuration</span>
      </section>
      <section class="main">
          <div class="content" #content></div>
      </section>
    </article>
    <app-connector (leaveConfigurator)="leaveConfigurator($event)" (actionType)="contactUs($event)" (showConnector)="show()"></app-connector>
    <app-alert></app-alert>
    <app-alert (actionType)="leave()"></app-alert>
  `,
  styles: [`
    .set {
      background-color: #fff;
      margin: 0 30px;
      height: 370px;
      width: 400px;
      position: relative;
    }
    .content {
      height: 100%;
      width: 100%;
      max-width: 1500px;
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: flex-start;
      flex-basis: 0%;
      overflow: hidden;  /* это трюк для Edge */
    }
    .header {
      background-color: #272727;
      position: relative;
      width: 100%;
      height: 26%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .title {
      color: white;
      font-size: 20px;
      font-weight: 300;
    }
    .main {
      flex-shrink: 1;
      flex-direction: column;
      width: 100%;
      height: 70%;
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
    }
    .page {
      left: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      z-index: 10;
    }
    .logo {
      width: 230px;
      height: 85px;
      background-image: url("assets/img/ui/hyve-logo-small.png");
      background-size: contain;
      background-position: center
    }
    .arrow {
      position: absolute;
      width: 50px;
      height: 50px;
      color: white;
      line-height: 50px;
      font-family: "Icons";
      font-size: 17px;
      left: 1px;
      top: 3px;
      border-radius: 4px;
      cursor: pointer;
      z-index: 50;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }
    .arrow:hover { font-size: 23px; transform: scale(1.05);}
    .set {
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }
    .set:hover {transform: scale(1.05);}
  `]
})
export class ChooserComponent implements OnInit {
  @ViewChild('page') page: ElementRef;
  @ViewChild('content') content: ElementRef;
  @Output() showChooser = new EventEmitter<void>();
  @ViewChild(ConnectorComponent) connector;
  @ViewChildren(AlertComponent) alerts: QueryList<AlertComponent>;

  // currentDevice: string = device();

  constructor(private renderer: Renderer2) { cssVars(); }

  ngOnInit() {

    window.addEventListener('JsonLoaded', () => {
      configurator2d.setConfigurationIcons(this.content.nativeElement, this.connector.show);
    });
    touchController(this.content.nativeElement);
  }

  public show(): void {
    // this.page.nativeElement.style.left = 0;
    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0 });
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: 0,
      duration: 0.5
    });

  }

  public hide(): void {

    this.showChooser.emit();
    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '100%' });
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: '100%',
      duration: 0.5
    });

  }

  public showConnector(): void {

    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '-50%' });
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: '-50%',
      duration: 0.5
    });

  }

  contactUs(event: boolean): void {
    const alert = this.alerts.toArray()[0];
    if (event) {
      alert.message.title = 'Contact Us';
      alert.message.text = "Thank you for your enquiry. We will be in touch shortly.";
      alert.message.button = 'Continue';
      alert.show();
    } else {
      alert.message.title = 'Sorry';
      alert.message.text = "We encountered an issue.";
      alert.message.button = 'Continue';
      alert.show();
    }

  }

  leaveConfigurator(event: any): void {
    const alert = this.alerts.toArray()[1];
    alert.message.title = 'Confirm Exit';
    alert.message.text = "Are you sure you wish to exit the configurator? Any changes will be lost.";
    alert.message.button = 'Continue';
    alert.show();
  }

  leave(): void {
    this.connector.hide();
  }


}
