import {geometryCache, IGeometries} from './geometryCache';
import {errMsg} from '../utils/utils';
import {ArrayOfUniqueItems} from "../utils/ArrayOfUniqueItems";
import {loader} from "../loader/loader"

class GeometryCollection {

	private bundleUrls: ArrayOfUniqueItems<string> = new ArrayOfUniqueItems();
	private count: number = 0;

	public addBundleUrl(url: string): void {
// console.log(url);
		this.bundleUrls.addItem(url);
	}

	public resetCount(): void {

		this.count = 0;
	}

	public loadGeometries(): Promise<any[] | void> {
		const promises = [];
		for(const url of this.bundleUrls) {
			promises.push(geometryCache.load(url).then(() => {
				this.count++;
				const event = new CustomEvent('geometryload', {
					detail: {
						url: url,
						progress: this.count / this.bundleUrls.length
					}
				});
				loader.eventTarget.dispatchEvent(event);
			}));
		}
		return Promise.all(promises);
	}

	public getGeometries(url: string): IGeometries {

		const geometries = geometryCache.getGeometries(url);
		if(!geometries) throw errMsg('Geometries not exists', `There are not the geometries (${url}) in the geometry collection`);
		return geometries;
	}

	public removeBundle(url: string): void {

		this.bundleUrls.removeItem(url);
		geometryCache.removeGeometries(url);
	}

	public clean(): void {

		this.bundleUrls.length = 0;
		geometryCache.clean();
	}
}

export const geometryCollection = new GeometryCollection();