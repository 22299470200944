export interface IVector {
	x: number,
	y: number
}

export function calcVectorLength(vector: IVector): number {
	return Math.sqrt(
		Math.pow(vector.x, 2) +
		Math.pow(vector.y, 2));
}

export function normalizeVector(vector: IVector): IVector {
	const length = calcVectorLength(vector);
	return { x: vector.x / length, y: vector.y / length };
}

export function sumVectors(vA: IVector, vB: IVector): IVector {
	return { x: Math.round((vA.x + vB.x)*100)/100, y: Math.round((vA.y + vB.y)*100)/100 };
}

export function subVectors(vA: IVector, vB: IVector): IVector {
	return { x: Math.round((vA.x - vB.x)*100)/100, y: Math.round((vA.y - vB.y)*100)/100 };
}

export function calcUnitVector(rad: number): IVector {
	return { x: Math.cos(rad), y: Math.sin(rad) };
}