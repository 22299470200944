import * as THREE from 'three';
import {stage3D} from './stage3D';
import {info} from "../info";
import {Mesh} from "../nodeTree/objects/Mesh";
import {engine} from "./engine";
import {PartNode} from "../nodeTree/PartNode";
import {ignore} from "selenium-webdriver/testing";

export class Highlighter {

	private raycaster: THREE.Raycaster = new THREE.Raycaster();
	private prevMesh: Mesh;
	private _selectedMesh: Mesh;
	private originalMaterial: THREE.Material;
	private outlineMesh: THREE.Mesh;
	private outlineMaterial: THREE.ShaderMaterial;

	constructor() {
		this.createOutlineMaterial();
		this.onHighlight = this.onHighlight.bind(this);
		this.onOutline = this.onOutline.bind(this);
		stage3D.container.addEventListener('pointermove', this.onHighlight, false);
		stage3D.container.addEventListener('pointerdown', this.onOutline, false);
	}

	private createOutlineMaterial(): void {
		const uniforms = { "offset": { type: "f", value: 0.022 } };

		const outlineMaterial = new THREE.ShaderMaterial({
			uniforms: uniforms,
			vertexShader: outlineShader.vertex_shader,
			fragmentShader: outlineShader.fragment_shader
		});
		outlineMaterial.depthWrite = false;
		outlineMaterial.depthTest = true;
		this.outlineMaterial = outlineMaterial;
	}

	get selectedMesh(): Mesh {
		return this._selectedMesh;
	}

	private onHighlight(event: MouseEvent): void {
		const mesh = this.isOverMesh(event);
		if(mesh === this.prevMesh) return;
		this.offHighlight();
		this.prevMesh = mesh;
		if(mesh) {
			stage3D.container.style.cursor = 'pointer';
			this.originalMaterial = <THREE.MeshStandardMaterial>mesh.material;
// console.log(mesh.name);
// const partNode = info.getPartNodeInfoByMeshUID(mesh.uid).partNode;
// console.log(partNode.part.name + ' : ' + mesh.name + ' : ' + mesh.uid + ' : ' + partNode.uid);
// console.log(partNode.part.styles);
			const highlightedMaterial = (<THREE.MeshStandardMaterial>mesh.material).clone();
			(<THREE.MeshStandardMaterial>highlightedMaterial).color.set(0xee1c25);
			(<THREE.MeshStandardMaterial>highlightedMaterial).map = null;
			highlightedMaterial.needsUpdate = true;

			mesh.material = highlightedMaterial;
			mesh.material.needsUpdate = true;
		}
	}

	private offHighlight(): void {
		if(!this.prevMesh) return;
		stage3D.container.style.cursor = 'default';

		this.prevMesh.material = this.originalMaterial;
		this.prevMesh = null;
	}

	private isOverMesh(event: MouseEvent): Mesh {
		const meshes = [];
		engine.scene.traverseVisible(object => { // /Bench Slats/ig.test(object.name) /*
      // if(object instanceof THREE.Mesh) console.log(object.name);
      if(object instanceof THREE.Mesh && /Bench Slats/ig.test(object.name)) meshes.push(object);
			// if(object instanceof THREE.Mesh && object.name !== 'shadow' && object.name !== '' && object.name !== 'Footprint') meshes.push(object);
		});
		return checkIntersection({ x: event.clientX, y: event.clientY }, meshes, engine.camera, this.raycaster);
	}

	private onOutline(event: MouseEvent): void {
		this.offOutline();
		const mesh = this.isOverMesh(event);
		// this._selectedMesh = mesh;
		if(mesh) {
			const partNode = info.getPartNodeInfoByMeshUID(mesh.uid).partNode;
			// if(mesh.name === 'Arm') { this.hideArmrest(partNode); return; }
			const partType = info.getPartType(mesh.name);
      if(partType === 'ignorable' || partType === 'panels' || partType === 'legs' || /\bT\d/g.test(mesh.name)) return;
      const partNodeInfo = info.getPartNodeInfo(partNode.uid);
			this.emit(partNodeInfo.parent.uid, partNodeInfo.parent.partGroup.name);
			/*const outlineMesh = new THREE.Mesh(partNode.model.mesh.geometry, this.outlineMaterial);
			outlineMesh.position.copy(getGlobalPosition(partNode.model.mesh));
			outlineMesh.rotation.copy(getGlobalRotation(partNode.model.mesh));
			outlineMesh.scale.copy(partNode.model.mesh.scale);
			this.outlineMesh = outlineMesh;
			engine.scene.add(outlineMesh);*/
		} else this.offOutline();
	}

  private emit(uid: string, name: string): void {
    const event = new CustomEvent('meshselected', {
      detail: { uid, name }
    });
    window.dispatchEvent(event);
  }

  private offOutline(): void {
		/* engine.scene.remove(this.outlineMesh);
		this.outlineMesh = null; */
	}

	private hideArmrest(partNode: PartNode): void {
		partNode.visible = false;
		this._selectedMesh = null;
	}
}

function checkIntersection(position: any, meshes: any[], camera: THREE.Camera, raycaster: THREE.Raycaster): Mesh {
	let object = null;

	raycaster.setFromCamera(getRelativePosition(), camera);
	const intersects = raycaster.intersectObjects(meshes);

	if(intersects.length > 0) {
		object = intersects[0].object;
	}
	return object;

	function getRelativePosition() {
		position.x = ( (position.x - stage3D.container.offsetLeft) / stage3D.width) * 2 - 1;
		position.y = -( (position.y - stage3D.container.offsetTop) / stage3D.height) * 2 + 1;
		return position;
	}
}

function getGlobalPosition(object): THREE.Vector3 {
	getRootParent(object).updateMatrixWorld();
	return object.getWorldPosition(new THREE.Vector3());
}

function getGlobalRotation(object): THREE.Euler {
	getRootParent(object).updateMatrixWorld();
	return new THREE.Euler().setFromQuaternion ( object.getWorldQuaternion(new THREE.Quaternion()), 'XYZ', true );
}

function getRootParent(object): THREE.Object3D {
	if(object.parent === null) return object;
	return getRootParent(object.parent);
}

const outlineShader = {
	vertex_shader: [
		"uniform float offset;",
		"void main() {",
		"vec4 pos = modelViewMatrix * vec4( position + normal / length(normal) * offset, 1.0 );",
		"gl_Position = projectionMatrix * pos;",
		"}"
	].join("\n"),

	fragment_shader: [
		"void main(){",
		"gl_FragColor = vec4( 1.0, 0.0, 0.0, 0.5 );",
		"}"
	].join("\n")
};
