import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
// import { TweenLite, Power3 } from 'gsap/all';
import { gsap } from 'gsap';
import { InformationComponent } from './information/information.component';
import { ChooserComponent } from '../chooser/chooser.component';
import { LoginComponent } from './login/login.component';
import { GreetingComponent } from './greeting/greeting.component';
import { TutorialComponent } from '../chooser/tutorial/tutorial.component';
import { AlertComponent } from '../alert/alert.component';
import { configuration } from '../chooser/connector/view3d/configuration.data';
import { PRICE_DATE } from '../../appConfig';
import {environment} from "../../environments/environment";


@Component({
  selector: 'app-home',
  template: `
    <app-home-information (showHome)="show()"></app-home-information>
    <div class="page" #page>
      <div class="app-version">v{{version}}</div>
        <article class="page-content">
          <div class="content-container">
            <a class="logo"></a>
            <div class="greeting-container">
              <app-home-greeting (startBuilding)="showChooser()" (showAbout)="showAbout()"></app-home-greeting>
            </div>
          </div>

          <!-- <nav class="aside"> -->
            <!-- <ul> -->
              <!--li class="spot" (click)=showLogin()>&#xe906;</li-->
              <!-- <li class="spot" (click)=contact.show()>&#xe904;</li> -->
              <!-- <li class="spot" (click)=showInfo()>&#xe905;</li> -->
            <!-- </ul> -->
          <!-- </nav> -->
        </article>
      </div>
    <!-- <div class="footer" (click)=showLogin()></div> -->


    <!-- <div class="mobile-responsive"> -->
    <div [ngClass]="{'hide-mobile-responsive' : hideAlertMessage}"class="mobile-responsive">
      <div class="mobile-box">
        <p>Please view the configurator at full screen on desktop</p>
      </div>
    </div>


    <!-- <app-tutorial></app-tutorial>
    <app-home-about #about></app-home-about> -->
    <app-home-login #login (respond)="showMember($event)"></app-home-login>
    <app-chooser (showChooser)="show()"></app-chooser>
  `,
  styles: [`
    .page {
      height: 100%;
    }
    .mobile-responsive {
      display: none;
    }
    .page-bg, .page-content {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .page-content {
      background-image: url("assets/img/ui/grillex-configurator-bg.jpg");
      background-size: cover;
    }
    .app-version {
      position: absolute;
      bottom:10px;
      right:10px;
      z-index: 1;
      color: white;
      font-size: 12px;
    }
    .logo {
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      width: 280px;
      height: 161.5px;
      position: absolute;
      background-image: url("assets/img/ui/hyve-logo.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .content-container {
      position: relative;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 390px;
    }
    .greeting-container {
      position: absolute;
      left: 50%;
      bottom: 15%;
      transform: translateX(-50%);
    }
    .member {
      left: 36px;
      top: 95px;
      position: absolute;
      font-size: 1rem;
      display: none;
      color: var(--dark-grey);
    }
    .spot {
      font-family: "Poppins";
      font-size: 28px;
      text-align: center;
      line-height: 54px;
      width: 69px;
      height: 54px;
      margin-bottom: 12px;
      background-color: var(--dark-grey);
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }
    .spot:hover { background-color: var(--dark-grey-hover); transform: scale(1.05);}
    nav {
      position: absolute;
    }
    nav.aside {
      right: 0;
      top: 68px;
    }
    ul { list-style: none; overflow: hidden; }
    li {
      font-size: 1.2rem;
      cursor: pointer;
      outline: none;
    }
    .footer {
      width: 100%;
      height: 6%;
      padding: 4px 15px;
      border-radius: 4px;
      background: #5e5e5e;
      bottom: 30px;
      color: var(--dark-grey);
      opacity: 0;
      font-size: 1.1rem;
    }
    .hide-mobile-responsive {
      display: none !important;
    }
    @media(max-width: 1000px) {
      .mobile-responsive {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1000000;
        background: rgba(0,0,0,0.5);
      }
      .mobile-box {
        height: 100px;
        width: 250px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -65%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: #dadada;
        border-radius: 7px;
      }
      .mobile-box p {
        font-size: 12px;
        text-align: center;
        line-height: 18px;
        width: 90%;
        color: black;
      }
      .left-half {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 100%;
      }
    }
  `]
})
export class HomeComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('member') member: ElementRef;
  @ViewChild(GreetingComponent) greeting;
  @ViewChild(LoginComponent) login;
  @ViewChild(AlertComponent) alert;
  @ViewChild(InformationComponent) info;
  @ViewChild(ChooserComponent) chooser;
  @ViewChild(TutorialComponent) tutorial;

  memberName = '';
  priceDate = '';
  version = environment.version;
  hideAlertMessage = false;

  ngOnInit() {

    window.addEventListener('beforeunload', (event) => {

      const message = 'Are you sure you want to exit?';

      if (typeof event === undefined) {
        event = window.event;
      }
      if (event) {
        event.returnValue = message;
      }
      return message;
    }, false );

    this.greeting.show();

    const salesData = {
      name: getCookie('name'),
      phone: getCookie('phone')
    };

    // console.log(`name: ${salesData.name}  phone: ${salesData.phone}`);

    this.showMember(salesData);
  }

  showLogin(): void {

    this.login.show();

  }

  showInfo(): void {

    window.open('https://streetfurniture.com/download/build-aria-desktop-brochure/');
    // this.info.show();
    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '-50%' });
  }

  showChooser(): void {
    this.hideAlertMessage = true;
    // console.log(this.hideAlertMessage);
    this.chooser.show();
    // this.page.nativeElement.style.left = '-50%';
    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '-50%' });
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: '-50%',
      duration: 0.5
    });

  }

  showAbout(): void {
  }

  showMember(event): void {
return;
    if (!event.name || !event.phone) { return; }

    this.member.nativeElement.style.display = 'block';
    this.memberName = event.name;
    this.priceDate = PRICE_DATE;

    setCookie('name', event.name);
    setCookie('phone', event.phone);
  }

  show(): void {

    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0 });
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: 0,
      duration: 0.5
    });
  }
}

function setCookie(name, value) {

  document.cookie = `${name}=${encodeURIComponent(value)}; ; path=/`;
}

function getCookie(name) {

  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {

    const pattern = new RegExp(`^${name}=`);

    if (cookie.search(pattern) !== -1) {
      return decodeURIComponent(cookie.replace(pattern, ''));
    }
  }

  return null;
}
