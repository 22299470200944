import {Part} from './Part';
import {errMsg} from '../utils/utils';
import {info} from "../info";
import {Connector} from "./Connector";

export class PartGroup {

	private _id: string;
	private _name: string;
	private _slug: string;
	private _parts: Part[] = [];
	private _connectorsData: any;
  private _partCode: string;
  private _priceClasses: number[];

	constructor(data: any, sprite: any) {
		this._id = sprite['_id'];
		this._name = sprite['name'];
		this._slug = sprite['slug'];
		this._connectorsData = sprite['connectors'];
    this._partCode = sprite['partCode'];
    this._priceClasses = sprite['priceClasses'];
		// this._width = data['width']; // лишнее. Нужно только для 2D
		// this._height = data['height'];

		if(this._id === undefined || this._name === undefined) throw errMsg('PartGroup ID/Name', '_id and name values are mandatory!');

		info.partGroupExists(this._id);
		this.buildParts(data, sprite['arModels']);
	}

	get id(): string {
		return this._id;
	}
	get name(): string {
		return this._name;
	}
  get slug(): string {
    return this._slug;
  }
	get parts(): Part[] {
		return this._parts;
	}
	get connectorsData(): any {
		return this._connectorsData;
	}
  get priceClasses(): number[] {
    return this._priceClasses;
  }
  get partCode(): string {
    return this._partCode;
  }

	private buildParts(data: any, arModelsData: any): void {
		for(const arModelData of arModelsData) {
			const part = new Part(data, arModelData);
			this._parts.push(part);
		}
	}

	public destruct(): void {
		for(let i=this._parts.length-1; i>=0; i--) {
			this._parts[i].destruct();
		}
		const partGroupInfo = info.getPartGroupInfo(this._id);
		partGroupInfo.parent.partGroups.splice(partGroupInfo.parent.partGroups.indexOf(partGroupInfo.partGroup), 1);
	}
}
