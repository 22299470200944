import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { APP_ID } from '../appConfig';
import {environment} from "../environments/environment";

@Injectable()
export class HttpService {

  constructor(private http: HttpClient) {}

  public post(request, data: any) {

    const url = environment.apiEndpoint + request + APP_ID;

   /* const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };*/
    return this.http.post(url, data); // JSON.stringify(data), httpOptions);
  }

  public get(id: string) {

    return this.http.get(`${environment.apiEndpoint}load-configuration/${id}`);
    /*, { params: new HttpParams().set('configuration', id) });*/

  }
}
