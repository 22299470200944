import * as THREE from 'three';
import {Material} from "../materials/Material";
import {generateID} from "../../utils/utils";
import {engine} from "../../engine/engine";

export class Mesh extends THREE.Mesh{

	private _uid: string;

	constructor(geometry: THREE.BufferGeometry, material: Material) {
		super(geometry, material);
		this._uid = generateID();
	}

	get uid(): string {
		return this._uid;
	}

	get boundingBox(): THREE.Box3 {
		if (!this.geometry.boundingBox) this.geometry.computeBoundingBox();
		const boundingBox = this.geometry.boundingBox.clone();
		boundingBox.applyMatrix4(this.matrixWorld);

		// const helper = new THREE.Box3Helper( boundingBox, new THREE.Color( 0x000000 ) );  // for testing
		// engine.scene.add( helper );

		return boundingBox;
	}
}

