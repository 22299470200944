import * as THREE from 'three';
import {mapCache} from './mapCache';
import {errMsg} from '../utils/utils';
import {ArrayOfUniqueItems} from '../utils/ArrayOfUniqueItems';
import {info} from '../info';
import {loader} from "../loader/loader";

interface ICategory {
	urls: string[],
	amount: number,
	count: number
}
interface ICategories {
	[idx: string]: ICategory // we use style names, 'base' and 'shadow' as indices
}

class MapCollection {

	private categories: ICategories = {};

	public addMapUrls(category: string, mapUrlList: string[]): void {

		if(!this.categories[category]) this.categories[category] = { urls: [], amount: 0, count: 0 };

		for(const url of mapUrlList) {
			if(this.categories[category].urls.indexOf(url) === -1) this.addMapUrl(category, url);
		}
	}

	private addMapUrl(category: string, url: string): void {

		this.categories[category].urls.push(url);
		this.categories[category].amount++;
	}

	public resetCount(): void {
		for(const category in this.categories) {
			if(!this.categories.hasOwnProperty(category)) continue;
			this.categories[category].count = 0;
		}
	}

	public loadMaps(categories: string[]): Promise<any[]> {

		const promises = [];

		for(const category of categories) {
			const nestedPromises = [];
			for (const url of this.categories[category].urls) {
				nestedPromises.push(mapCache.loadMap(url).then(()=> {

					this.categories[category].count++;
					this.emit(category, url, this.categories[category].count / this.categories[category].amount);
				}));
			}
			promises.push(Promise.all(nestedPromises));
			if(this.categories[category].urls.length === 0) this.emit(category, null, 1);
		}
		return Promise.all(promises);
	}

	private emit(category: string, url: string, progress: number): void {
		const event = new CustomEvent('mapload', {
			detail: { category, url, progress }
		});
		loader.eventTarget.dispatchEvent(event);
	}

	public loadBaseMaps(): Promise<any[] | void> {
		return this.loadMaps(['base']);
	}

	public loadShadowMaps(): Promise<any[] | void> {
		if(!this.categories['shadow']) return;
		return this.loadMaps(['shadow']);
	}

	public loadStyleMaps(): Promise<any[] | void> {
		const categories = this.getStyleNameList();
		return this.loadMaps(categories);
	}

	private getStyleNameList(): ArrayOfUniqueItems<string> {

		const categories = new ArrayOfUniqueItems<string>(...Object.keys(this.categories));

		categories.removeItem('base');
		categories.removeItem('shadow');

		return categories;
	}

	public getCategoryList(): ArrayOfUniqueItems<string> {
// console.log(this.categories);
		return new ArrayOfUniqueItems<string>(...Object.keys(this.categories));
	}

	public getMap(url): THREE.Texture {
		// console.log('Map - ' + url);
		const map = url !== null ? mapCache.getMap(url).map : null; // т.к. в json-е прописано null вместо пути, если текстура отсутствует

		if(map === undefined) throw errMsg('Map not exists', `There is no map (${url}) in the map collection`);
		return map;
	}

	public clean(): void {

		const mapUrlList = this.getMapUrlList();

		this.removeMaps(mapUrlList);

		// mapCache.clean();
	}

	private getMapUrlList(): ArrayOfUniqueItems<string> {

		const mapUrlList: ArrayOfUniqueItems<string> = new ArrayOfUniqueItems();

		for(const category in this.categories) {
			if(!this.categories.hasOwnProperty(category)) continue;
			for(const mapUrl of this.categories[category].urls) {
				mapUrlList.addItem(mapUrl);
			}
		}
		return mapUrlList;
	}

	public update(): void {

		this.removeUnusedMaps();
	}

	private removeUnusedMaps(): void {
// console.log(this.categories);
		const mapUrlListA = info.getCurrentMapUrlList();
// console.log(mapUrlListA);
		const mapUrlListB = this.getMapUrlList();
// console.log(mapUrlListB);
		const unusedMapUrlList = getListIntersection(mapUrlListA, mapUrlListB);
// console.log(unusedMapUrlList);
		this.removeMaps(unusedMapUrlList);
// console.log(this.categories);
	}

	private removeMaps(mapUrlList: ArrayOfUniqueItems<string>): void {

		for(const category in this.categories) {
			if(!this.categories.hasOwnProperty(category)) continue;
			// for(const mapUrl of this.categories[category])
			for(let i=this.categories[category].urls.length-1; i>=0; i--) {
				const mapUrl = this.categories[category].urls[i];
// console.log(category + ' - ' + mapUrl);
				if(mapUrlList.exists(mapUrl)) this.removeMap(category, mapUrl);
			}
			if(this.categories[category].urls.length === 0) {
				this.categories[category] = null;
				delete this.categories[category];
			}
		}
// console.log(this.categories);
	}

	private removeMap(category: string, url: string): void {

		if(!this.categories[category] || this.categories[category].urls.indexOf(url) === -1) return;
		const idx = this.categories[category].urls.indexOf(url);
		this.categories[category].urls.splice(idx, 1);
		this.categories[category].amount--;
		mapCache.removeMap(url);
	}
}

export const mapCollection = new MapCollection();

function getListIntersection(listA: ArrayOfUniqueItems<string>, listB: ArrayOfUniqueItems<string>): ArrayOfUniqueItems<string> {

	const listIntersection: ArrayOfUniqueItems<string> = new ArrayOfUniqueItems();
	for(const item of listB) {
		if(!listA.exists(item)) listIntersection.addItem(item);
	}
	return listIntersection;
}
