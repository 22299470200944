import * as THREE from 'three';
import {PartNode} from './PartNode';
import {PartGroup} from '../dataTree/PartGroup';
import {info} from "../info";
import {engine} from "../engine/engine";
import {generateID} from "../utils/utils";
import {Connector} from "../dataTree/Connector";
import {Label} from '../dataTree/Label';

export class PartGroupNode {
	private _partNodes: PartNode[] = [];
	private _groupNode: THREE.Group;
	private _uid: string;
	private _connectors: Connector[] = [];
  private _labels: Label[] = [];

	constructor(private _partGroup: PartGroup) {
		this.addPartNode();
		this._uid = generateID();
	}

	private addPartNode(): void {
		for(const part of this._partGroup.parts) {
			const partNode = new PartNode(part);
			this._partNodes.push(partNode);
		}
	}

	get uid(): string {
		return this._uid;
	}
	get id(): string {
		return this._partGroup.id;
	}
	get partGroup(): PartGroup {
		return this._partGroup;
	}
	get partNodes(): PartNode[] {
		return this._partNodes;
	}
	get groupNode(): THREE.Group {
		return this._groupNode;
	}
	get connectors(): Connector[] {
		return this._connectors;
	}
  get labels(): Label[] {
	  return this._labels;
  }
	public addToScene(): void {
		this._groupNode = new THREE.Group();
		this.addConnectors();
		engine.scene.add(this._groupNode);
		for(const partNode of this._partNodes) {
			partNode.addToScene(this._groupNode);
		}
	}

  get armsCode(): string {
    if(!/Backrest/gi.test(this._partGroup.name) || /Stool/gi.test(this._partGroup.name)) return null;
    let code: string;
    const arms: PartNode[] = [];
    for(const partNode of this._partNodes) {
      if(partNode.part.name === 'Arm') arms.push(partNode);
    }
    if(arms[0].visible && arms[1].visible) code = '004';
    else if(arms[0].visible) code = '002';
    else if(arms[1].visible) code = '003';
    else code = '001';
    return code;
  }

  public setArmsVisibility(armsVisibility): void {
    const arms: PartNode[] = [];
    for(const partNode of this._partNodes) {
      if(partNode.part.name === 'Arm') arms.push(partNode);
    }
    for(let i=0; i<arms.length && i<armsVisibility.length; i++) {
      arms[i].visible = armsVisibility[i];
    }
  }

  public createLabels(): void {
    const codes = info.generatePartGroupNodeCodes(this);
    const darkGrey = 'rgba(70, 70, 70, 0.9)';
    const grey = 'rgba(125, 125, 125, 0.9)';
    const top = 0.45;
    const origin = new THREE.Vector3(0, top, 0);
    const indent = 0.06;
    const armIndent = { x: 0, z: -0.35 };
    const legIndent = { x: 0, z: 0.35 };
    const partIndent = { x: 0, z: 0 }; /* /Seat/ig.test(this.partGroup.name) /*&&
      !/Backrest/ig.test(this.partGroup.name) &&
      !/600mm/ig.test(this.partGroup.name) ? { x: 0.0, z: 0.25 } : { x: 0, z: 0 };*/

    if(/Seat/ig.test(this.partGroup.name) && this._groupNode.rotation.y % Math.PI === 0) partIndent.z = -0.15;

    if(this.partGroup.name === 'Coffee Table') { partIndent.z = 0.0; origin.y = 0.55; }

    if(codes.codeB) this.addLabel(darkGrey, origin.clone(), -indent, partIndent).text = codes.codeB;
    if(this.partGroup.name !== 'Footprint') this.addLabel(darkGrey, origin.clone(), 0, partIndent).text = codes.codeA;
    if(codes.connections) this.addLabel(grey, origin.clone(), indent, partIndent).text = codes.connections;

    for(const partNode of this._partNodes) {
      if(partNode.part.name === 'Arm' && partNode.visible && !/Backrest/ig.test(this.partGroup.name)) {
        const position = partNode.model.mesh.position.clone();
        position.y = top;
        this.addLabel(darkGrey, position.clone(), -indent * 0.5, armIndent).text = 'HYV-ARM-001';
        this.addLabel(grey, position.clone(), indent * 0.5, armIndent).text = 'Connection B';
      }
      if(partNode.part.name === 'Leg') {
        const legCodes = this.generateLegCodes(partNode);
        const position = partNode.model.mesh.position.clone();
        position.y = top;
        this.addLabel(darkGrey, position.clone(), -indent * 0.5, legIndent).text = legCodes.code;
        this.addLabel(grey, position.clone(), indent * 0.5, legIndent).text = legCodes.connections;
      }
    }
  }

  private generateLegCodes(partNode: PartNode): any {
    const codes = {
      code: 'HYV-SLEG-002',
      connections: 'Connections D2 & G'
    };
    for(const connector of this._connectors) {
      if(connector.intersectedPieces.length !== 1) continue;
      if(connector.intersectedPieces[0]['Leg'] && connector.intersectedPieces[0]['Leg'].partNode === partNode) {
        codes.code = 'HYV-SLEG-001';
        codes.connections = 'Connections D1 & G';
      }
    }
    return codes;
  }

  private addLabel(color: string, origin: THREE.Vector3, labelIndent: number, partIndent: any): Label {
    let x = partIndent.x;
    let z = partIndent.z + labelIndent;
    const angle = -this._groupNode.rotation.y;
    if(angle !== 0) {
      x = Math.sin(angle) * labelIndent + partIndent.x;
      z = Math.cos(angle) * labelIndent + partIndent.z;
    }
    const label = new Label({
      origin: origin.add(new THREE.Vector3(x, 0, z)),
      width: 582,
      height: 64,
      radius: 30,
      fontSize: 46,
      fontFace: 'sans-serif',
      bgColor: color,
      textColor: '#ffffff',
      scaleFactor: 0.058
    });
    this._labels.push(label);
    this._groupNode.add(label.mesh);
    return label;
  }

  public removeLabels(): void {
    for(const label of this._labels) {
      label.destruct();
    }
    this._labels.length = 0;
  }

  public showLabels(): void {
    for(const label of this._labels) {
      label.show();
    }
  }

  public hideLabels(): void {
    for(const label of this._labels) {
      label.hide();
    }
  }

  private addConnectors(): void {
		for(const cData of this._partGroup.connectorsData) {
			this._connectors.push(new Connector(cData));
		}
	}

	public translate(deltaVector: THREE.Vector3): void {
		this._groupNode.position.add(deltaVector);
		this._connectors.forEach(connector => { connector.update(this); });
	}

	public rotate(deltaAngle: number): void {
		this._groupNode.rotation.y += deltaAngle;
		this._connectors.forEach(connector => { connector.update(this); })
	}

  public scale(value: THREE.Vector3): void {
    this._groupNode.scale.set(value.x, value.y, value.z);
    this._connectors.forEach(connector => { connector.update(this); })
  }

  public updateMaterials(styleName: string): void {
		for(const partNode of this._partNodes) {
			if(partNode.getStyleByName(styleName)) partNode.updateMaterial(styleName);
		}
	}

	public destruct(): void {
		for(let i=this._partNodes.length-1; i>=0; i--) {
			this._partNodes[i].destruct();
		}
		const partGroupNodeInfo = info.getPartGroupNodeInfo(this._uid);
		partGroupNodeInfo.parent.partGroupNodes.splice(partGroupNodeInfo.parent.partGroupNodes.indexOf(partGroupNodeInfo.partGroupNode), 1);
    this._groupNode.parent.remove(this._groupNode);
	}
}
