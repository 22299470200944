import * as THREE from 'three';
import {saveAs} from 'file-saver';
import {formatDateTime, b64toBlob} from '../utils/utils';
import {opts} from  '../options';
import {engine} from './engine';

export class Stage3D {

	private _width: number;
	private _height: number;
	private _container: HTMLElement;

	private resize: any; // callback function

	constructor() {

		this.onResize = this.onResize.bind(this);
		window.addEventListener('resize', this.onResize);
	}

	public init(): void {

		this._container = document.getElementById(opts.containerId);
		this.updateSize();
	}

	get container(): HTMLElement {

		return this._container;
	}

	get height(): number {

		return this._height;
	}

	get width(): number {

		return this._width;
	}

	public addResizeListener(callback: any): void {

		this.resize = callback;
	}

	public removeResizeListener(): void {

		window.removeEventListener('resize', this.onResize);
	}

	public updateSize(): void {

		this._width = this._container.offsetWidth;
		this._height = this._container.offsetHeight;
	}

	public shoot(): void {

	  const pixelRatio = engine.renderer.getPixelRatio();
    engine.renderer.setPixelRatio(pixelRatio * 2);
    engine.composer.setSize(stage3D.width * pixelRatio * 2, stage3D.height * pixelRatio * 2);
    engine.composer.render();

		const imgSrc = engine.renderer.domElement.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, '');
    engine.renderer.setPixelRatio(pixelRatio);
    engine.composer.setSize(stage3D.width * pixelRatio, stage3D.height * pixelRatio);
		const blob = new Blob([b64toBlob(imgSrc,'image/png')], {type: 'image/png'});
		const now = new Date();

		saveAs(blob, `Grillex ${formatDateTime(now)}.png`);
	}

  public takeTopImage(): any {
    // const zoom = engine.cameraControls.cameraZoom;
    const cameraPosition = engine.cameraControls.cameraPosition;
    // const cameraFov = engine.cameraControls.cameraFov;
	  this.setViewport(new THREE.Vector3(0, 1, 0));

    // engine.cameraControls.cameraFov = 25;

    const pixelRatio = engine.renderer.getPixelRatio();
    engine.renderer.setPixelRatio(pixelRatio * 2);
    engine.composer.setSize(stage3D.width * pixelRatio * 2, stage3D.height * pixelRatio * 2);
    engine.render();
    engine.composer.render();
    // throw new Error("Test");

    const imgSrc = engine.renderer.domElement.toDataURL('image/png');
    engine.renderer.setPixelRatio(pixelRatio);
    engine.composer.setSize(stage3D.width * pixelRatio, stage3D.height * pixelRatio);
    // engine.cameraControls.cameraZoom = zoom;
    // engine.cameraControls.cameraFov = cameraFov;
    engine.cameraControls.cameraPosition = cameraPosition;

/*
    const imgSrc2 = imgSrc.replace(/^data:image\/(png|jpg);base64,/, '');
    engine.renderer.setPixelRatio(pixelRatio);
    const blob = new Blob([b64toBlob(imgSrc2,'image/png')], {type: 'image/png'});
    const now = new Date();
    saveAs(blob, `Datasheet ${formatDateTime(now)}.png`);
*/

    return imgSrc;
  }

  public takeImage(): any {
    const pixelRatio = engine.renderer.getPixelRatio();
    engine.renderer.setPixelRatio(pixelRatio * 2);
    engine.composer.setSize(stage3D.width * pixelRatio * 2, stage3D.height * pixelRatio * 2);
    engine.render();
    engine.composer.render();

    const imgSrc = engine.renderer.domElement.toDataURL('image/png');
    engine.renderer.setPixelRatio(pixelRatio);
    engine.composer.setSize(stage3D.width * pixelRatio, stage3D.height * pixelRatio);

    return imgSrc;
  }

  public toggleShadow(): boolean {

		let visible = false;
		engine.scene.children.filter(obj => {

			if(obj.name === 'shadow') {
				obj.visible = !obj.visible;
				visible = obj.visible;
			}
		});
		return visible;
	}

	private onResize(): void {

	  if(!this._container) return;
		this.updateSize();
		this.resize();
	}

	public setViewport(viewDirection: THREE.Vector3): void {

		engine.cameraControls.fitScene();
		engine.cameraControls.lookFrom(viewDirection);
	}

	public setViewportWithAnimation(viewDirection: THREE.Vector3): void {

		engine.cameraControls.lookFromWithAnimation(viewDirection);
	}
}

export const stage3D = new Stage3D();
