import { Component, ViewChild, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { NgForm} from '@angular/forms';
// import { TweenLite, Power1, Power3 } from 'gsap/all';
import { gsap } from 'gsap';
import { HttpService } from '../../http.service';
import { AlertComponent } from '../../alert/alert.component';

@Component({
  selector: 'app-home-login',
  template: `
    <div class="shadow" #shadow></div>
    <article class="page" #page>
      <div class="box">
        <!--div class="logo"></div-->
        <div class="title">Staff Login</div>
        <div class="close" (click)=hide()>&#x2715;</div>
        <form #form="ngForm" novalidate>
          <input type="text" name="email" placeholder="Username" [value]="email" ngModel required>
          <input type="password" name="password" placeholder="Password" [value]="password" ngModel required>
          <button (click)="submit(form)" [disabled]="form.invalid">Login</button>
        </form>
      </div>
    </article>
    <app-alert></app-alert>
  `,
  styles: [`
    .page {
      background-color: transparent;
      top: 100%;
      z-index: 10;
    }
    .box {
      position: absolute;
      width: 280px;
      height: 270px;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px 80px;
      text-align: center;
      background-color: #fff;
      border-radius: 1%;
      box-shadow: 0 0 7px rgba(168,160,150,0.5);
    }
    .shadow {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      display: none;
      z-index: 10;
    }
    .close {
      position: absolute;
      right: 13px;
      top: 13px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Poppins";
      font-size: 12px;
      border-radius: 100%;
      color: var(--dark-charcoal);
      cursor: pointer;
      opacity: 0.3;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }
    .close:hover { background-color: #febb07; color: #fff; transform: scale(1.05);}
    button {
      background-color: var(--logo-red);
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
      border: none;
      color: white;
      padding: 10px 60px;
      margin-top: 15px;
      font-family: "Poppins";
      text-decoration: none;
      font-size: 0.9rem;
      border-radius: 30px;
      font-weight: 300;
      line-height: normal;
      cursor: pointer;
      width: 100%;
    }
    button,
    button:active,
    button:focus {
      outline: none;
    }
    button:hover { background-color: var(--logo-red-hover); transform: scale(1.05);}
    .title {
      font-size: 1.6rem;
      font-weight: 500;
      color: var(--dark-charcoal);
      margin-bottom: 25px;
      margin-top: 30px;
    }
    .logo {
      width: 210px;
      height: 62px;
      background-image: url("assets/img/ui/logo-dark.png");
      background-size: contain;
    }
    /* button:disabled { background-color: #FF8684; } */
    input[type="text"], input[type="password"] {
      background-color: transparent;
      height: 35px;
      width: 100%;
      margin-top: 15px;
      padding: 0 10px;
      font-family: "Poppins";
      font-size: 0.9rem;
      line-height: normal;
      border: solid 1.5px #dadada;
      border-radius: 2px;
      text-shadow: none;
      display: block;
      box-sizing: border-box;
    }
    input::placeholder {
      color: black;
      opacity: 0.3;
    }
  `],
   providers: [HttpService]
})

export class LoginComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('shadow') shadow: ElementRef;
  @ViewChild(AlertComponent) alert;
  @Output() respond = new EventEmitter<any>();

  email: string;
  password: string;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.page.nativeElement.addEventListener('keydown', (event) => { event.stopPropagation(); }, true);
  }

  public show(): void {

    this.shadow.nativeElement.style.display = 'block';
    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, top: 0 });
    // TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: .2});
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      top: 0,
      duration: 0.5
    });
    gsap.to(this.shadow.nativeElement, {
      ease: 'power1.out',
      opacity: 0.2,
      duration: 0.5
    });
  }

  hide(): void {

    /*TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power1.easeOut,
      top: '100%'
    });
    TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: 0, onComplete: () => {
      this.shadow.nativeElement.style.display = 'none';
    }});*/
    const that = this;
    gsap.to(this.page.nativeElement, {
      ease: 'power1.out',
      top: '100%',
      duration: 0.5
    });
    gsap.to(this.shadow.nativeElement, {
      ease: 'power1.out',
      opacity: 0,
      duration: 0.5,
      onComplete() { that.shadow.nativeElement.style.display = 'none'; }
    });
  }

  submit(form: NgForm) {

    console.log(form.value);
    this.httpService.post('login', form.value)
      .subscribe( (data) => {
        this.hide();
        this.respond.emit(data);
      }, error => {
        this.showAlert();
        console.error(error);
      });
  }

  showAlert(): void {

    this.alert.message = {
      title: 'Error',
      text: 'Username / password incorrect'
    };
    this.alert.show();
  }
}
