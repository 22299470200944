import * as THREE from 'three';
import {MaterialSet} from '../../dataTree/MaterialSet';
import {mapCollection} from '../../maps/mapCollection';
import {IMaterialProperties, defaultMaterialParametrs} from '../../options';
import {environmentMap} from '../../engine/environmentMap';

export class Material extends THREE.MeshStandardMaterial{

	constructor() {

		super();
	}

	public update(set: MaterialSet): void {

		const parameters: IMaterialProperties = {...defaultMaterialParametrs, ...chargeMaps(set.items)};
		parameters.envMap = environmentMap.map;
		this.setValues(parameters);
		this.needsUpdate = true;
	}
}

function chargeMaps(set: any): IMaterialProperties {

	const parameters: IMaterialProperties = {};

	for(const key in set) {

		if(!set.hasOwnProperty(key)) continue;
		const isMap = (/(^(map|aoMap|metalnessMap|roughnessMap|normalMap|emissiveMap)$)/ig).test(key);
		parameters[key] = isMap ? mapCollection.getMap(set[key]) : set[key];
	}
	return parameters;
}
