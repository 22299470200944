import {MaterialSet} from './MaterialSet';
import {errMsg} from '../utils/utils';
import {info} from "../info";
import {mapCollection} from "../maps/mapCollection";

export class Style {

	private _id: string;
	private _name: string;
	private _slug: string;
	private _imageUrl: string;
	private _imageColor: string;
	private _materialSet: MaterialSet;

	constructor(private data: any) {

		this._id = data['_id'];
		this._name = data['name'];
		this._slug = data['slug'];
		if(this._id === undefined || this._name === undefined) throw errMsg('Style ID/Name', '_id and name values are mandatory!');
		this._imageUrl = data['image'];
		this._imageColor = data['color'];
		// info.styleExists(this._id);
		this._materialSet = new MaterialSet(data);
		// mapCollection.addMapUrls(this._name, this._materialSet.imageUrls);
	}

	get id(): string {
		return this._id;
	}

	set id(value: string) {
		this._id = value;
	}

	get name(): string {
		return this._name;
	}

  get slug(): string {
    return this._slug;
  }

  get imageColor(): string {
		return this._imageColor;
	}

	get imageUrl(): string {
		return this._imageUrl;
	}

	get materialSet(): MaterialSet {
		return this._materialSet;
	}

	get json(): any {
		return this.data;
	}

	public clone(): Style {
		return new Style(this.data);
	}

	public destruct(): void {

		if(this._materialSet) this._materialSet.destruct();
		this._materialSet = null;

		const styleInfo = info.getStyleInfo(this._id);
		// console.log(this._id);
		styleInfo.parent.styles.splice(styleInfo.parent.styles.indexOf(styleInfo.style), 1);
	}
}
