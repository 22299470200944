export function getAngle(el: HTMLElement): number {
	const cssText = el.style.cssText;
	const result = cssText.match(/transform:.+rotate\(([\d.]+)deg\)/);
	let angle = result && result[1] ? +result[1] : 0;
	angle = angle < 0 ? angle + 360 : angle;
	angle = angle > 360 ? angle - 360 : angle;
	return angle;
}

export function getScale(el: HTMLElement): number {
	const cssText = el.style.cssText;
	const result = cssText.match(/transform:.+scale\(([\d.]+)\)/);
	return result && result[1] ? +result[1] : 1;
}
