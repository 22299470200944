import * as THREE from 'three';
import {opts} from '../options';
import {CPromise} from '../utils/CPromise';
import {engine} from './engine';

class EnvironmentMap {

	private _map: THREE.Texture;

	get map(): THREE.Texture {
		return this._map;
	}

	public loadMap(): Promise<void> {

		const promise = new CPromise();

		const pmremGenerator = new THREE.PMREMGenerator( engine.renderer );
		pmremGenerator.compileEquirectangularShader();

		const that = this;
		new THREE.CubeTextureLoader().setPath(opts.maps)
			.load(['px.png','nx.png','py.png','ny.png','pz.png','nz.png'],
				(map)=>{
					// texture.encoding = THREE.LinearEncoding;
					that._map = pmremGenerator.fromEquirectangular(map).texture;
					map.dispose();
					pmremGenerator.dispose();

					promise.result.resolve();
				},
				undefined, () => { console.warn('CubeTexture problem'); promise.result.reject(); });

		return promise.handle;
	}
}
export const environmentMap = new EnvironmentMap();
