import {errMsg} from './utils/utils';
import {Unit} from './Unit';

class LayersControl {
	private layers: any;
	private order: string[];

	constructor() {
		this.init();
	}

	get units(): any {
		return this.layers;
	}

	private init(): void {
		this.layers = {};
		this.order = [];
	}

	public addLayer(unit: Unit, id: string): void {
		if(this.layers[id]) throw errMsg('ID is not unique', 'id should be unique!');
		this.layers[id] = unit;
		this.order.push(id);
	}

	public removeLayer(id: string): void {
		const idx = this.order.indexOf(id);
		if(idx === -1) return;
		delete this.layers[id];
		this.order.splice(idx, 1);
	}

	public toFront(id: string): void {

		if(this.order.indexOf(id) !== -1) this.order.splice(this.order.indexOf(id),1);
		this.order.push(id);

		let bZIndex = 0;
		let cZIndex = 100;
		for(const idx of this.order) {
			this.layers[idx].order = this.layers[idx].alwaysOnTop ? cZIndex++ : bZIndex++;
      if(this.layers[idx].alwaysBack ) this.layers[idx].order = -1;
    }
	}

	public clean(): void {
		this.init();
	}
}
export const layersControl = new LayersControl();
