import {Product} from '../dataTree/Product';
import {PartGroupNode} from './PartGroupNode';
import {mapCollection} from '../maps/mapCollection';
import {geometryCollection} from '../geometries/geometryCollection';
import {info} from "../info";
import {PartGroup} from "../dataTree/PartGroup";

export class ProductNode {

	private _partGroupNodes: PartGroupNode[] = [];

	constructor(private _product: Product) {
		info.setProductNode(this);
	}

	get product(): Product {
		return this._product;
	}

	get partGroupNodes(): PartGroupNode[] {
		return this._partGroupNodes;
	}

	private addPartGroupNodes(): void {
		for(const partGroup of this._product.partGroups) {
			const partGroupNode = new PartGroupNode(partGroup);
			this._partGroupNodes.push(partGroupNode);
		}
	}

	public addPartGroupNode(partGroup: PartGroup): PartGroupNode {
		const partGroupNode = new PartGroupNode(partGroup);
		this._partGroupNodes.push(partGroupNode);
		return partGroupNode;
	}

	public addToScene(): void {
		for(const partGroupNode of this._partGroupNodes) {
			partGroupNode.addToScene();
		}
	}

	public updateMaterials(styleName?: string): void {
		// console.log(styleName);

		for(const partGroupNode of this._partGroupNodes) {
			partGroupNode.updateMaterials(styleName);
		}
	}

	public destruct(): void {
		for(let i=this._partGroupNodes.length-1; i>=0; i--) {
			this._partGroupNodes[i].destruct();
		}
		mapCollection.clean();
		geometryCollection.clean();
	}
}