import { Component, ViewChild, ViewChildren, ElementRef, QueryList, AfterViewInit, ApplicationRef } from '@angular/core';
// import { TweenLite, Power1, Power3 } from 'gsap/all';
import { gsap } from 'gsap';
import * as Swiper from '../../../../node_modules/swiper/dist/js/swiper.min.js'

@Component({
  selector: 'app-bullet-tutorial',
  templateUrl: './bullet-tutorial.component.html',
  styleUrls: ['./bullet-tutorial.component.css']
})
export class BulletTutorialComponent implements AfterViewInit {
  @ViewChildren('box') boxes: QueryList<ElementRef>;
  @ViewChild('container') container: ElementRef;
  @ViewChild('panel') panel: ElementRef;
  @ViewChild('shadow') shadow: ElementRef;
  @ViewChild('swiperContainer') swiperContainer: ElementRef;

  swiper: any;

  tutorials: any[] = [
    [
      {
      title: "Creating a Configuration",
      text: [
        'Drag and drop pieces to create your configuration.',
        'Tap the arrow keys on the keyboard to rotate parts.',
        'Use the grey dots to clip your pieces together.',
        'Note that parts do not have to be clipped together and can be placed in separate groups.',
        'Use the “3D View” to view your configuration in 3D.',
        'You can switch between the 2D editor and 3D view as often as you like to refine your layout.'
      ]
      },
      {
        title: "Removing Parts",
        text: [
          'Remove pieces by dragging them into the “Bin” icon.'
        ]
      },
      {
        title: "Changing Colours & Materials",
        text: [
          'Materials can be applied in the 3D view but will not display in the 2D Editor.'
        ]
      },
      {
        title: "Adding Armrests",
        text: [
          'Armrests can only be applied and viewed in the 3D view by clicking on the seat.'
        ]
      },
      {
        title: "Adding Coffee Tables",
        text: [
          'Add a coffee table by dragging it to a seat with a grey dot.',
          'Coffee tables can only be placed on a seat without a backrest.'
        ]
      },
      {
        title: "Set Footprint",
        text: [
          'Use the “Set Footprint” icon to add a slab size in metres under the work area. This can be used as a reference to help visualize the size of your layout. Note: Setting the values to 0x0 will remove the footprint.'
        ]
      },
      {
        title: "Have a Question?",
        text: [
          'For further information on the Hyve range contact our team on 1300 552 102.'
        ]
      }
    ],
    [
      {
        title: "How to Use the 3D View",
        text: [
          'Hold down the left mouse button to rotate the view.',
          'Use the mouse scroll wheel to zoom in and out.',
        ]
      },
      {
        title: "Changing Colours & Materials",
        subtitle: "Furniture Colour",
        text: [
          'Use the “Primary Colour” icon to change the colour of the Legs, Backrest Brackets, Connector Cover Panels, Armrests and Coffee Tables.',
          'Use the “Secondary Colour” icon to change the colour of the Slat End Panels and Connector Body.',
          'Use the “Slat Colour” icon to change the colour of both the Seat slats and Backrest Slats.'
        ],
        subtitle_2: "BBQ Colour",
        text_2: [
          'Use the “BBQ Colour” icon to change the colour of the BBQ cabinet.'
        ]
      },
      {
        title: "Adding Armrests",
        text: [
          'Armrests can be added by clicking on a Seat or Backrest and then selecting an option from the menu.',
        ]
      },
      {
        title: "Saving a Photo and Layout",
        text: [
          'To save an image of your layout select the “Camera” icon and follow the prompts.',
          'To save your layout select the “Save Layout” icon and follow the prompts.',
          'To receive a quote on your current layout design select the “Request a Quote” icon.'
        ]
      },
      {
        title: "Points to Remember",
        text: [
          'Armrests can only be applied and viewed in the 3D view.',
          'Colours applied in the 3D view do not display in the 2D editor.',
          'Parts cannot be individually coloured.'
        ]
      },
      {
        title: "Have a Question?",
        text: [
          'For further information on the Hyve range contact our team on 1300 552 102.'
        ]
      },
    ],
    [
      {
        title: "Adding Armrests",
        text: [
          'Armrests can be added by clicking on a Seat or Backrest and then selecting an option from the menu.',
        ]
      }
    ]
  ];

  tips: any[];

  prevSlideNum = 0;

  ngAfterViewInit() {

    window.addEventListener('resize', () => { this.positionPanel(); });

    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      pagination: { el: '.swiper-pagination', clickable: true },
      mousewheel: true,
      direction: 'horizontal',
      observer: true,
      observeParents: true,
    });

    this.swiper.on('transitionEnd', () => {
      if (this.swiper.isEnd && this.prevSlideNum === this.tips.length - 1) { this.last(); }
      this.prevSlideNum = this.swiper.activeIndex;
    });
    this.container.nativeElement.style.display = 'none';

  }

  constructor(private appRef: ApplicationRef) {/* tick */ }

  public positionPanel() {
    const boxes = this.boxes.toArray();

    const offset = 0;

    const containerHalfHeight = this.container.nativeElement.offsetHeight / 2;
    let boxHalfHeight = 0;
    boxes.forEach(box => {
      if (boxHalfHeight < box.nativeElement.offsetHeight / 2)
      {
        boxHalfHeight = box.nativeElement.offsetHeight / 2;
        console.log(boxHalfHeight);
      }
    });

    this.panel.nativeElement.style.top = containerHalfHeight + boxHalfHeight + offset + 'px';
  }

  public next(event): void {

    if (this.swiper.isEnd) {
      this.last(event);
      return;
    }
    this.swiper.slideNext();
  }

  public last(event?) {

    if (event !== undefined) {
        event.stopPropagation();
      }


    const that = this;

    /*TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: 0,onComplete() {
      that.shadow.nativeElement.style.display = 'none';
    }});

    TweenLite.to(that.container.nativeElement, 0.5, {ease: Power1.easeOut, opacity: 0, scale: 0, onComplete(){
      that.container.nativeElement.style.display = 'none';
    }});*/
    gsap.to(that.shadow.nativeElement, {
      ease: 'power1.out',
      opacity: 0,
      duration: 0.5,
      onComplete() { that.shadow.nativeElement.style.display = 'none'; }
    });
    gsap.to(that.container.nativeElement, {
      ease: 'power1.out',
      opacity: 0,
      scale: 0,
      duration: 0.5,
      onComplete() { that.container.nativeElement.style.display = 'none'; }
    });

  }

  public run(num): void {

    this.tips = this.tutorials[num];

    // Running arm tutorial, hide swipe pagination
    if (num === 2) {
      this.panel.nativeElement.style.display = 'none';
    } else {
      this.panel.nativeElement.style.display = 'block';
    }

    this.appRef.tick();

    this.swiper.update();

    const that = this;

    that.shadow.nativeElement.style.display = 'block';
    this.container.nativeElement.style.display = 'block';

    /*TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: .2});

    TweenLite.to(this.container.nativeElement, 0.5, {ease: Power1.easeOut, top:0, scale: 1, opacity: 1});*/

    gsap.to(that.shadow.nativeElement, {
      ease: 'power1.out',
      opacity: 0.2,
      duration: 0.5
    });
    gsap.to(that.container.nativeElement, {
      ease: 'power1.out',
      top: 0,
      scale: 1,
      opacity: 1,
      duration: 0.5
    });

    this.positionPanel();
  }

}
