import {PartGroup} from './PartGroup';
import {loadJSON} from '../utils/utils';
import {info} from "../info";

export class Product {

	private _id: string;

	private _partGroups: PartGroup[] = [];

	public load(url: string): Promise<void> {

		return loadJSON(url).then( (data: JSON) => {
			this._id = data['appId'];
			info.setProduct(this);
			this.buildPartGroups(data, data['sprites']);
		});
	}

	get partGroups(): PartGroup[] {

		return this._partGroups;
	}

	private buildPartGroups(data: any, spritesData: any): void {

		for(const spriteData of spritesData) {
			const partGroup = new PartGroup(data, spriteData);
			this._partGroups.push(partGroup);
		}
	}

	public destruct(): void {

		for(let i=this._partGroups.length-1; i>=0; i--) {
			this._partGroups[i].destruct();
		}
	}
}