import { Component, ViewChild, ElementRef, Output, EventEmitter, OnInit, QueryList, Renderer2, ApplicationRef } from '@angular/core';
// import { TweenLite, Power3 } from 'gsap/all';
import { gsap } from 'gsap';
import { configurator2d } from './c2d/configurator';
import { View3dComponent } from './view3d/view3d.component';
import { TutorialComponent } from '../tutorial/tutorial.component';
import { BulletTutorialComponent } from '../bullet-tutorial/bullet-tutorial.component';
import { touchController } from '../touchController';
import cssVars from 'css-vars-ponyfill';
import {ActivatedRoute} from '@angular/router';
import { HttpService } from '../../http.service';
import domtoimage from 'dom-to-image';
import { datasheetdata } from './view3d/configuration.data';
import { configurator3d } from './view3d/c3d/src/configurator';
import { FootprintComponent } from './footprint/footprint.component';
import {environment} from "../../../environments/environment";

enum Subtitle {
  Size,
  Codes,
  Quote
}

@Component({
  selector: 'app-connector',
  template: `
    <article class="page" #page id="scene2d">

      <div class="scene2d">
        <div class="container" #container></div>
      </div>
      <section id="asideBg" class="aside" #aside>
        <div class="arrow" (click)=showAlert()>
          &#xe907;
          <span>Exit Configurator</span>
        </div>
        <div class="wrapper">
            <a>
              <div class="logo"></div>
            </a>

            <div class="slim-scrollbar">
              <div class="parts-container">
                <div class="parts" #partsList></div>
              </div>
            </div>
        </div>
        <div class="aside-box">
          <ul>
            <li class="aside-button aside-ask" (click)="runBulletTutorial()">Tutorial</li>
          </ul>
        </div>
      </section>

      <div id="configurator">
        <span class="codes footprint-btn" (click)="showFootprint()">Set Footprint</span>
        <span class="codes" #codes>Grid: 1m x 1m</span>
      </div>

      <div class="button" #button (click)=show3d()>
          <span>3D View</span>
      </div>

      <div #bin id="bin" class="bin"></div>
    </article>
    <app-tutorial></app-tutorial>
    <app-bullet-tutorial></app-bullet-tutorial>
    <app-view3d (showConnector)="slide()"></app-view3d>
    <app-footprint></app-footprint>
  `,
  styleUrls: ['./connector.component.scss'],
  providers: [HttpService]
})

export class ConnectorComponent implements OnInit {
  @Output() actionType = new EventEmitter<any>();
  @Output() leaveConfigurator = new EventEmitter<any>();
  @ViewChild('page') page: ElementRef;
  // @ViewChild('main') main: ElementRef;
  @ViewChild('aside') aside: ElementRef;
  @ViewChild('header') header: ElementRef;
  @ViewChild('button') button: ElementRef;
  @ViewChild('codes') codes: ElementRef;
  @ViewChild('partsList') partsList: ElementRef;
  @ViewChild('container') container: ElementRef;
  @ViewChild('asideContact') asideContact: ElementRef;
  @ViewChild('scene2d') scene2d: ElementRef;
  @ViewChild('bin') bin: ElementRef;
  @ViewChild(View3dComponent) view3d;
  @ViewChild(TutorialComponent) tutorial;
  @ViewChild(BulletTutorialComponent) bulletTutorial;
  @ViewChild(FootprintComponent) footprint;

  @Output() showConnector = new EventEmitter<void>();

  mode: number = Subtitle.Size;

  constructor(private renderer: Renderer2, private appRef: ApplicationRef, private route: ActivatedRoute, private httpService: HttpService) {
    cssVars();
    this.show = this.show.bind(this);
  }

  async ngOnInit() {
    // const url = 'https://api.inhaabit.com/v2/configurator/2d/6052c2ba256daa0297177656';

    this.container.nativeElement.addEventListener('StartDrag', () => { this.showBin(); } );

    this.container.nativeElement.addEventListener('EndDrag', () => {
      this.setSize();
      this.hideBin();
    } );

    await configurator2d.run(this.container.nativeElement, environment.configuratorUrl);
    configurator2d.setFurnitureIcons(this.partsList.nativeElement);

    this.route.queryParams.subscribe(
      (queryParam: any) => {
        if(!queryParam['configuration']) {
          return;
        }
        this.httpService.get(queryParam['configuration'])
          .subscribe((response: any) => {
            const configuration = JSON.parse(response['configuration']);
            configurator3d.savedStyles = configuration.styles;
            configurator2d.showConfiguration(configuration);
            if(configuration.arms) configurator2d.armsVisibility = configuration.arms;
            this.primeShow();
            this.setSize();
          }, error => {
            console.error(error);
          });
      }
    );
  }

  public show(): void {
    this.slide();
  }

  public slide(): void {
    this.aside.nativeElement.style.visibility = 'hidden';
    this.aside.nativeElement.style.left = '-400px';
    /*TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0, onComplete: () => {
      // this.toggleAside(true);
      this.tutorial.run(0);
      this.openAside();
    }});*/
    const that = this;
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: 0,
      duration: 0.5,
      onComplete() {
        that.tutorial.run(0);
        that.openAside();
      }
    });
  }

  public runBulletTutorial(): void {
    // this.aside.nativeElement.style.visibility = 'hidden';
    this.bulletTutorial.run(0);

  }

  public primeShow(): void {
    this.aside.nativeElement.style.left = '-400px';
    this.aside.nativeElement.style.visibility = 'hidden';
    this.page.nativeElement.style.left = '0px';
    this.tutorial.run(0);
    this.openAside();
  }

  show3d(): void {
    // this.toPng();
    // this.view3d.dimension = configurator2d.getSizeString();
    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '-50%' });
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: '-50%',
      duration: 0.5
    });
    this.view3d.sequence = configurator2d.getCurrentConfiguration();
    this.view3d.show();
  }

  showAlert(): void {
    this.leaveConfigurator.emit();
    configurator2d.footprint = null;
  }

  hide(): void {
    this.showConnector.emit();
    /*TweenLite.to( this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      left: '100%',
      onComplete: () => { this.aside.nativeElement.style.visibility = 'hidden';}
    });*/
    const that = this;
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      left: '100%',
      duration: 0.5,
      onComplete() { that.aside.nativeElement.style.visibility = 'hidden'; }
    });
  }

  openAside(): void {
    this.setSize();
    // const strOpen = 'drawer-open';
    // const strClose = 'drawer-close';

    const asideEl = this.aside.nativeElement;
    asideEl.style.left = '-400px';
    asideEl.style.visibility = 'visible';

    // TweenLite.to(asideEl, 0.4, { ease: Power3.easeOut, left: '0px', delay: 0.5 });
    gsap.to(asideEl, {
      ease: 'power3.out',
      left: 0,
      duration: 0.4,
      delay: 0.5
    });
  }

  private setSize(): void {

    // this.codes.nativeElement.textContent = configurator2d.getSizeString();
  }

  private getSubtitle(mode, chain): string {

    let sub: string;

    switch (mode) {
      case Subtitle.Size:
        sub = chain.getRealSize().text;
        break;
      case Subtitle.Codes:
        sub = chain.getCodes();
        break;
      case Subtitle.Quote:
        sub = 'nothing';
        break;
    }
    return sub;
  }

  public toPng() {
    const sheet = this.container.nativeElement.cloneNode(true);
    document.body.appendChild(sheet);
    sheet.style.left = '0px';
    sheet.style.top = '0px';
    const sheetRect = sheet.getBoundingClientRect();
    return domtoimage.toPng(sheet, { style: { opacity: 1 }, cacheBust: true, width: sheetRect.width, height: sheetRect.height })
      .then( (imgSrc) => {
        datasheetdata.image1 = imgSrc;
        sheet.remove();
      })
      .catch( (error) => {
        console.error('oops, something went wrong!', error);
      });
  }

  private hideBin(): void {
    this.bin.nativeElement.style.opacity = '0';
  }

  private showBin(): void {
    this.bin.nativeElement.style.opacity = '1';
  }

  showFootprint(): void {
    this.footprint.show();
  }
}
