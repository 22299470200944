import {mapCollection} from "../maps/mapCollection";
import {geometryCollection} from "../geometries/geometryCollection";
import {info} from "../info";

interface IProgressList {
	[idx: string]: number
}

class Loader {

	private progressList: IProgressList = {};
	private _eventTarget: EventTarget;

	constructor() {
		this._eventTarget = new EventTarget();
		this._eventTarget.addEventListener('mapload', (event: CustomEvent) => { this.emit('map', event.detail);});
		this._eventTarget.addEventListener('geometryload', (event: CustomEvent) => {  this.emit('geometry', event.detail); });
	}

	get eventTarget(): EventTarget {
		return this._eventTarget;
	}

	public load(): Promise<any> {
// console.log('LOAD');
		this.prepareProgressList();

		mapCollection.resetCount();
		geometryCollection.resetCount();

		const promises = [];

		promises.push(mapCollection.loadBaseMaps());
		promises.push(mapCollection.loadShadowMaps());
		promises.push(mapCollection.loadStyleMaps());
		promises.push(geometryCollection.loadGeometries());

		return Promise.all(promises);
	}

	public init(): void { this.prepareProgressList(); }

	private prepareProgressList(): void {
		this.progressList = {};
		const loadingList = info.getLoadingList();
// console.log(loadingList);
		for(const category of loadingList) {
			this.progressList[category] = 0;
		}
	}

	private emit(type: string, data: any): void {
		const category = type === 'geometry'? type : data.category;
		this.progressList[category] = data.progress;

		const detail: any = {
			totalProgress: this.calcTotalProgress()
		};

		if(category !== 'geometry' && category !== 'base' && category !== 'shadow') {
			detail['styleName'] = category;
			detail['localProgress'] = data.progress;
		}

		const event = new CustomEvent('rcload', { detail });
		window.dispatchEvent(event);
	}

	private calcTotalProgress(): number {
		const categories = Object.keys(this.progressList);
		let totalProgress = 0;

		for(const category of categories) {
			totalProgress += this.progressList[category];
			// console.log(category + ' * ' + this.progressList[category]);
		}

		return totalProgress / categories.length;
	}
}

export const loader = new Loader();