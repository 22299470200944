import { Component, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
// import { TweenLite, Power1, Power3 } from 'gsap/all';
import { gsap } from 'gsap';

@Component({
  selector: 'app-alert',
  template: `
    <article class="page" #page>
      <div class="box">
        <section class="message">
          <h1 class="title">{{message.title}}</h1>
          <p class="text" [innerHTML]="message.text"></p>
          <div *ngIf="message.button" class="button" (click)="action()">{{message.button}}</div>
        </section>
        <div class="close" (click)=hide() [hidden]="!showCloseButton">&#x2715;</div>
      </div>
    </article>
  `,
  styles: [`
    [hidden] {
      display:none !important;
    }
    .message {
      color: var(--dark-charcoal);
      text-align: center;
    }
    .title {
      font-size: 1.6rem;
      font-weight: 400;
    }
    .text {
      margin-top: 15px;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 300;
    }
    .page {
      background-color: rgba(0, 0, 0, 0.4);
      display: none;
      z-index: 10;
      opacity: 0.5;
    }
    .box {
      position: absolute;
      width: 388px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      top: 50%;
      left: 50%;
      padding: 30px;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 3px;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Poppins";
      font-size: 10px;
      border-radius: 100%;
      color: var(--dark-charcoal);
      cursor: pointer;
      box-sizing: border-box;
      opacity: 0.7;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
    }
    .close span {
      background-color: cornflowerblue;
    }
    .close:hover { background-color: #febb07; color: #fff; transform: scale(1.05);}
    .button {
      background-color: #fdcd03;
      transition: all .2s cubic-bezier(0, 1.04, 0.58, 1);
      border: none;
      color: black;
      padding: 10px 60px;
      font-family: "Poppins";
      text-decoration: none;
      font-size: 0.9rem;
      border-radius: 30px;
      font-weight: 300;
      line-height: normal;
      cursor: pointer;
    }
    .button,
    .button:active,
    .button:focus {
      outline: none;
    }
    .button:hover { background-color: #febb07; transform: scale(1.05);}
  `]
})

export class AlertComponent {

  public showCloseButton = true;
  
  @ViewChild('page') page: ElementRef;
  @Output() actionType = new EventEmitter<void>();

  message: any = { title: '', text: '', button: '' };

  public show(showCloseButton = true): void {
    this.showCloseButton = showCloseButton;
    this.page.nativeElement.style.display = 'block';
    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, opacity: 1 });
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      opacity: 1,
      duration: 0.5
    });
  }

  hide(): void {

    /*TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power1.easeOut,
      opacity: 0,
      onComplete: () => { this.page.nativeElement.style.display = 'none'; }
    });*/
    const that = this;
    gsap.to(this.page.nativeElement, {
      ease: 'power1.out',
      opacity: 0,
      duration: 0.5,
      onComplete() {
        that.page.nativeElement.style.display = 'none';
      }
    });
  }

  action() {

    this.hide();
    this.actionType.emit();
  }
}
