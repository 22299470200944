import * as THREE from 'three';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {SSAARenderPass} from 'three/examples/jsm/postprocessing/SSAARenderPass';
import {stage3D} from './stage3D';
import {opts} from '../options';

export class Composer extends EffectComposer {

	private ssaaRenderPass: SSAARenderPass;

	constructor(renderer: THREE.WebGLRenderer, private scene: THREE.Scene, private camera: THREE.Camera) {

		super(renderer);

		this.ssaaRenderPass = new SSAARenderPass(this.scene, this.camera, 0xFFFFFF, 0);
		this.addPass(this.ssaaRenderPass);
		this.ssaaRenderPass.renderToScreen = true;
		this.update();
	}

	public update(): void {

		this.setSize(stage3D.width * opts.pixelRatio, stage3D.height * opts.pixelRatio);

		if(window.devicePixelRatio > 1.5) {
			this.ssaaRenderPass.sampleLevel = 1;
		} else {
			this.ssaaRenderPass.sampleLevel = 2;
		}
		if(opts.maxAALevel !== null) {
			this.ssaaRenderPass.sampleLevel = Math.min(this.ssaaRenderPass.sampleLevel, opts.maxAALevel);
		}
	}
}