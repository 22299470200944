import * as THREE from 'three';

export interface IMaterialProperties {
	color?: string, // '#ffffff'
	map?: THREE.Texture, // null
	aoMap?: THREE.Texture, // null
	aoMapIntensity?: number, // 1
	metalnessMap?: THREE.Texture, // null
	metalness?: number, // 0, 0-1
	roughnessMap?: THREE.Texture, // null
	roughness?: number, // 1, 0-1
	normalMap?: THREE.Texture, // null
	normalScale?: THREE.Vector2; // (1,1)
	envMap?: THREE.Texture, // null
	envMapIntensity?: number,
	emissiveMap?: THREE.Texture, // null
	emissive?: string, // '#000000'
	emissiveIntensity?: number, // 1
	alphaTest?: number, // 0
	depthTest?: boolean, // true
	depthWrite?: boolean, // true
	transparent?: boolean, // false
	opacity?: number, // 1, 0-1
	flatShading?: boolean, // false
	wireframe?: boolean, // false
	side?: number, // THREE.FrontSide
	needsUpdate?: boolean, // false
}

export const defaultMaterialParametrs: IMaterialProperties = {
	color: '#ffffff',
	map: null,
	aoMap: null,
	aoMapIntensity: 1,
	metalnessMap: null,
	metalness: 0,
	roughnessMap: null,
	roughness: 1,
	normalMap: null,
	normalScale: new THREE.Vector2(1,1),
	envMap: null,
	envMapIntensity: 1,
	emissiveMap: null,
	emissive: '#000000',
	emissiveIntensity: 1,
	alphaTest: 0,
	depthTest: true,
	depthWrite: true,
	transparent: false,
	opacity: 1,
	side: THREE.FrontSide,
	flatShading: false
};
/*
export interface IDistance {
	frame: number,
	fullscreen: number
}
*/
export interface IEndpoints {
	webProduct: string,
	stats: string
}
/**
 * Creates a new IHViewer object, removing all previous viewers
 *
 * @param {string|null} element - if set, will show on top of that particular element.
 * @param {object} options - Object containing the following:
 *
 * GENERAL
 * {boolean} rotate(=true):              If true, will rotate automatically
 * {boolean} noFullScreen(=false):       If true, will prevent full screen
 * {boolean} show360Icon(=true):         If true, shows a 360 button in the element to enable the viewer
 * {boolean} hideUntilLoaded(=false):    If true, hides the viewer until it's loaded.  If false, shows a loading screen
 * {boolean} lightbox(=false):           If true, the viewer will initially be hidden (even if hideUntilLoaded==true), and then show in a lightbox (instead of on top of the element)
 * {boolean} preload(=true):             If true, starts loading the DOM + model files immediately.  When false, user must click the button to start the viewer
 * {boolean} showOnElementClick(=false): If true, clicking on the element will show the viewer
 * {string|array} hideOnClick:           List of element class names which will close viewer when clicked
 * {string} url360Icon(=""):             Set this to a custom image to replace the default 360 image
 * {boolean} lightroom(=false)           If true, shows lightroom buttons to take photos when in fullscreen mode
 * {boolean} featureBtn(=false)          If true, shows feature button on the bottom of IHViewer
 * {number} maxAALevel(=null)            If set, forces the SSAA level to always be less than this. (0 => 1 sample, 1 => 2 samples, 2 => 4 samples ... 5 => 32 samples)
 *
 * EVENTS
 * {function} onShow(element):      Function to run after the viewer is shown.  'element' is the 3D viewer container.
 * {function} onHide(element):     Run before the viewer is hidden.  'element' is the 3D viewer container.
 * {function} onLoad(element):      Run the first time the viewer container is loaded in
 *
 * CAMERA
 * {number} maxDistance.frame:       Maximum zoom out (when in frame)
 * {number} maxDistance.fullscreen:  Maximum zoom out (when in fullscreen)
 * {number} minDistance.frame:       Maximum zoom in (when in frame)
 * {number} minDistance.fullscreen:  Maximum zoom in (when in fullscreen)
 * {number} cameraHeight:            Height of camera (to adjust perspective, eg. 1 = 1m off ground)
 * {boolean} restrictAngle(=true):   If true, disallows moving the angle underneath the object
 *
 * MATERIALS
 * {object} solid:                              solid material options
 * {object} transparent:                        transparent material options
 * {bool}   transparent.doubleSided(=false)     Use double sided for transparency
 * {string} maps:                               Path to replace the default texture maps
 *
 * FIREBASE ANALYTICS
 * {object} firebaseConfig:  Contains your web app's Firebase configuration, if not provided no events will be logged to firebase
 */

export interface IOptions {
	sku: string,
	appId: string,
	containerId: string, // ui

	basePath: string,
	endpoints: IEndpoints,
	maps: string // envMap
	// preload: boolean,

	toneMappingExposure: number, // renderer
	toneMappingExposureIE: number, // renderer
	pixelRatio: number, // renderer

	postprocessing: boolean, // composer
	maxAALevel: number, // composer

	zoom: number, // camera
	maxDistance: number, // camera orbitcontrols // Подумать. Может добавить в API возможность инициализировать
	minDistance: number, // camera orbitcontrols
	zNear: number, // camera
	zFar: number, // camera
	rotate: boolean // orbitcontrols
	// restrictAngle: boolean // orbitcontrols // Заменить на { maxPolarAngle, minPolarAngle, maxAzimuthAngle, minAzimuthAngle }
}
// readonly

export const transparentMaterialParametrs: IMaterialProperties = {
	color: '#ffffff',
	metalness: 1.0,
	envMapIntensity: 8
};
export const solidMaterialParametrs: IMaterialProperties = {
	color: '#ffffff',
	metalness: 0.5,
	envMapIntensity: 6.2
};
export const shadowMaterialParametrs: IMaterialProperties = {
	color: '#ffffff',
	metalness: 0.5,
	envMapIntensity: 15,
	flatShading: true,
	transparent: true,
	opacity: 0.5,
	alphaTest: 0.025,
	depthWrite: false
};

export const opts: IOptions = {
	sku: '',
	appId: '',
	containerId: '',

	basePath: 'https://api.inhaabit.com/v2',
	endpoints: {
		webProduct: 'web-product',
		stats: 'stats'
	},
	maps: './maps/',
	// preload: true,

	toneMappingExposure: 1,
	toneMappingExposureIE: 0.65,
	pixelRatio: 1,

	postprocessing: true,
	maxAALevel: 100,

	zoom: 0.95,
	maxDistance: 1.5,
	minDistance: 0.5,
	zNear: 1,
	zFar: 130,
	rotate: false
	// restrictAngle: true
};

export const connectorRadius: number = 0.01;

export const defaultPartStyles = {
  slats: 'Western Red Cedar',
  panels: 'Yellow Gold',
  legs: 'Ironstone',
  bbqs: 'Ironstone',
  greencellBodies: 'Charcoal',
  greencellTrims: 'Yellow Gold'
};

export const partTypes = {
  ignorable: [
    'T1 Screws',
    'T2 Screws',
    'Leg Screws',
    'Back Screws',
    'Con 90 Screws',
    'Con 60 Screws',
    'Back Stiffener Screws',
    'Footprint',
    'Bar Table Fixings',
    'Bar Stool Fixings',
    'Bar Stool With Backrest Fixings',
    'Greencell 1000x500 Join',
    'Greencell 1000x1000 Join',
  ],
  slats: [
    'Bench Slats 1000',
    'Back Slats 1000',
    'Bench Slats 600',
    'Bench Slats 1500',
    'Bench Slats 2000',
    'Back Slats 600',
    'Back Slats 1500',
    'Back Slats 2000',
    'T1 Slats 2000',
    'T2 Slats 2000',
    'T1 Slats 1000',
    'T1 Slats 1500',
    'T2 Slats 1000',
    'T2 Slats 1500',
    'Bar Table 1500 Slats',
    'Bar Table 2000 Slats',
    'Bar Table 3000 Slats',
    'Bar Table 4000 Slats',
    'Bar Stool Slats',
    'Bar Stool With Backrest Slats',
  ],
  panels: [
    'Bench Panel',
    'Back Panel',
    'T1 Panel',
    'T2 Panel',
    'Con 90 Top',
    'Con 60 Top',
    'Bar Table Panel',
    'Bar Stool Panel',
    'Bar Stool With Backrest Panel',
  ],
  legs: [
    'Leg',
    'T1 Leg',
    'T1 Stiffener',
    'T2 Leg',
    'T2 Stiffener',
    'Back',
    'Con 90 Legs',
    'Con 60 Legs',
    'Back Stiffener',
    'Bench Stiffener',
    'Table',
    'Arm',
    'Bar Table Leg',
    'Bar Stool Body',
    'Bar Stool With Backrest Body',
  ],
  bbqs: [
    'Edge Single BBQ Cabinet',
    'Edge Double BBQ Cabinet',
    'Frontier Single BBQ Cabinet',
    'Frontier Double BBQ Cabinet',
  ],
  greencellBodies: [
    'Greencell 1000x500 Body',
    'Greencell 2000x500 Body',
    'Greencell 3000x500 Body',
    'Greencell 4000x500 Body',
    'Greencell 1000x1000 Body',
    'Greencell End Connector',
    'Greencell Middle Connector',
  ],
  greencellTrims: [
    'Greencell 1000x500 Trim',
    'Greencell 2000x500 Trim',
    'Greencell 3000x500 Trim',
    'Greencell 4000x500 Trim',
    'Greencell 1000x1000 Trim',
  ],
};
export const labelDescription: any = {
  'HYV-SEAT-001-600': 'Seat 600 Long',
  'HYV-SEAT-001-1000': 'Seat 1000 Long',
  'HYV-SEAT-001-1500': 'Seat 1500 Long',
  'HYV-SEAT-001-2000': 'Seat 2000 Long',

  'HYV-CTAB-001': 'Coffee Table',
  'HYV-ARM-001': 'Armrest',

  'HYV-BKRT-001-520':   'Backrest Assembly 520 Long',
  'HYV-BKRT-001-920':   'Backrest Assembly 920 Long',
  'HYV-BKRT-001-1420':  'Backrest Assembly 1420 Long',
  'HYV-BKRT-001-1920':  'Backrest Assembly 1920 Long',
  'HYV-BKRT-002-520':   'Backrest LHS Armrest only 520 Long',
  'HYV-BKRT-002-920':   'Backrest LHS Armrest only 920 Long',
  'HYV-BKRT-002-1420':  'Backrest LHS Armrest only 1420 Long',
  'HYV-BKRT-002-1920':  'Backrest LHS Armrest only 1920 Long',
  'HYV-BKRT-003-520':   'Backrest RHS Armrest only 520 Long',
  'HYV-BKRT-003-920':   'Backrest RHS Armrest only 920 Long',
  'HYV-BKRT-003-1420':  'Backrest RHS Armrest only 1420 Long',
  'HYV-BKRT-003-1920':  'Backrest RHS Armrest only 1920 Long',
  'HYV-BKRT-004-520':   'Backrest Assembly with Armrests 520 Long',
  'HYV-BKRT-004-920':   'Backrest Assembly with Armrests 920 Long',
  'HYV-BKRT-004-1420':  'Backrest Assembly with Armrests 1420 Long',
  'HYV-BKRT-004-1920':  'Backrest Assembly with Armrests 1920 Long',

  'HYV-SLEG-001': 'Seat Leg End',
  'HYV-SLEG-002': 'Seat Leg Intermediate',

  'HYV-CON90-001': '90 Degree Connector - no seats',
  'HYV-CON90-002': '90 Degree Connector - 1 Seat',
  'HYV-CON90-003': '90 Degree Connector - 2 Seats',
  'HYV-CON90-004': '90 Degree Connector - 3 Seats',
  'HYV-CON90-005': '90 Degree Connector - 4 Seats',

  'HYV-CON60-001': '60 Degree Connector - no seats',
  'HYV-CON60-002': '60 Degree Connector - 1 Seat',
  'HYV-CON60-003': '60 Degree Connector - 2 Seats',
  'HYV-CON60-004': '60 Degree Connector - 3 Seats',

  'HYV-STAB-001-1000': 'Single Table 1000 Long',
  'HYV-STAB-001-1500': 'Single Table 1500 Long',
  'HYV-STAB-001-2000': 'Single Table 2000 Long',
  'HYV-STAB-002-3000': 'Single Table (Two Spans) 3000 Long',
  'HYV-STAB-002-4000': 'Single Table (Two Spans) 4000 Long',

  'HYV-DTAB-001-1000': 'Double Table 1000 Long',
  'HYV-DTAB-001-1500': 'Double Table 1500 Long',
  'HYV-DTAB-001-2000': 'Double Table 2000 Long',
  'HYV-DTAB-002-3000': 'Double Table (Two Spans) 3000 Long',
  'HYV-DTAB-002-4000': 'Double Table (Two Spans) 4000 Long',

  'HYV-GC-SM-001-1000': 'Greencell 1000 Long',
  'HYV-GC-SM-001-2000': 'Greencell 2000 Long',
  'HYV-GC-SM-001-3000': 'Greencell 3000 Long',
  'HYV-GC-SM-001-4000': 'Greencell 4000 Long',
  'HYV-GC-SM-002': 'Greencell (2)',

  'HYV-STAB-003-1500': 'Bar Table 1500 Long',
  'HYV-STAB-003-2000': 'Bar Table 2000 Long',
  'HYV-STAB-003-3000': 'Bar Table 3000 Long',
  'HYV-STAB-003-4000': 'Bar Table 4000 Long',

  'HYV-STL-001': 'Bar Stool',
  'HYV-STL-002': 'Bar Stool With Backrest',

  'ESB-SC-SST-001': 'Edge Single',
  'ESB-SC-STD-001': 'Edge Single',

  'EDB-DC-SST-001': 'Edge Double',
  'EDB-DC-STD-001': 'Edge Double',

  'FSB-SC-SST-001': 'Frontier Single',
  'FSB-SC-STD-001': 'Frontier Single',

  'FDB-DC-SST-001': 'Frontier Double',
  'FDB-DC-STD-001': 'Frontier Double',
};
