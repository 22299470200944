import * as THREE from 'three';
import {engine} from "../engine/engine";

export class Watermark {
	private _mesh = null;

	constructor(map: THREE.Texture) {
		this.create(map);
	}

	private create(map: THREE.Texture): void {

    // const spriteMap = new THREE.TextureLoader().load( '/assets/img/ui/hyve-photo.png' );

		const material = new THREE.SpriteMaterial({
			map: map,
			opacity: 1,
			transparent: true,
			depthTest: false,
			depthWrite: false,
      sizeAttenuation: false
		});
		this._mesh = new THREE.Sprite(material);
		const factor = 0.00025;
    this._mesh.scale.set(527 * factor, 407 * factor, 0);
		this._mesh.center = new THREE.Vector2(1, 0);
		const position = new THREE.Vector3(1, -1, .0).unproject(engine.camera);
		this._mesh.position.copy(position);

		this.addToScene();
	}

	public show(): void {
		this._mesh.material.opacity = 1;
	}

	public hide(): void {
		this._mesh.material.opacity = 0;
	}

	public destruct(): void {
		if(this._mesh.material) {
			this._mesh.material.map.dispose();
			this._mesh.material.dispose();
		}
		this._mesh.material = null;

		this._mesh.parent.remove(this._mesh);
		this._mesh = null;
	}

  public addToScene(): void {
    engine.scene.add(this._mesh);
  }
}
