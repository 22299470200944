import * as THREE from 'three';
import {labelDescription} from '../options';

export class Label {
	private canvas = null;
	private ctx = null;
	private _mesh = null;
  private _text: string;
  private _description: string;

	constructor(private options: any) {
		this.setContext();
		this.draw3D(options.origin, options.scaleFactor);
	}

	get mesh(): THREE.Mesh {
		return this._mesh;
	}
  get text(): string {
    return this._text;
  }
	set text(value: string) {
	  this._text = value;
    this._description = labelDescription[value];
		this.ctx.clearRect(0, 0, this.options.width, this.options.height);
		this.drawRoundedRect();
		this.ctx.fillStyle = this.options.textColor;
		this.ctx.fillText(this._text, this.canvas.width / 2, this.canvas.height / 2);
	}
  get description(): string {
    return this._description;
  }

	private setContext(): void {
		this.canvas = document.createElement('canvas');
		this.canvas.width = this.options.width;
		this.canvas.height = this.options.height;

		this.ctx = this.canvas.getContext("2d");

		this.ctx.textBaseline = 'middle';
		this.ctx.textAlign = 'center';

		this.ctx.font = `lighter ${this.options.fontSize}px ${this.options.fontFace}`;
	}

	private drawRoundedRect(): void {
		const r = this.options.radius;
		const w = this.options.width;
		const h = this.options.height;

		this.ctx.beginPath();
		this.ctx.moveTo(r, 0);
		this.ctx.lineTo(w - r, 0);
		this.ctx.quadraticCurveTo(w, 0, w, r);
		this.ctx.lineTo(w, h - r);
		this.ctx.quadraticCurveTo(w, h, w - r, h);
		this.ctx.lineTo(r, h);
		this.ctx.quadraticCurveTo(0, h, 0, h - r);
		this.ctx.lineTo(0, r);
		this.ctx.quadraticCurveTo(0, 0, r, 0);
		this.ctx.closePath();

		this.ctx.fillStyle = this.options.bgColor;
		this.ctx.strokeStyle = this.options.bgColor;
		this.ctx.fill();
	}

	private draw3D(origin: THREE.Vector3, scaleFactor: number): void {
		const spriteMap = new THREE.Texture(this.canvas);
		spriteMap.needsUpdate = true;

		const material = new THREE.SpriteMaterial({ // new THREE.MeshBasicMaterial({
			map: spriteMap,
			opacity: 0,
			transparent: true,
			depthTest: false,
			depthWrite: false,
		});
		// const planeGeometry = new THREE.PlaneGeometry(1, 1);
		// this._mesh = new THREE.Mesh(planeGeometry, material);
		this._mesh = new THREE.Sprite(material);
		this._mesh.center = new THREE.Vector2(0.5, 0.5);
		this._mesh.rotateX(-Math.PI * 0.5);
		this._mesh.position.copy(origin);
		this.setScale(scaleFactor);
	}

	private setScale(scaleFactor): void {
		this._mesh.scale.set(scaleFactor * this.canvas.width / this.canvas.height, scaleFactor, 1);
	}

	public show(): void {
		this._mesh.material.opacity = 1;
	}

	public hide(): void {
		this._mesh.material.opacity = 0;
	}

	public destruct(): void {
		if(this._mesh.material) {
			this._mesh.material.map.dispose();
			this._mesh.material.dispose();
		}
		this._mesh.material = null;

		this._mesh.parent.remove(this._mesh);
		this._mesh = null;
	}
}
