import { Component, ViewChild, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { gsap } from 'gsap';
import {configurator2d} from '../c2d/configurator';
import {options} from '../c2d/options';

@Component({
  selector: 'app-footprint',
  templateUrl: './footprint.component.html',
  styleUrls: ['./footprint.component.scss'],
})
export class FootprintComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('shadow') shadow: ElementRef;
  @Output() generate = new EventEmitter<string>();

  width: number;
  height: number;

  ngOnInit() {}

  public show(): void {
    this.width = configurator2d.footprint ? configurator2d.footprint.width / options.globalZoomFactor : 0;
    this.height = configurator2d.footprint ? configurator2d.footprint.height / options.globalZoomFactor : 0;

    this.page.nativeElement.style.display = 'block';
    this.shadow.nativeElement.style.display = 'block';

    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      opacity: 1,
      scale: 1,
      duration: 0.5
    });
    gsap.to(this.shadow.nativeElement, {
      ease: 'power3.out',
      opacity: 0.2,
      scale: 1,
      duration: 0.5
    });
  }

  public hide(): void {
    const that = this;

    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      opacity: 0,
      scale: 0,
      duration: 0.5,
      onComplete() { that.page.nativeElement.style.display = 'none'; }
    });

    gsap.to(this.shadow.nativeElement, {
      ease: 'power3.out',
      opacity: 0,
      duration: 0.5,
      onComplete() { that.shadow.nativeElement.style.display = 'none'; }
    });
  }

  public submit() {
    if (this.width <= 0 || this.height <= 0) {
      this.width = 0;
      this.height = 0;
    }
    this.generate.emit();
    this.hide();

    configurator2d.setFootprintSize(this.width, this.height);
  }
}
