import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
// import { TweenLite, Power1, Power3 } from 'gsap/all';
import { gsap } from 'gsap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AlertComponent } from '../alert/alert.component';
import { configuration } from '../chooser/connector/view3d/configuration.data';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  // Real time checking of input is being reused twice (contact.ts, contact3d.ts layout.ts)
  emailChanged$ = new Subject<string>();
  firstNameChanged$ = new Subject<string>();
  surnameChanged$ = new Subject<string>();
  phoneChanged$ = new Subject<string>();
  companyChanged$ = new Subject<string>();

  public firstName = '';
  public surname = '';
  public email = '';
  public phone = '';
  public company = '';

  public emailError = false;
  public firstNameError = false;
  public surnameError = false;
  public phoneError = false;
  public companyError = false;

  public validEmail = false;
  public validFirstName = false;
  public validSurname = false;
  public validPhone = false;
  public validCompany = false;

  public isError = true;

  @ViewChild('page') page: ElementRef;
  @ViewChild(AlertComponent) alertComponent;
  @Output() actionType = new EventEmitter<string>();

  constructor() {
    this.emailChanged$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(async (changed) => this.validateEmail())
    ).subscribe();
    this.firstNameChanged$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(async (changed) => this.validateFirstName())
    ).subscribe();
    this.surnameChanged$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(async (changed) => this.validateSurname())
    ).subscribe();
    this.phoneChanged$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(async (changed) => this.validatePhone())
    ).subscribe();
    this.companyChanged$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(async (changed) => this.validateCompany())
    ).subscribe();
   }

  ngOnInit() {
  }

  show(): void {
    this.page.nativeElement.style.display = 'block';
    // TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, opacity: 1 });
    gsap.to(this.page.nativeElement, {
      ease: 'power3.out',
      opacity: 1,
      duration: 0.5
    });
  }

  hide(): void {
    /*TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power1.easeOut,
      opacity: 0,
      onComplete: () => { this.page.nativeElement.style.display = 'none'; }
    });*/
    const that = this;
    gsap.to(this.page.nativeElement, {
      ease: 'power1.out',
      opacity: 1,
      duration: 0.5,
      onComplete() { that.page.nativeElement.style.display = 'none'; }
    });
  }

  checkError(): void {
    if (this.validEmail === true && this.validFirstName === true && this.validSurname === true && this.validCompany && this.validPhone) {
      this.isError = false;
    } else {
      this.isError = true;
    }
  }

  submit(): void {
    this.validateEmail();
    this.validateFirstName();
    this.validateSurname();
    this.validatePhone();
    this.validateCompany();

    if (this.isError === false) {
      configuration.firstName = this.firstName;
      configuration.lastName = this.surname;
      configuration.email = this.email;
      configuration.phone = this.phone;
      configuration.company = this.company;
      this.actionType.emit();

      this.hide();
    }
  }

  blurKeyboard(e): void {
    if (e.keyCode === 13) {
      const activeElement = document.activeElement as HTMLElement;
      activeElement && activeElement.blur && activeElement.blur();
    }
  }

    /**
   * Function from:
   * https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
   */
  validateEmail(): void {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(this.email).toLowerCase())) {
      this.emailError = false;
      this.validEmail = true;
    } else {
      this.validEmail = false;
      this.emailError = true;
    }
    this.checkError();
  }

  validateFirstName(): void {
    if (String(this.firstName).length >= 2) {
      this.firstNameError = false;
      this.validFirstName = true;
    } else {
      this.validFirstName = false;
      this.firstNameError = true;
    }
    this.checkError();
  }

  validateSurname(): void {
    if (String(this.surname).length >= 2) {
      this.surnameError = false;
      this.validSurname = true;
    } else {
      this.validSurname = false;
      this.surnameError = true;
    }
    this.checkError();
  }

  validatePhone(): void {
    if (String(this.phone).length >= 8 && String(this.phone).length <= 16) {
      this.phoneError = false;
      this.validPhone = true;
    } else {
      this.validPhone = false;
      this.phoneError = true;
    }
    this.checkError();
  }

  validateCompany(): void {
    if (String(this.company).length >= 2) {
      this.companyError = false;
      this.validCompany = true;
    } else {
      this.validCompany = false;
      this.companyError = true;
    }
    this.checkError();
  }

}
