import * as THREE from 'three';
import {Object3d} from './Object3d';
import {MaterialSet} from '../../dataTree/MaterialSet';
import {Material} from '../materials/Material';
import {solidMaterialParametrs, transparentMaterialParametrs} from '../../options';

export class Model extends Object3d {

	constructor(geometry: THREE.BufferGeometry, material: Material) {

		super(geometry, material);
	}

	public updateMaterial(set: MaterialSet): void {
		const parameters = set.items.transparent ? transparentMaterialParametrs : solidMaterialParametrs;
		set.items = {...parameters, ...set.items}; // , ...{envMap: environment.map}
		this.material.update(set);
		this.material.needsUpdate = true;
	}
}