import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import {  AppComponent, HomeComponent, InformationComponent, AlertComponent, LoginComponent, GreetingComponent,
  ChooserComponent, TutorialComponent, ConnectorComponent, View3dComponent, FootprintComponent,
  EmailComponent, QuoteComponent } from './index';
import { ConfiguratorSliderComponent } from './chooser/connector/view3d/configurator-slider/configurator-slider.component';
import { LayoutComponent } from './layout/layout.component';
import { DatasheetComponent } from './datasheet/datasheet.component';
import { BulletTutorialComponent } from './chooser/bullet-tutorial/bullet-tutorial.component';

@NgModule({
  declarations: [
    AppComponent, HomeComponent, InformationComponent,
    AlertComponent, LoginComponent, GreetingComponent,
    ChooserComponent, TutorialComponent, ConnectorComponent, View3dComponent,
    EmailComponent, QuoteComponent, FootprintComponent, ConfiguratorSliderComponent,
    LayoutComponent, DatasheetComponent, BulletTutorialComponent
  ],
  imports: [
    BrowserModule, AppRoutingModule, FormsModule, HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
