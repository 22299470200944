import {hexToInt} from '../utils/utils';

interface IKeyAdapter {
	albedo: string,
	ambientOcclusion: string,
	emission: string,
	metalness: string,
	roughness: string,
	normal: string,
	albedoColor: string,
	metalnessColor: string,
	roughnessColor: string,
	emissionColor: string,
	alphaTest: string,
}

const keyAdapter: IKeyAdapter = {
	albedo: 'map',
	ambientOcclusion: 'aoMap',
	emission: 'emissiveMap',
	metalness: 'metalnessMap',
	roughness: 'roughnessMap',
	normal: 'normalMap',
	albedoColor: 'color',
	metalnessColor: 'metalness',
	roughnessColor: 'roughness',
	emissionColor: 'emissive',
	alphaTest: 'alphaTest',
};

export class MaterialSet {

	private _items: any = {};

	constructor(data?: any) {

		if(!data) return;

		const dataSet = extractSet(data);
		this._items = adaptSet(dataSet);
	}

	get items(): any {

		return this._items;
	}

	set items(value: any) {

		this._items = value;
	}

	get imageUrls(): string[] {

		const urls = [];

		for(const key in this._items) {

			if(!this._items.hasOwnProperty(key)) continue;

			const str = this._items[key];
			if((/((.png|.jpg)$)/ig).test(str)) urls.push(str);
		}

		return urls;
	}

	public merge(otherSet: MaterialSet): MaterialSet {

		const materialSet = new MaterialSet();
		materialSet._items = { ...this._items, ...otherSet.items };

		return materialSet;
	}

	public destruct(): void {

		this._items = null;
	}
}

function extractSet(data: any): any {

	const set = {};

	for(const key in data) {

		if(!data.hasOwnProperty(key)) continue;

		const propertyExists = (/(^(albedo|ambientOcclusion|normal|metalness|roughness|emission|albedoColor|metalnessColor|roughnessColor|emissionColor|alphaTest)$)/ig).test(key);

		if(propertyExists) {
			set[key] = /^metalnessColor|roughnessColor$/.test(key) && /^#/.test(data[key]) ? hexToInt(data[key]) / hexToInt('#ffffff') : data[key];
		}
	}
	return set;
}

function adaptSet(set: any): any {

	const parameters: any = {};

	for(const key in set) {

		if(!set.hasOwnProperty(key)) continue;
		const adaptedKey = keyAdapter[key];
		if(adaptedKey) parameters[adaptedKey] = set[key];
	}
	return parameters;
}


